import classNames from "classnames";
import {Image} from "../image/image";
import {Link} from "../link/link";

export const DropdownItem = ({item, onChange, logoIncluded, styles}) => {
  const imageProps = {
    className: classNames(styles.item_logo),
    src: item.imageURL
  }

  const linkProps = {
    to: item.link,
    className: classNames(styles.item),
    onClick: () => onChange(item.id)
  }

  return item.link !== undefined ? (
    <Link {...linkProps}>
      {
        logoIncluded ? <Image  {...imageProps}/> : null
      }
      <bdi className={styles.item_text}>{item.name}</bdi>
    </Link>
  ) : (
    <div className={classNames(styles.item)} onClick={() => onChange(item.id)}>
      {
        logoIncluded ? <Image  {...imageProps}/> : null
      }
      <bdi className={styles.item_text}>{item.name}</bdi>
    </div>
  );
};
