import React from 'react';

export class StringToJSXProps {
  domString: String;
}

export const StringToJsx = (props: StringToJSXProps) => {
  return createJSX(Array.from(getNodes(props.domString)));
}

const getNodes: Node = (domString: String) => new DOMParser().parseFromString(domString, "text/html").body.childNodes;

let createJSX = (nodeArray: Array<Node>) => {
  return nodeArray.map((node: Node, index) => {
    let attributeObj = {};
    const {attributes, localName, childNodes, nodeValue} = node;

    if (attributes) {
      Array.from(attributes).forEach((attribute) => {
        let attributeName = attribute.name;

        if (attributeName === "style") {
          let styleAttributes = attribute.nodeValue.split(";");
          let styleObj = {};
          styleAttributes.forEach(attribute => {
            let [key, value] = attribute.split(":");
            styleObj[key] = value;
          });
          attributeObj[attributeName] = styleObj;
        } else {
          if(attributeName === "class") {
            attributeName = "className"
          }
          attributeObj[attributeName] = attribute.nodeValue;
        }
      });
    }

    attributeObj['key'] = index;

    const isJsx: Boolean = !!localName;

    return isJsx ?
      React.createElement(localName, attributeObj, childNodes && Array.isArray(Array.from(childNodes)) ? createJSX(Array.from(childNodes)) : [])
      :
      nodeValue;
  });
}
