import React, {useRef} from 'react';

import classNames from "classnames";
import styles from './game.module.scss';
import {GameScoreInputActive} from "./game.score-input.active";

export const GameScoreInput = ({setActive, setInactive, value, onChangeValue, isInputActive}) => {
  const ref = useRef();
  const valueDisplay = `${value > -1 ? value : ''}`;

  const divProps = {
    className: classNames(styles.score_input),
    onClick: (e) => {
      e.stopPropagation();
      e.preventDefault();

      setActive(e)
    },
    ref
  }

  const gameScoreInputActiveProps = {
    onChangeValue: (e, value) => onChangeValue(e, value),
    setInactive: (e) => setInactive(e),
  }

  return <div {...divProps}>
    {
      isInputActive ?
        <GameScoreInputActive {...gameScoreInputActiveProps} /> : valueDisplay
    }
  </div>
};
