import {WidgetComposer} from "../../widget.composer";
import {LoginWidgetController} from "../login.widget.controller";
import {LoginWidgetState} from "../login.widget.state";
import classNames from "classnames";
import styles from "../styles/login.module.scss";
import {LoginWidgetWelcomePage} from "./login.widget.welcome-page";
import {shareState} from "../../../app/share-state";
import {LoginWidgetRegistrationPage} from "./login.widget.registration-page";
import {LoginWidgetMain} from "./login.widget.main";
import {WelcomeImageRequestParams} from "../../../icons/background/welcome";
import {LanguagesDropdown} from "../../../components/languages/languages-dropdown";
import languageDropdownStyles from "../styles/login.languages-dropdown.module.scss";
import {useEffect} from "react";

export const settings = {
  controller: LoginWidgetController,
  state: LoginWidgetState
}

export const LoginWidget = WidgetComposer(settings)(({controller}) => {
  useEffect(() => {
    controller.handleRedirect();
  }, []);

  const containerProps = {
    className: classNames(styles.container, {[styles.mobile_view]: shareState.isMobileView}),
    style: !shareState.isMobileView ? {
      backgroundImage: `url(${new WelcomeImageRequestParams()})`
    } : null
  }

  return (
    <div {...containerProps}>
      {
        !shareState.isWebView ? <LanguagesDropdown styles={languageDropdownStyles} openToTop={shareState.isMobileView}/> : null
      }
      <LoginWidgetMain controller={controller}/>
      <LoginWidgetWelcomePage controller={controller}/>
      <LoginWidgetRegistrationPage controller={controller}/>
    </div>
  )
});
