import {ProfileHeaderButtonModel} from "./profile-header.button.model";
import {APP_CONST, ROUTE_NAME} from "../../../consts";
import {CupDefaultImageRequestParams} from "../../../icons/cup/cup-default";
import {ImageRequestParams} from "../../../models/image/image-request-params";
import styles from "../profile-header.module.scss";
import {override} from "mobx";
import {WIZARD} from "../../../widgets/wizard-widget/wizard.widget.consts";
import {shareState} from "../../../app/share-state";
import {CompetitorImageRequestParams} from "../../../icons/competitor";
import {WinningTeamClickKinesisEvent} from "../../../analytics/kinesis/events/profile-header/winning-team.click.kinesis-event";

export class ProfileHeaderButtonWinningTeamModel extends ProfileHeaderButtonModel {
  @override get entityId(): Number {
    return this.user.winnerTeamId;
  }

  @override get wizardPage(): Number {
    return WIZARD.PAGES.winningTeam;
  }

  @override get source(): String {
    return !this.isOtherUser ? APP_CONST.ROUTE.winningTeam: APP_CONST.ROUTE.leaderboard;
  }

  @override get name(): String {
    return this.user.winnerTeamName || (this.isOtherUser ? '-' : shareState.termsUtils.terms.WC_20222_SELECT);
  }

  @override get imageSource(): String {
    const imageSource: ImageRequestParams = this.entityId ? new CompetitorImageRequestParams(this.entityId) : new CupDefaultImageRequestParams(16,32);

    return imageSource;
  }

  get sectionName(): String {
    return shareState.termsUtils.terms.WC_2022_WINNING_TEAM;
  }

  get iconClassName(): String {
    return styles.cup_icon;
  }

  @override get hasNoSelection(): Boolean {
    return !this.entityId;
  }

  @override get isRouteActive(): Boolean {
    const isRouteActive: Boolean = ROUTE_NAME[APP_CONST.ROUTE.winningTeam] === this.pathName;
    const isPageActive: Boolean = WIZARD.PAGES.winningTeam === shareState.wizard.currentPage && shareState.wizard.isVisible;
    const isWinningTeamActive: Boolean = isRouteActive || isPageActive;

    return isWinningTeamActive && !this.entityId;
  }

  @override get clickEventModel(): WinningTeamClickKinesisEvent {
    return new WinningTeamClickKinesisEvent({id: this.entityId});
  }
}
