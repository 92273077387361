import classNames from "classnames";
import styles from './blacket.module.scss';

export const Blanket = ({className, onClick, shouldRenderBlanket}) => {
  const blanketProps = {
    className: classNames(styles.blanket, className),
    onClick: () => onClick()
  }

  return (
    <>
      {
        shouldRenderBlanket ? <div {...blanketProps}/> : null
      }
    </>
  );
};


