import {WebRequestModel} from "../../../web-request";
import {REQUEST} from "../../../requests.consts";
import {APP_CONST} from "../../../../../consts";
import {localStorageUtils} from "../../../../../infrastructure/utils/locale-storage-utils";

export class GetRequestTermsModel extends WebRequestModel {
  constructor(requestParams) {
    super(REQUEST.ACTION.WEB.terms, requestParams, false, true, false, false);
  }

  get languageParams(): String {
    const langId = localStorageUtils.getItem(APP_CONST.LOCAL_STORAGE_KEYS.langId) || 1
    const languageParams: String = this.includeLanguage ?`?langId=${langId}` : '';

    return languageParams
  }
}
