import {useEffect, useRef} from "react";

// Handler have to be wrapped by callBack
export const useEventListener = (eventName: String, handler: Function, element: Element = window, options = {}) => {
  const savedHandler = useRef();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported: Boolean = element && element?.addEventListener;

    if (!isSupported) return;

    const eventListener = (event) => savedHandler.current(event);

    element.addEventListener(eventName, eventListener, options);

    return () => {
      element.removeEventListener(eventName, eventListener, options);
    };
  }, [eventName, element]);
};