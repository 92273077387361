import classNames from "classnames";
import styles from "../../styles/login.module.scss";
import {LoginWidgetStepItem} from "./login.widget.step.item";

export const LoginWidgetStep = ({step, shouldRenderLine}) => {
  return (
    <>
      <LoginWidgetStepItem step={step}/>
      {
        shouldRenderLine ? <div className={classNames(styles.line)}/> : null
      }
    </>
  )
};
