export const COLORS = {
  default: {
    site_background: '#16181B',
    element_background: '#21282D',
    text: '#FFF',
    disable: '#7F97AB',
    item_background: '#21282D',
    item_hover: '#232D33',
    score_box: '#F4F4F4',
    primary: '#03A9F4',
    live: '#FF5200',
    success: '#76B300',
    partial_success: '#FFC107',
    double_score: '#FFD24D',
    error: '#EA4335',
    text_dark: '#505050',
    active_cell_selection: '#216890'
  },
  sb: {
    site_background: '#16181B',
    element_background: '#21282D',
    text: '#FFF',
    disable: '#7F97AB',
    item_background: '#21282D',
    item_hover: '#232D33',
    score_box: '#F4F4F4',
    primary: '#03A9F4',
    live: '#FF5200',
    success: '#76B300',
    partial_success: '#FFC107',
    double_score: '#FFD24D',
    error: '#EA4335',
    text_dark: '#505050',
    active_cell_selection: '#216890'
  },
  dominos: {
    site_background: '#16181B',
    element_background: '#21282D',
    text: '#FFF',
    disable: '#7F97AB',
    item_background: '#21282D',
    item_hover: '#232D33',
    score_box: '#F4F4F4',
    primary: '#03A9F4',
    live: '#FF5200',
    success: '#76B300',
    partial_success: '#FFC107',
    double_score: '#FFD24D',
    error: '#EA4335',
    text_dark: '#505050',
    active_cell_selection: '#216890'
  }
}
