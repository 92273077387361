import classNames from "classnames";
import styles from '../game.module.scss';
import {observer} from "mobx-react";

export const GameKeyboardButton = observer(({button, onClick}) => {
  const containerProps = {
    className: classNames(styles.keyboard_button_background),
    onClick: (e) => onClick && onClick(e)
  }

  return (
    <div {...containerProps}>
      <div className={classNames(styles.keyboard_button)}>
        {button}
      </div>
    </div>
  )
});


