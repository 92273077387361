import classNames from "classnames";
import styles from "./popup.module.scss";
import {Modal} from "../modal/modal";
import {shareState} from "../../app/share-state";
import {APP_CONST} from "../../consts";
import {useEventListener} from "../../hooks/use-event-listener";
import {Blanket} from "../blanket/blanket";
import {CloseImageRequestParams} from "../../icons/close";
import {Image} from "../image/image";

export const Popup = ({className, onClose, children, style}) => {
  useEventListener('keydown', (event) => onEscapeClick(event))

  const onEscapeClick = (event) => {
    const onPressEscape: Boolean = event.keyCode === APP_CONST.KEY_CODES.escape;
    onPressEscape && onClose?.()
  }

  const blanketProps = {
    className: classNames(styles.blanket),
    onClick: () => onClose?.(),
    shouldRenderBlanket: true
  }

  const closeProps = {
    className: classNames(styles.close),
    src: new CloseImageRequestParams(16, 16),
    onClick: () => onClose?.()
  }

  return (
    <Modal>
      <Blanket {...blanketProps}/>
      <div className={classNames(styles.container, className, {[styles.mobile_view]: shareState.isMobileView})}
           style={style}>
        {
          onClose ? <Image {...closeProps}/> : null
        }
        {
          children
        }
      </div>
    </Modal>
  );
}
