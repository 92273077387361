import {GamesWidgetGroupModel} from "../models/games-widget.group.model";
import {observer} from "mobx-react";
import {GamesWidgetGroup} from "./games.widget.group";

export const GamesWidgetGroups = observer(({controller, shouldConsiderAcca}) => {
  const {state} = controller;
  const {groups} = state;

  return groups.map((group: GamesWidgetGroupModel) => <GamesWidgetGroup
      group={group}
      key={group.id}
      controller={controller}
      shouldConsiderAcca={shouldConsiderAcca}
    />
  )
});
