import React, {Suspense} from "react";
import {LoadingIndicator} from "../../components/loading-indicator/loading-indicator";

const WizardPage = React.lazy(() => import("./wizard.page" /* webpackChunkName: "wizard.page" */ ));

const WizardPageElement = () => {
  return (
    <Suspense fallback={<LoadingIndicator/>}>
      <WizardPage/>
    </Suspense>
  )
};

export default WizardPageElement;