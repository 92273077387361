export const DATE_FORMATS = {
  "en": {
    "ShortestDatePattern": "dd/MM",
    "ShortDatePattern": "dd/MM/yyyy",
    "LongDatePattern": "dddd, MMMM dd yyyy",
    "ShortTimePattern": "HH:mm",
    "LongTimePattern": "HH:mm:ss",
    "FullDateTimePattern": "dddd, MMMM dd yyyy HH:mm",
    "ShortTimePattern12": "hh:mm a",
    "LongTimePattern12": "hh:mm:ss a",
    "FullDateTimePattern24": "dddd, MMMM dd yyyy HH:mm",
    "ShortTimePattern24": "HH:mm",
    "LongTimePattern24": "HH:mm:ss",
    "FullDateTimePattern12": "dddd, MMMM dd yyyy hh:mm a",
    "FirstDayOfWeek": 1,
    "Use24Clock": true
  },
  "es-es": {
    "ShortestDatePattern": "dd/MM",
    "ShortDatePattern": "dd/MM/yyyy",
    "LongDatePattern": "dddd, dd' de 'MMMM' de 'yyyy",
    "ShortTimePattern": "H:mm",
    "LongTimePattern": "H:mm:ss",
    "FullDateTimePattern": "dddd, dd' de 'MMMM' de 'yyyy H:mm",
    "ShortTimePattern12": "h:mm a",
    "LongTimePattern12": "h:mm:ss a",
    "FullDateTimePattern24": "dddd, dd' de 'MMMM' de 'yyyy H:mm",
    "ShortTimePattern24": "H:mm",
    "LongTimePattern24": "H:mm:ss",
    "FullDateTimePattern12": "dddd, dd' de 'MMMM' de 'yyyy h:mm a",
    "FirstDayOfWeek": 1,
    "Use24Clock": true
  },
  "ar": {
    "ShortestDatePattern": "dd/MM",
    "ShortDatePattern": "dd/MM/yy",
    "LongDatePattern": "dd/MMMM/yyyy",
    "ShortTimePattern": "hh:mm a",
    "LongTimePattern": "hh:mm:ss a",
    "FullDateTimePattern": "dd/MMMM/yyyy hh:mm a",
    "ShortTimePattern12": "hh:mm a",
    "LongTimePattern12": "hh:mm:ss a",
    "FullDateTimePattern24": "dd/MMMM/yyyy HH:mm ",
    "ShortTimePattern24": "HH:mm ",
    "LongTimePattern24": "HH:mm:ss ",
    "FullDateTimePattern12": "dd/MMMM/yyyy hh:mm a",
    "FirstDayOfWeek": 0,
    "Use24Clock": false
  },
  "pt-br": {
    "ShortestDatePattern": "dd/MM",
    "ShortDatePattern": "dd/MM/yyyy",
    "LongDatePattern": "dddd, d' de 'MMMM' de 'yyyy",
    "ShortTimePattern": "HH:mm",
    "LongTimePattern": "HH:mm:ss",
    "FullDateTimePattern": "dddd, d' de 'MMMM' de 'yyyy HH:mm",
    "ShortTimePattern12": "hh:mm a",
    "LongTimePattern12": "hh:mm:ss a",
    "FullDateTimePattern24": "dddd, d' de 'MMMM' de 'yyyy HH:mm",
    "ShortTimePattern24": "HH:mm",
    "LongTimePattern24": "HH:mm:ss",
    "FullDateTimePattern12": "dddd, d' de 'MMMM' de 'yyyy hh:mm a",
    "FirstDayOfWeek": 1,
    "Use24Clock": true
  },
  "he-il": {
    "ShortestDatePattern": "dd/MM",
    "ShortDatePattern": "dd/MM/yyyy",
    "LongDatePattern": "dddd dd MMMM yyyy",
    "ShortTimePattern": "HH:mm",
    "LongTimePattern": "HH:mm:ss",
    "FullDateTimePattern": "dddd dd MMMM yyyy HH:mm",
    "ShortTimePattern12": "hh:mm tt",
    "LongTimePattern12": "hh:mm:ss tt",
    "FullDateTimePattern24": "dddd dd MMMM yyyy HH:mm",
    "ShortTimePattern24": "HH:mm",
    "LongTimePattern24": "HH:mm:ss",
    "FullDateTimePattern12": "dddd dd MMMM yyyy hh:mm tt",
    "FirstDayOfWeek": 0,
    "Use24Clock": true
  },
  "en-us": {
    "ShortestDatePattern": "M/d",
    "ShortDatePattern": "M/d/yyyy",
    "LongDatePattern": "dddd, MMMM dd, yyyy",
    "ShortTimePattern": "h:mm a",
    "LongTimePattern": "h:mm:ss a",
    "FullDateTimePattern": "dddd, MMMM dd, yyyy h:mm a",
    "ShortTimePattern12": "h:mm a",
    "LongTimePattern12": "h:mm:ss a",
    "FullDateTimePattern24": "dddd, MMMM dd, yyyy H:mm ",
    "ShortTimePattern24": "H:mm ",
    "LongTimePattern24": "H:mm:ss ",
    "FullDateTimePattern12": "dddd, MMMM dd, yyyy h:mm a",
    "FirstDayOfWeek": 1,
    "Use24Clock": false
  },
}
