import {Component} from "react";
import {override} from "mobx";
import {WizardWidgetPageModel} from "./wizard-widget.page.model";
import {APP_CONST} from "../../../consts";
import {SelectionListWidget} from "../../selection-list.widget/components/selection-list.widget";
import {shareState} from "../../../app/share-state";
import {WIZARD} from "../wizard.widget.consts";
import {WizardTopScorerSelectionSkipKinesisEvent} from "../../../analytics/kinesis/events/wizard/wizard.top-scorer-selection.skip.kinesis-event";
import {kinesisAnalyticsUtils} from "../../../analytics/kinesis/kinesis.analytics-utils";

export class WizardWidgetPageTopScorerModel extends WizardWidgetPageModel {
  @override get WidgetComponent(): Component {
    return SelectionListWidget;
  }

  @override get title(): String {
    return shareState.termsUtils.terms.WC_2022_SELECT_TOP_SCORER;
  }

  @override get nextPage(): Number {
    return WIZARD.PAGES.games;
  }

  @override get textButton(): String {
    return this.hasSelection ? shareState.termsUtils.terms.WC_2022_NEXT_BUTTON : shareState.termsUtils.terms.WC_2022_SKIP_BUTTON;
  }

  @override get context(): String {
    return APP_CONST.ROUTE.topScorer;
  }

  @override get hasSelection(): String {
    return shareState.user.topScorerId > 0;
  }

  @override get shouldRenderArrow(): Boolean {
    return !shareState.isMobileView;
  }

  buttonEventModel(): WizardTopScorerSelectionSkipKinesisEvent {
    return kinesisAnalyticsUtils.send(new WizardTopScorerSelectionSkipKinesisEvent());
  }
}
