import {BaseModel} from "../base";

export class Bookmaker extends BaseModel {
  link: String;
  actionButton: Object;
  color: String;
  textColor: String;

  constructor(model) {
    super(model);

    this.id = model.ID;
    this.link = model.URL;
    this.actionButton = model.ActionButton;
    this.color = model.Color;
    this.textColor = model.CTATextColor;
  }
}