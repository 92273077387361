import {observable, makeObservable} from 'mobx';
import {BaseModel} from "../base";
import {IMAGE} from "./image.consts";
import {shareState} from "../../app/share-state";

export class ImageRequestParams extends BaseModel {
  _resourceName: String;
  alt: String;
  title: String;
  _width: Number;
  _height: Number;
  isRound: Boolean;
  version: Number;
  ratio: Number;
  c: String;
  g: String;
  zoom: Number;
  r: String;
  color: String;
  @observable theme: String;

  constructor(params: ImageRequestParams) {
    super();

    makeObservable(this);

    this._resourceName = params._resourceName;
    this.alt = params.alt || '';
    this.title = params.title || '';
    this.version = shareState.settings.version;
    this.isRound = typeof params.isRound === "boolean" ? params.isRound : true;
    this._width = params.width;
    this._height = params.height;
    this.ratio = params.ratio || 1;
    this.c = params.c || 'limit';
    this.g = params.g;
    this.zoom = params.zoom;
    this.r = params.r;
    this.theme = null;
    this.color = params.color;
    this.env = `bolao24/${shareState.environment.name}/${shareState.theme}`;
    this.fallbackEnv = `bolao24/${shareState.environment.name}/default`;
  }

  get width(): Number {
    return this._width;
  }

  get height(): Number {
    return this._height;
  }

  get defaultFormat(): String {
    return IMAGE.FORMAT.PNG;
  }

  get quality(): String {
    return 'auto:eco';
  }

  get versionPath(): String{
    return this.version ? `/v${this.version}` : '';
  }

  get rgb(): String{
    return this.color ? `co_rgb:${this.color.replace('#','')},e_colorize:100` : '';
  }

  get params() {
    const params = [];

    this.defaultFormat && params.push(`f_${this.defaultFormat}`);

    !isNaN(this.width) && params.push(`w_${this.width}`);
    !isNaN(this.height) && params.push(`h_${this.height}`);

    this.c && params.push(`c_${this.c}`);
    this.angle && params.push(`a_${this.angle}`);
    this.quality && params.push(`q_${this.quality}`);
    this.ratio && params.push(`dpr_${this.ratio}`);
    this.g && params.push(`g_${this.g}`);
    this.r && params.push(`r_${this.r}`);
    this.zoom && params.push(`z_${this.zoom}`);
    this.color && params.push(this.rgb);
    this.fallbackResourceName && params.push(`d_${this.fallbackResourceName}`)


    return params.join(',');
  }

  get resourceName() {
    return this._resourceName;
  }

  get fallbackResourceName() {
    return null;
  }

  get pathPostfix(): String {
    return `${this.versionPath}/${this.resourceName}`;
  }

  toString(): String {
    const url = `${IMAGE.domain}${this.params}${this.pathPostfix}`;

    return url;
  }
}
