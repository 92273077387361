import {BaseModel} from "../base";
import {REQUEST} from "./requests.consts";
import {RequestModel} from "./request";

export class WebRequestModel extends RequestModel {
  constructor(
    action: String,
    requestParams: BaseModel,
    isPostRequest: Boolean,
    includeLanguage: Boolean,
    includeAuthorized: Boolean,
    includeContentType: Boolean
  ) {
    super(
      REQUEST.CATEGORY.web,
      action,
      requestParams,
      isPostRequest,
      includeLanguage,
      includeAuthorized,
      includeContentType
    );

    this.apiEnvironment = `https://${this.getEnv()}.${this.domain}`

    this.method = REQUEST.METHOD_TYPE.get;
  }

  getEnv() {
    let apiType: String = process.env.REACT_APP_API_ENV;

    return apiType;
  }
}
