import {RequestModel} from "../../../request";
import {REQUEST} from "../../../requests.consts";
import {shareState} from "../../../../../app/share-state";

export class PostRequestRenameGroupModel extends RequestModel {
  groupId: Number;
  groupName: String;

  constructor({groupName, groupId}) {
    super(REQUEST.CATEGORY.groups, REQUEST.ACTION.GROUPS.rename, {}, true, false, true, true);

    this.apiEnvironment = shareState.environment.baseUrl;
    this.method = REQUEST.METHOD_TYPE.post;
    this.groupId = groupId;
    this.groupName = groupName;
  }

  get body() {
    const lang = {"lang": shareState.language.id}
    const groupId = this.groupId ? {"groupID": this.groupId} : {}
    const groupName = this.groupName ? {"newName": this.groupName} : {}

    return {...groupId, ...groupName, ...lang};
  }
}
