import {BackImageRequestParams} from "../../icons/back";
import {Image} from "../image/image";
import {COLORS} from "../../styles/colors";
import {shareState} from "../../app/share-state";

export const BackButton = ({onBackClick, className, iconClassName}) => {
  const containerProps = {
    onClick: () => onBackClick(),
    className: className
  }

  const iconProps = {
    className: iconClassName,
    src: new BackImageRequestParams(8, 14, COLORS[shareState.theme].disable)
  }

  return (
    <div {...containerProps}>
      <Image {...iconProps}/>
      <div>{shareState.termsUtils.terms.WC_2022_BACK}</div>
    </div>
  )
};
