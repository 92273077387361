import classNames from "classnames";
import styles from "../../styles/login.module.scss";

export const LoginWidgetStepDescription = ({step}) => {
  const {title, text} = step;

  return (
    <div>
      <div className={classNames(styles.step_title)}>{title}</div>
      <div className={classNames(styles.step_text)}>{text}</div>
    </div>
  )
};
