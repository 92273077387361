import {ImageRequestParams} from "../models/image/image-request-params";

export class LanguageImageRequestParams extends ImageRequestParams {
  constructor(id, width: Number, height: Number) {
    super({width, height});

    this._resourceName = `Website/Languages/${id}`;
  }

  get fallbackResourceName(): null {
    return 'Countries:1.png'
  }
}
