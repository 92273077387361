import {ProfileHeaderButtons} from "./buttons/profile-header.buttons";
import {ProfileHeaderStrip} from "./strip/profile-header.strip";
import {shareState} from "../../app/share-state";
import classNames from "classnames";
import styles from "./profile-header.module.scss";
import {ProfileHeaderUserInfo} from "./profile-header.user-info";
import {observer} from "mobx-react";

export const ProfileHeader = observer(({user}) => {
  const isOtherUser: Boolean = shareState.user?.userId !== user.userId;

  const buttonsProps = {
    user,
    isOtherUser
  }

  return (
    <div className={classNames(styles.container, {[styles.mobile_view]: shareState.isMobileView}, {[styles.popup]: isOtherUser})}>
      <ProfileHeaderUserInfo user={user}/>
      <ProfileHeaderButtons {...buttonsProps}/>
      <ProfileHeaderStrip user={user}/>
    </div>
  );
});

