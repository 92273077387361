import {WidgetController} from "../controllers/widget.controller";
import {AppState} from "./app.state";
import {shareState} from "./share-state";
import {UserModel} from "../models/user";
import {APP_CONST} from "../consts";
import {GetRequestUserSettingsModel} from "../models/request/category/users/get/get-request.user-settings.model";
import {LANGUAGES} from "../languages";
import {LanguageModel} from "../models/language";
import {GetRequestTermsModel} from "../models/request/category/web/get/get-request.terms.model";
import {TermModel} from "../models/term";
import {GetRequestGroupInfoModel} from "../models/request/category/groups/get/get-request.group-info.model";
import {GroupModel} from "../models/group";
import {PostRequestJoinGroupModel} from "../models/request/category/groups/post/post-request.join-group.model";
import {PostRequestLoginModel} from "../models/request/category/users/post/post-request.login.model";
import {REQUEST} from "../models/request/requests.consts";
import {datetimeUtils} from "../infrastructure/utils/datetime-utils";
import {visibleWidgetsManager} from "../infrastructure/managers/visible-widgets-manager";
import {WidgetState} from "../widgets/widget.state";
import {FetchUtils} from "../infrastructure/utils/fetch-utils";
import {kinesisAnalyticsUtils} from "../analytics/kinesis/kinesis.analytics-utils";
import {VisitKinesisEvent} from "../analytics/kinesis/events/app/visit.kinesis-event";
import {ExitKinesisEvent} from "../analytics/kinesis/events/app/exit.kinesis-event";
import {LOGIN} from "../widgets/login-widget/login.widget.consts";
import {DeltaRequestModel} from "../models/request/delta-request";
import {NativeGameConfig} from "../models/native-game-config";
import {GetRequestGameSettingsModel} from "../models/request/category/settings/get/get-request.game-settings.model";
import {GameSettings} from "../models/game-settings";
import {GetRequestLocaleModel} from "../models/request/category/web/get/get-request.locale.model";
import {localStorageUtils} from "../infrastructure/utils/locale-storage-utils";
import {GetRequestTournamentInfoModel} from "../models/request/category/tournament/get/get-request.info.model";
import {TournamentInfo} from "../models/tournament-info";
import {cookieUtils} from "../infrastructure/utils/cookie-utils";
import {
  PostRequestSetUserSettingsModel
} from "../models/request/category/users/post/post-request.set-user-settings.model";

export class AppController extends WidgetController {
  state: AppState;

  constructor(props, stateType: WidgetState, fetchManager: FetchUtils) {
    super(props, stateType, fetchManager);
    window.bolaoIntervalsIds = [];
    document.addEventListener('visibilitychange', (e) => {
      const isAppVisible = document.visibilityState === 'visible';
      const shouldUpdateAppData: Boolean = shareState.user && !shareState.onLoginProcess && isAppVisible;

      !isAppVisible && this.cleanActiveRequests()
      shouldUpdateAppData && visibleWidgetsManager.updateData()
    });
  }


  async init() {
    await super.init();

    this.state.isLoadingApp = true;
    this.state.isLoading = false;

    shareState._appType = await this.resolveAppType();

    if (shareState.isWebView) {
      await this.initFromWebView();
    } else {
      await this.initUserId();
      await this.initLocaleForWeb();
      await this.generalInit();
    }
  }

  async generalInit() {
    await this.populateUserCountryId();
    await this.initLanguage();
    await this.loginRedirect();
    await this.initTerms();
    await this.getTournamentInfo();
    await this.initUserAgreementPopup();

    this.state.isLoadingApp = false;

    await this.initJoinGroupDialog();

    kinesisAnalyticsUtils.send(new VisitKinesisEvent());
  }

  async initUserAgreementPopup() {
    if (shareState.user) {
      const isUserAlreadyAgreed: Boolean = shareState.user.userAgreement;
      const isUserAlreadyAgreedToPopup: Boolean = shareState.user.userAgreementPopupDisplayed;
      const isValidEnv: Boolean = (shareState.environment.name === APP_CONST.GAME_ENVIRONMENT.israel) && (shareState.theme === 'dominos');

      if (!isUserAlreadyAgreed && !isUserAlreadyAgreedToPopup && isValidEnv) {
        this.state.userAgreementsValue = true;
        this.state.userAgreementsPromise = new Promise((resolve) => this.resolver = resolve);
        shareState.userAgreementPopup = !shareState.user.userAgreement && !shareState.user.userAgreementPopupDisplayed && (shareState.environment.name === APP_CONST.GAME_ENVIRONMENT.israel) && (shareState.theme === 'dominos');
      }
    }

   return this.state.userAgreementsPromise;
  }

  async getTournamentInfo() {
    const requestModel: GetRequestTournamentInfoModel = new GetRequestTournamentInfoModel();

    return fetch(requestModel.fullUrl).then(res => res.json()).then((data) => {
      if (data) {
        shareState.tournamentInfo = new TournamentInfo(data);
      }
    });
  }

  async resolveAppType() {
    const queryParams = window.location.search.replace('?', '').split('&');
    const appTypeParam = queryParams.find((query) => query.includes('app_type='));
    let appType = 5;

    if (appTypeParam) {
      const appTypeParamValue = appTypeParam.replace('app_type=', '');
      appType = parseInt(appTypeParamValue);
    }

    return appType;
  }

  async initFromWebView() {
    let json;

    window.openGroupInvitationScreen = (groupId, isValidEnvironment) => {
      this.handleJoinGroup(groupId, shareState.environment.name, shareState.environment.baseUrl, shareState.environment.name, !(isValidEnvironment === "true"), true)
    }

    window.getGameConfig = (gameConfig) => {
      if (gameConfig) {
        json = JSON.parse(gameConfig);
        shareState.nativeGameConfig = new NativeGameConfig(json);
        this.getGameSettings(shareState.nativeGameConfig.userCountryId, shareState.nativeGameConfig.langId).then(() => {
          this.generalInit();
        });
      }
    }

    json = await shareState.nativeFunctionsUtils.callFunction("getGameConfig");

    if (json) {
      json = JSON.parse(json);
      shareState.nativeGameConfig = new NativeGameConfig(json);
      await this.getGameSettings(shareState.nativeGameConfig.userCountryId, shareState.nativeGameConfig.langId);

      await this.generalInit();
    }

    return json;
  }

  updateData() {
    super.updateData()
    const requestModel: GetRequestUserSettingsModel = new GetRequestUserSettingsModel(!shareState.isMobileView);

    return this.sendGetRequest(requestModel, (data) => shareState.user = new UserModel(data.user))
  }

  async getGameSettings(userCountryId: Number, languageId: Number) {
    const requestModel: GetRequestGameSettingsModel = new GetRequestGameSettingsModel(userCountryId, languageId);

    return this.sendGetRequest(requestModel, (data) => {
      shareState.settings = new GameSettings(data);

      document.documentElement.setAttribute("data-bolao-theme", shareState.settings.theme);
      localStorageUtils.setItem("bolao_theme", shareState.settings.theme);
    });
  }

  async initLanguage(): LanguageModel {
    let languageId: Number;

    const languages = Object.values(LANGUAGES).map((language: LanguageModel) => new LanguageModel(language));
    languageId = shareState.settings?.defaultLanguageId || 1;
    const language = languages?.find((language: LanguageModel) => language.id === languageId);
    this.setLanguage(language);

    return language;
  }

  async populateUserCountryId() {
    let userCountryId: Number = localStorageUtils.getItem(APP_CONST.LOCAL_STORAGE_KEYS.countryId);

    if (shareState.isWebView) {
      userCountryId = shareState.nativeGameConfig?.userCountryId;
    }

    if (!userCountryId) {
      userCountryId = shareState.settings?.countryId || 6;
    }

    shareState.userCountryId = userCountryId;

    if (userCountryId > 0) {
      localStorageUtils.setItem(APP_CONST.LOCAL_STORAGE_KEYS.countryId, userCountryId);
    }

    return userCountryId
  }

  async initUserId() {
    const userId = cookieUtils.getCookieByName('USER_ID');
    shareState.websiteVisitorId = JSON.parse(decodeURIComponent(userId));
  }

  async initLocaleForWeb() {
    const requestModel: GetRequestLocaleModel = new GetRequestLocaleModel();
    let userCountryId = localStorageUtils.getItem(APP_CONST.LOCAL_STORAGE_KEYS.countryId);
    let languageId = localStorageUtils.getItem(APP_CONST.LOCAL_STORAGE_KEYS.langId);

    if (!(userCountryId > 0)) {
      await fetch(requestModel.fullUrl).then(res => res.json()).then((data) => {
        shareState.userCountryId = data.UserCountryID;
        localStorageUtils.setItem(APP_CONST.LOCAL_STORAGE_KEYS.countryId, data.UserCountryID);

        userCountryId = data.UserCountryID;
        languageId = data.DefaultLangID;

        return data;
      })
    }

    return this.getGameSettings(userCountryId, languageId);
  }

  // resolveLanguageFromUrl() {
  //   const languages = Object.values(LANGUAGES).map((language: LanguageModel) => new LanguageModel(language));
  //   let force = true;
  //
  //   const firstPath = window.location.pathname.split('/')[1];
  //   const routes = Object.values(APP_CONST.ROUTE).map(r => r.replace('/', '')).filter(x => !!x);
  //
  //   if (!routes.includes(firstPath)) {
  //     shareState._currentDisplayName = firstPath;
  //     force = false;
  //   }
  //
  //   let urlLanguage: LanguageModel = languages?.find((language: LanguageModel) => language.displayName === firstPath);
  //   if (!urlLanguage) {
  //     urlLanguage = languages?.find((language: LanguageModel) => language.id === 1);
  //   }
  //
  //   return {urlLanguage, force};
  // }

  // onPopulateLanguage = () => {
  //   let languages: Array<LanguageModel> = Object.values(LANGUAGES);
  //   let siteLangId: Number = Number(localStorageUtils.getItem(APP_CONST.LOCAL_STORAGE_KEYS.siteLangId) || '1');
  //   let language = languages.find(lang => lang.subLanguagesIds.includes(siteLangId));
  //
  //   if (!language) {
  //     language = languages.find(lang => lang.id === 1);
  //   }
  //
  //   this.setLanguage(language);
  //
  //   return language;
  // }

  setLanguage(languageModel: LanguageModel) {

    shareState.language = languageModel;
    localStorageUtils.setItem(APP_CONST.LOCAL_STORAGE_KEYS.langId, languageModel.id);
    datetimeUtils.initialize(languageModel.cultureName);

    const rootElement = document.getElementById('bolao-root');
    const modalRootElement = document.getElementById('bolao-modal-root');

    rootElement.setAttribute("dir", languageModel.dir);
    modalRootElement.setAttribute("dir", languageModel.dir);
  }

  async initTerms() {
    const params = {
      categoryName: shareState.termsCategory,
      userCountryId: shareState.userCountryId,
      version: shareState.settings.version
    }
    const requestModel: GetRequestTermsModel = new GetRequestTermsModel(params);
    await fetch(requestModel.fullUrl)
      .then(async res => res.json())
      .then((data) => this.onPopulateTerms(data));
  }

  async initializeEnvironment(storeEnvOnLocalStorage) {
    let baseUrl = localStorageUtils.getItem(APP_CONST.LOCAL_STORAGE_KEYS.baseUrl);
    let envName = localStorageUtils.getItem(APP_CONST.LOCAL_STORAGE_KEYS.envName);

    if (!baseUrl || !envName) {
      baseUrl = shareState.nativeGameConfig?.serverUrl;
      envName = shareState.nativeGameConfig?.envName;

      if (!baseUrl || !envName) {
        //Get params from invitation link
        const queryParams = window.location.search.replace('?', '').split('&');
        const baseUrlParam = queryParams.find((query) => query.includes('env_api='));
        const envNameParam = queryParams.find((query) => query.includes('env='));
        baseUrl = baseUrlParam?.split('=')[1];
        envName = envNameParam?.split('=')[1];

        if (!baseUrl || !envName) {
          //Get params from terms
          baseUrl = shareState.settings?.environment?.baseUrl;
          envName = shareState.settings?.environment?.name;
        }
      }
    }

    if (storeEnvOnLocalStorage) {
      baseUrl && localStorageUtils.setItem(APP_CONST.LOCAL_STORAGE_KEYS.baseUrl, baseUrl);
      envName && localStorageUtils.setItem(APP_CONST.LOCAL_STORAGE_KEYS.envName, envName);
    }

    shareState.environment.baseUrl = baseUrl;
    shareState.environment.name = envName;
  }

  onPopulateTerms = (data) => {
    return data.terms.forEach((term: TermModel) => shareState.termsUtils.setTerm(term));
  }

  async initJoinGroupDialog() {
    const queryParams = window.location.search.replace('?', '').split('&');
    const envNameParam = queryParams.find((query) => query.includes('env='));
    const envBaseUrlParam = queryParams.find((query) => query.includes('env_api='));
    const groupIdParam = queryParams.find((query) => query.includes('GROUP_ID='));
    const groupId = groupIdParam?.split('=')[1];
    const envName = envNameParam?.split('=')[1];
    const envBaseUrl = envBaseUrlParam?.split('=')[1];

    const currentUserEnvironment = shareState.nativeGameConfig?.envName || localStorageUtils.getItem(APP_CONST.LOCAL_STORAGE_KEYS.envName);

    return this.handleJoinGroup(groupId, envName, envBaseUrl, currentUserEnvironment)
  }

  async handleJoinGroup(groupId, envName, envBaseUrl, currentUserEnvironment, invalidGroupInvitation, showDialog = false) {
    if (groupId !== undefined) {
      if ((!currentUserEnvironment || envName === currentUserEnvironment) && !(shareState.nativeGameConfig?.invalidGroupInvitation || invalidGroupInvitation === true)) {
        console.log(shareState, invalidGroupInvitation)
        const requestModel: GetRequestGroupInfoModel = new GetRequestGroupInfoModel({groupID: Number(groupId)});
        requestModel.apiEnvironment = decodeURIComponent(envBaseUrl);

        await this.sendGetRequest(requestModel, (dataSource) => this.onPopulateJoinGroup(dataSource, Number(groupId), showDialog));
      } else {
        shareState.shouldRenderInvitationFailedPopup = true;
        console.log(`not on the same env, user_env=${currentUserEnvironment} group_env=${envName}`)
      }
    }
  }

  async onPopulateJoinGroup(dataSource, groupId, showDialog = false) {
    if (dataSource.ok) {
      shareState.joinGroupDialog.group = new GroupModel({groupID: groupId, ...dataSource})

      if (!shareState.user || showDialog) {
        shareState.joinGroupDialog.isVisible = true;
      }
    }
  }

  async onAcceptGroupInvitation(groupId) {
    if (!shareState.user) {
      localStorageUtils.setItem(APP_CONST.LOCAL_STORAGE_KEYS.groupIdInvitation, groupId)
      shareState.welcomePage = LOGIN.PAGE.registration
    } else {
      await this.joinGroup(groupId)
    }
  }

  async joinGroup(groupId) {
    const requestModel: PostRequestJoinGroupModel = new PostRequestJoinGroupModel({groupId});

    await this.sendPostRequest(requestModel, () => {
      const rankingRoute: String = `${shareState.currentDisplayName ? `/${shareState.currentDisplayName}` : ''}${APP_CONST.ROUTE.leaderboard}/${groupId}`;

      shareState.rankingRoute = rankingRoute;
    });
  }

  async loginRedirect() {
    const userToken = localStorageUtils.getItem(APP_CONST.LOCAL_STORAGE_KEYS.userToken);
    if (!userToken && !shareState.nativeGameConfig?.userId) {
      await this.initializeEnvironment(false);
      shareState.user = null
    } else {
      return await this.getUserSettings(userToken);
    }
  }

  async login(firebaseToken) {
    let userAuthorizationToken = localStorageUtils.getItem(APP_CONST.LOCAL_STORAGE_KEYS.userAuthorizationToken);

    if (shareState.nativeGameConfig?.userId) {
      userAuthorizationToken = shareState.nativeGameConfig.userId;
      localStorageUtils.setItem(APP_CONST.LOCAL_STORAGE_KEYS.userAuthorizationToken, userAuthorizationToken)
    }

    if (!userAuthorizationToken) {
      const requestModel: PostRequestLoginModel = new PostRequestLoginModel(firebaseToken, REQUEST.AUTHENTICATOR_ID.firebase, true, shareState.deviceId, shareState.appType);

      await this.sendPostRequest(requestModel, (data) => data.userToken && localStorageUtils.setItem(APP_CONST.LOCAL_STORAGE_KEYS.userAuthorizationToken, data.userToken))
    }
  }

  async getUserSettings(firebaseToken) {
    await this.login(firebaseToken);
    await this.initializeEnvironment(true);
    const requestModel: GetRequestUserSettingsModel = new GetRequestUserSettingsModel(!shareState.isMobileView);

    return this.sendGetRequest(requestModel, (data) => {
      if (!data?.user) {
        this.initializeEnvironment(false);
        shareState.user = null
      } else {
        shareState.user = new UserModel(data.user)
        this.setWizardDisplay();
      }

    })
  }

  setWizardDisplay() {
    let wizardDisplayObject: Object = localStorageUtils.getJSON(APP_CONST.LOCAL_STORAGE_KEYS.wizardDisplay);
    let isWizardDisplayed = false;

    if (wizardDisplayObject) {
      try {
        isWizardDisplayed = wizardDisplayObject[shareState.user.userId];
      } catch (e) {
        isWizardDisplayed = false;
      }
    }

    const shouldShowWizard = !shareState.nativeGameConfig?.userId && !shareState.user.isTournamentStarted && shareState.user.showWizard && !isWizardDisplayed;

    shareState.wizard.isVisible = shouldShowWizard;
  }

  changeWizardPage(page) {
    shareState.wizard.currentPage = page;
  }

  closeWizardDisplay() {
    shareState.wizard.isVisible = false;
  }

  closeJoinDialog() {
    shareState.joinGroupDialog.isVisible = false;
    shareState.joinGroupDialog.group = null;
  }

  cleanActiveRequests() {
    visibleWidgetsManager.controllers.forEach((controller: WidgetController) => {
      controller.state.activeRequests.forEach((request: DeltaRequestModel) => {
        clearInterval(request.intervalId)
        request.intervalId = null
      })
      controller.state.activeRequests.length = 0;
    });

    window.bolaoIntervalsIds = []
  }

  closeInvitationFailedPopup() {
    shareState.shouldRenderInvitationFailedPopup = false;
  }

  async changeUserAgreement() {
    const requestModel = new PostRequestSetUserSettingsModel({
      userAgreement: this.state.userAgreementsValue,
      userAgreementPopupDisplayed: true
    });
    localStorageUtils.setItem(APP_CONST.LOCAL_STORAGE_KEYS.userAgreements, this.state.userAgreementsValue);

    await this.sendPostRequest(requestModel, (data) => this.onPopulateUser(data));

    this.resolver();
  }

  async onPopulateUser(dataSource) {
    shareState.user = new UserModel(dataSource.user)
  }

  setUserAgreements(value) {
    localStorageUtils.setItem(APP_CONST.LOCAL_STORAGE_KEYS.userAgreements, value);

    this.state.userAgreementsValue = value;
  }

  onUnload() {
    kinesisAnalyticsUtils.send(new ExitKinesisEvent());
  }
}
