import {AccaWidgetState} from "./acca-widget.state";
import {visibleWidgetsManager} from "../../infrastructure/managers/visible-widgets-manager";
import {GamesBetsWidgetController} from "../../controllers/games-bets.widget.controller";
import {GetRequestACCAGamesModel} from "../../models/request/category/games/get/get-request.acca-games.model";
import {GameWidgetInputModel} from "../games-widget/models/games.widget.game-input-model";
import {GameModel} from "../../models/game/game";
import {GetRequestMobileBetSlipModel} from "../../models/request/category/mobile/get/get-request.mobile.bet-slip.model";

export class AccaWidgetController extends GamesBetsWidgetController {
  state: AccaWidgetState;

  async init() {
    visibleWidgetsManager.set(this);

    this.state.isLoadingGames = true;

    return this.getGames();
  }

  async getGames() {
    const requestModel: GetRequestACCAGamesModel = new GetRequestACCAGamesModel();

    await this.sendGetRequest(requestModel, async (data) => {
      await this.onPopulateGames(data);
      await this.createGames(data.games);

      this.state.isLoadingGames = false;
    }, () =>
      this.state.isLoadingGames = false);
  }

  async createGames(games): Promise<Array<GameWidgetInputModel>> {
    const currentGames: Array<GameModel> = games?.map((game: GameModel) => {
      return new GameWidgetInputModel(game);
    });

    this.state.games = currentGames;

    return this.state.games;
  }

  async getBetSlip(): Promise<Object> {
    this.state.isLoadingBetSlip = true;
    const gamesRequestModels = this.state.games?.map((game: GameWidgetInputModel) => {
      return {id: game.sportifierGameId, score: `${game.bets[0].score}-${game.bets[1].score}`}
    })

    const lineTypes = [1, 126];
    const requestModel: GetRequestMobileBetSlipModel = new GetRequestMobileBetSlipModel(gamesRequestModels, lineTypes);

    await this.sendGetRequest(requestModel, async (data) => {
      this.state.selections = this.createSelections(data);
      this.state._accumulatedRate = data.AccumulatedRate;
      this.state.isLoadingBetSlip = false
    }, () => this.state.isLoadingBetSlip = false)
  }
}