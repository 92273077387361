import React, {useState, useEffect, useRef} from "react";
import imageCompression from "browser-image-compression";

import classNames from "classnames";
import styles from "./entity-info.module.scss";
import {Image} from "../image/image";
import {CameraImageRequestParams} from "../../icons/camera";
import {observer} from "mobx-react";
import {APP_CONST} from "../../consts";
import {LoadingIndicator} from "../loading-indicator/loading-indicator";
import {shareState} from "../../app/share-state";

export const EntityInfoImage = observer(({item, onImageUploaded}) => {
  const [hasError, setHasError] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const [imageSource, setImageSource] = useState(item?.imageURL)
  const hiddenFileInput = useRef(null);
  const errorMessage = hasError ? 'Unsupported file - Try again' : null; /*TODO: term is missing */

  useEffect(() => setImageSource(item?.imageURL), [item?.imageURL])

  const imageProps = {
    className: classNames(styles.image),
    src: imageSource,
    onClick: () => shareState.isWebView ? handleWebViewClick() : hiddenFileInput.current.click()
  }

  const iconProps = {
    className: classNames(styles.icon),
    src: new CameraImageRequestParams(16, 16),
  }

  const compressImage = (image) => {
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 1920,
    };

    setInProgress(true);

    // console.log('original - size: ', image.size);
    let newImage = URL.createObjectURL(image);

    const maxSizeInKB = options.maxSizeMB * 1000;
    const imageSizeInKB = image.size / 1024;

    if (maxSizeInKB < imageSizeInKB) {
      imageCompression(image, options).then(imageCompressed => {
        newImage = URL.createObjectURL(imageCompressed);
        // console.log('image compressed - size: ', imageCompressed.size);
      }).catch((e) => {
        console.log('image compression has been failed: ', e)
      });
    }

    return newImage
  }

  const onInputChange = (event) => {
    const fileUploaded = event.target.files[0];
    const validExtensions = Object.values(APP_CONST.IMAGE_EXTENSION);
    if (validExtensions.includes(fileUploaded.type)) {
      const image = compressImage(fileUploaded)

      setImageSource(image);
      onImageUploaded && onImageUploaded(image).then(() => setInProgress(false));

    } else {
      setHasError(true)
      setTimeout(() => setHasError(false), 3000)
    }
  }

  const inputProps = {
    type: 'file',
    ref: hiddenFileInput,
    style: {display: 'none'},
    onChange: (e) => onInputChange(e),
  }

  const iconContainerProps = {
    className: classNames(styles.icon_container),
    onClick: () => shareState.isWebView ? handleWebViewClick() : hiddenFileInput.current.click()
  }

  const handleWebViewClick = () => {
    shareState.nativeFunctionsUtils.callFunction("pickPhotoClicked");

    window.setUserPhoto = (image) => {
      try {
        setImageSource(image);
        onImageUploaded && onImageUploaded(image).then(() => setInProgress(false));
      } catch (e) {
        console.log(e)
        setHasError(true)
        setInProgress(false)
      }
    }
  }

  return (
      <>
        <input {...inputProps}/>
        {
          inProgress ? <div className={classNames(styles.loader, styles.image)}><LoadingIndicator size={20}/></div> :
              <Image {...imageProps}/>
        }
        <div {...iconContainerProps}>
          <Image {...iconProps}/>
        </div>
        <div className={classNames(styles.error)}>{errorMessage}</div>
      </>
  );
});


