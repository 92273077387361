import {makeObservable, observable} from "mobx";
import {EMPTY_SCORE} from "../../../consts";

export class GameInputBetModel {
  @observable score: Number;
  @observable active: Boolean;

  constructor(initialScore: Number = EMPTY_SCORE) {

    this.score = initialScore;
    this.active = false;

    makeObservable(this)
  }
}
