import {ImageRequestParams} from "../models/image/image-request-params";
export class ProfileImageRequestParams extends ImageRequestParams {
  constructor(width: Number, height: Number, color: String) {
    super({width, color});

    this._resourceName = `bolao24/Icons/Profile_Icon`;
    this.ratio = 3;
    this.c = null;
  }
}
