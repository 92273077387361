export const IMAGE = {
  domain: 'https://imagecache.365scores.com/image/upload/',

  FORMAT: {
    AUTO: 'auto',
    PNG: 'png',
    SVG: 'svg',
    GIF: 'gif',
    WEBP: 'webp',
    JPG: 'jpg'
  }
}