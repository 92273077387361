import classNames from "classnames";
import styles from "../styles/login.module.scss";
import {Image} from "../../../components/image/image";

export const LoginWidgetButton = ({className, source, label, onButtonClick}) => {
  const googleSignInButtonProps = {
    className: classNames(styles.button, className),
    onClick: () => onButtonClick && onButtonClick()
  }

  const signInImageProps = {
    className: classNames(styles.button_logo),
    src: source
  }

  return (
    <div {...googleSignInButtonProps}>
      <Image {...signInImageProps}/>
      <div className={classNames(styles.button_text)}>{label}</div>
    </div>
  );
};

