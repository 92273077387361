import {RequestModel} from "../../../request";
import {REQUEST} from "../../../requests.consts";
import {shareState} from "../../../../../app/share-state";

export class GetRequestAllTeamsModel extends RequestModel {
  constructor() {
    super(REQUEST.CATEGORY.tournament, REQUEST.ACTION.TOURNAMENT.getAllTeams, {}, false, true, false, false);

    this.apiEnvironment = shareState.environment.baseUrl;
    this.method = REQUEST.METHOD_TYPE.get;
  }
}
