import {shareState} from "../../../../../app/share-state";
import {RequestModel} from "../../../request";
import {REQUEST} from "../../../requests.consts";

export class GetRequestMobileBetSlipModel extends RequestModel {
  constructor(gamesRequestModels: Array<Object>) {
    super(REQUEST.CATEGORY.data, REQUEST.ACTION.DATA.betsSlip, {}, false, true, false, false);

    this.method = REQUEST.METHOD_TYPE.get;
    this.gamesRequestModels = gamesRequestModels;
    this.apiEnvironment = `https://${this.getEnv()}.${this.domain}`
  }

  getEnv() {
    let apiType: String = process.env.REACT_APP_API_ENV;

    return apiType;
  }

  get userCountryParams(): String {
    const userCountryParams: String = shareState.userCountryId ? `&uc=${shareState.userCountryId}` : '';

    return userCountryParams;
  }

  get gamesParams(): String {
    let gamesParams: String = '';
    let index = 0;

    this.gamesRequestModels?.forEach(({id, score, lineType}) => {
      if(score) {
        gamesParams += `&gameIds[${index}]=${id}&scores[${index}]=${score}`;

        if(lineType) {
          gamesParams +=`&linetypeIds[${index}]=${lineType}`
        }
        index++;
      }
    })

    return gamesParams;
  }

  get appTypeParams(): String {
    const appTypeParams: String = `&appType=${shareState.appType}`;
    // const appTypeParams: String = `&appType=${REQUEST.appType}`;

    return appTypeParams;
  }

  get appVersionParams(): String {
    const appTypeParams: String = shareState.nativeGameConfig?.appVersion ? `&appversion=${shareState.nativeGameConfig?.appVersion.split(" ")[0]}` : '';
    // const appTypeParams: String = `&appType=${REQUEST.appType}`;

    return appTypeParams;
  }

  get withLineTypesParams(): String {
    const withLineTypesParams: String = `&withLineTypes=true`;

    return withLineTypesParams;
  }

  get withTopBookmakerParams(): String {
    const withTopBookmakerParams: String = shareState.settings.topBookmakerId ? `&topbm=${shareState.settings.topBookmakerId}` : '';

    return withTopBookmakerParams;
  }

  get fullUrl(): String {
    const fullUrl: String = `${this.url}${this.languageParams}${this.appTypeParams}${this.appVersionParams}${this.userCountryParams}${this.gamesParams}${this.withLineTypesParams}${this.withTopBookmakerParams}`;

    return fullUrl
  }
}
