import React, {useCallback, useEffect, useRef} from 'react';

import classNames from "classnames";
import styles from './game.module.scss';

export const GameScoreInputActive = ({onChangeValue, setInactive}) => {
  const mounted = useRef(false);

  const onWindowClickHandler = useCallback((e) => setInactive(e));
  const handleKeyPress = useCallback((e) => {
    if (mounted.current) {
      IsNumeric(e) && onChangeValue(e, e.key);
    }
  });

  useEffect(() => {
    mounted.current = true;
    window.addEventListener('keypress', (e) => handleKeyPress(e));
    document.addEventListener('click', (e) => onWindowClickHandler(e));

    return () => {
      mounted.current = false;
      window.removeEventListener('keypress', (e) => handleKeyPress(e));
      document.removeEventListener('click', (e) => onWindowClickHandler(e));
    }
  }, [])

  const IsNumeric = (e) => {
    const keyCode = e.which ? e.which : e.keyCode
    const IsNumeric = keyCode >= 48 && keyCode <= 57;

    return IsNumeric;
  }

  return (
    <div className={classNames(styles.score_input_hover)}>
      <div className={classNames(styles.score_input_active_mark)}/>
      <div className={classNames(styles.score_input_active)}/>
    </div>
  )
};
