import classNames from "classnames";
import styles from './game.module.scss';
import {GameCompetitor} from "./game.competitor";
import {GameScores} from "./game.scores";

export const GameBoard = ({
                            item,
                            isHomePressed,
                            controller,
                            setActive,
                            onInputChange,
                            shouldRenderGameBets
                          }) => {
  const {competitors} = item;
  const homeCompetitor = {
    competitor: competitors[0],
    gridArea: 'home_competitor'
  }

  const awayCompetitor = {
    competitor: competitors[1],
    gridArea: 'away_competitor'
  }

  const scoreProps = {
    item,
    controller,
    isHomePressed,
    shouldRenderGameBets,
    setActive: (e, index) => setActive(e, index),
    onChangeValue: (e, value, index) => onInputChange(e, value, index)
  }

  return (
    <div className={classNames(styles.item)}>
      <GameCompetitor {...homeCompetitor}/>
      <GameScores {...scoreProps}/>
      <GameCompetitor {...awayCompetitor}/>
    </div>
  );
};


