import React from 'react';

import classNames from "classnames";
import styles from './game.module.scss';
import {GameScoreInput} from "./game.score-input";
import {observer} from "mobx-react";

export const GameGoal = observer(({
                           controller,
                           score,
                           item,
                           setActive,
                           bet,
                           isInputActive,
                           onChangeValue
                         }) => {
  const {state: {isOtherUser}} = controller;
  const {isSchedule} = item;

  const inputGoalProps = {
    value: bet,
    isInputActive,
    onChangeValue: (e, value) => onChangeValue(e, value),
    setActive: (e) => setActive(e),
    setInactive: () => item.setInactive(),
  }

  return isSchedule && !isOtherUser ? <GameScoreInput {...inputGoalProps}/> :
    <div className={classNames(styles.goal)}>{score}</div>;
});
