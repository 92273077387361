import {Component} from "react";
import {APP_CONST} from "../../../consts";
import {override} from "mobx";
import {WizardWidgetPageModel} from "./wizard-widget.page.model";
import {SelectionListWidget} from "../../selection-list.widget/components/selection-list.widget";
import {shareState} from "../../../app/share-state";
import {WIZARD} from "../wizard.widget.consts";
import {WizardTeamSelectionSkipKinesisEvent} from "../../../analytics/kinesis/events/wizard/wizard.team-selection.skip.kinesis-event";
import {kinesisAnalyticsUtils} from "../../../analytics/kinesis/kinesis.analytics-utils";

export class WizardWidgetPageWinningTeamModel extends WizardWidgetPageModel {
  @override get WidgetComponent(): Component {
    return SelectionListWidget;
  }

  @override get title(): String {
    return shareState.termsUtils.terms.WC_2022_SELECT_WINNING_TEAM;
  }

  @override get nextPage(): Number {
    return WIZARD.PAGES.topScorer;
  }

  @override get textButton(): String {
    return this.hasSelection ? shareState.termsUtils.terms.WC_2022_NEXT_BUTTON : shareState.termsUtils.terms.WC_2022_SKIP_BUTTON;
  }

  @override get hasSelection(): String {
    return shareState.user?.winnerTeamId > 0;
  }

  @override get context(): String {
    return APP_CONST.ROUTE.winningTeam;
  }

  @override get shouldRenderArrow(): Boolean {
    return !shareState.isMobileView;
  }

  buttonEventModel(): WizardTeamSelectionSkipKinesisEvent {
    return kinesisAnalyticsUtils.send(new WizardTeamSelectionSkipKinesisEvent({id: shareState.user.winnerTeamId}));
  }
}
