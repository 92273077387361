import {shareState} from "../../../app/share-state";
import {observer} from "mobx-react";
import {NavigationBarLinkButton} from "./navigation-bar.link-button";
import {APP_CONST, COMPETITION_PAGES} from "../../../consts";
import classNames from "classnames";
import styles from "../navigation-bar.module.scss";
import {CompetitionPageSectionRequestParams} from "../../../icons/competition-page-section";

export const NavigationBarDesktopButtonsLinks = observer(() => {
  const standingsProps = {
    page: COMPETITION_PAGES.standings,
    iconType: new CompetitionPageSectionRequestParams(24, 24, APP_CONST.COMPETITION_PAGES_BUTTON_IDS.standings),
    label: shareState.termsUtils.terms.WC_GROUP_TABLES
  }

  const bracketsProps = {
    page: COMPETITION_PAGES.brackets,
    iconType: new CompetitionPageSectionRequestParams(27, 22, APP_CONST.COMPETITION_PAGES_BUTTON_IDS.brackets),
    label: shareState.termsUtils.terms.WC_WEB_BRACKETS
  }

  const statsProps = {
    page: COMPETITION_PAGES.stats,
    iconType: new CompetitionPageSectionRequestParams(24, 24, APP_CONST.COMPETITION_PAGES_BUTTON_IDS.stats),
    label: shareState.termsUtils.terms.WC_STATISTICS_NEWWEB
  }

  return (
    <div className={classNames(styles.links)}>
      <div className={classNames(styles.link_title)}>{shareState.termsUtils.terms.WC_2022_EXPLORE_TEXT}</div>
      <NavigationBarLinkButton {...standingsProps}/>
      <NavigationBarLinkButton {...bracketsProps}/>
      <NavigationBarLinkButton {...statsProps}/>
    </div>
  );
});

