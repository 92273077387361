import classNames from "classnames";
import styles from './game.module.scss';
import {shareState} from "../../app/share-state";
import {observer} from "mobx-react";
import {LazyElementWrapper} from "../lazy-element-wrapper/lazy-element-wrapper";
import {useEffect, useRef} from "react";
import {kinesisAnalyticsUtils} from "../../analytics/kinesis/kinesis.analytics-utils";
import {GameClickKinesisEvent} from "../../analytics/kinesis/events/game/game.click.kinesis-event";
import {useCurrentPath} from "../../hooks/use-current-path";

export const GameWrapper = observer(({item, children, isSmallView, isCurrentGame}) => {
  const ref = useRef();
  const {pathName} = useCurrentPath();

  useEffect(() => {
    if (item.isActive || isCurrentGame) {
      ref?.current?.scrollIntoView({behavior: "smooth", block: "center"})
    }
  }, [item.isActive, ref]);

  const wrapperProps = {
    ref,
    className: classNames(styles.container,
      {[styles.mobile_view]: shareState.isMobileView || isSmallView},
      {[styles.keyboard_container]: item.isActive},
      {[styles.addons_included]: item.shouldShowAddon}
    )
  }


  const onGameClick = () => {
    kinesisAnalyticsUtils.send(new GameClickKinesisEvent({
      id: item.id,
      status: item.status,
      source: pathName
    }));

    shareState.isWebView && shareState.nativeFunctionsUtils.callFunction("gameClicked", {gameId: item.sportifierGameId})
  }


  const baseContainerProps = {
    className: classNames(styles.link),
    onClick: onGameClick
  }
  const linkProps = {
    href: item.link,
    target: "_blank",
    rel: "noreferrer",
    ...baseContainerProps
  }

  return (
    <LazyElementWrapper {...wrapperProps}>
      {
        !shareState.isWebView ? <a {...linkProps}>
          {children}
        </a> : <div {...baseContainerProps}>
          {children}
        </div>
      }
    </LazyElementWrapper>
  );
});


