import {BaseModel} from "./base";
import {REQUEST} from "./request/requests.consts";
import {shareState} from "../app/share-state";

export class CompetitorModel extends BaseModel {
  name: String;
  competitorID: Number;
  countryId: Number;
  nameForUrl: String;

  constructor(model) {
    super(model);

    this.id = model.competitorID;
    this.name = model.name;
    this.countryId = model.countryID;
    this.nameForUrl = model.nameForUrl;
    this.nativeFunctionName = "competitorClicked";
  }

  get nativeLinkParams() {
    const linkParams = {
      id: this.id,
    }

    return linkParams;
  }


  get link() {
    let linkPrefix: String = `https://${REQUEST.domain}/`

    if(shareState.language.id !== 1) {
      linkPrefix += `${shareState.language.displayName}/`
    }

    return `${linkPrefix}${shareState.tournamentInfo.sportTypeUrlName}/team/${this.nameForUrl}-${this.id}`
  }
}

