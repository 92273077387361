import {Image} from "../image/image";
import styles from "./settings-popup.module.scss";
import classNames from "classnames";
import {kinesisAnalyticsUtils} from "../../analytics/kinesis/kinesis.analytics-utils";
import {SettingsClickKinesisEvent} from "../../analytics/kinesis/events/settings/settings.click.kinesis-event";
import {UserEditImageRequestParams} from "../../icons/user-edit";

export const SettingsButton = ({shouldRenderSettingsButton, className, openSettingsPopup}) => {
  const settingsProps = {
    className: classNames(styles.icon, className),
    src: new UserEditImageRequestParams(20, 16),
    onClick: () => {
      kinesisAnalyticsUtils.send(new SettingsClickKinesisEvent())
      openSettingsPopup()
    }
  }

  return shouldRenderSettingsButton ? <Image {...settingsProps}/>: null;
};

