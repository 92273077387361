export class BaseModel {
  id: String;

  constructor(model) {
    this.id = model?.id;
  }

  get ObjectType(): String {
    return this.constructor.name
  }
}