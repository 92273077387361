import classNames from "classnames";
import styles from "./app-popups.module.scss";
import {shareState} from "../../share-state";
import {observer} from 'mobx-react';
import {Popup} from "../../../components/popup/popup";
import {Image} from "../../../components/image/image";
import {useHistory} from "react-router-dom";
import {InvitationBackgroundImageRequestParams} from "../../../icons/assets/invitation-background";

export const AppJoinGroupPopup = observer(({controller}) => {
  const history = useHistory();
  const {terms: {WC_2022_ACCEPT_AND_PLAY, WC_2022_INVINTATION_TERM}} = shareState.termsUtils;

  const popupProps = {
    className: classNames(styles.popup_container, {[styles.mobile_view]: shareState.isMobileView}),
    style: {
      backgroundImage: `url(${new InvitationBackgroundImageRequestParams()})`,
    },
    onClose: () => {
      controller.closeJoinDialog()
      history.push(`${shareState.currentDisplayName ? `/${shareState.currentDisplayName}` : ''}`)
    },
  }

  const imageProps = {
    className: classNames(styles.group_logo),
    src: shareState.joinGroupDialog.group?.imageURL
  }

  const buttonProps = {
    className: classNames(styles.popup_button),
    onClick: () => {
      controller.onAcceptGroupInvitation(shareState.joinGroupDialog.group.id);
      controller.closeJoinDialog();
    },
  }

  return (
    shareState.joinGroupDialog.isVisible ?
      <Popup {...popupProps}>
        <div>{WC_2022_INVINTATION_TERM}</div>
        <Image {...imageProps}/>
        <bdi className={classNames(styles.group_name)}>{shareState.joinGroupDialog.group?.name}</bdi>
        <div {...buttonProps}>{WC_2022_ACCEPT_AND_PLAY}</div>
      </Popup> : null
  );
});


