import {RequestModel} from "../../../request";
import {REQUEST} from "../../../requests.consts";
import {shareState} from "../../../../../app/share-state";

export class PostRequestRandomizeEmptyBetsModel extends RequestModel {
  constructor() {
    super(REQUEST.CATEGORY.bets, REQUEST.ACTION.BETS.randomizeEmptyBets, {}, true, true, true, true);

    this.apiEnvironment = shareState.environment.baseUrl;
    this.method = REQUEST.METHOD_TYPE.post;
  }

  get body() {
    const lang = {"lang": shareState.language.id}

    return {...lang};
  }
}
