import {KinesisEvent} from "../kinesis.event";
import {KINESIS} from "../../kinesis.consts";

export class TopScorerDisplayKinesisEvent extends KinesisEvent {
  constructor(properties) {
    super(
      KINESIS.CATEGORY.bolao,
      KINESIS.ACTION.topScorer,
      KINESIS.LABEL.display,
      null,
      properties
    );
  }

  getProperties(params) {
    const props = super.getProperties()

    return {
      ...props,
      athlete_id: params.id
    }
  }
}
