import {KinesisEvent} from "../kinesis.event";
import {KINESIS} from "../../kinesis.consts";

export class SettingsLeaveGroupClickKinesisEvent extends KinesisEvent {
  constructor(properties) {
    super(
      KINESIS.CATEGORY.bolao,
      KINESIS.ACTION.settings,
      KINESIS.LABEL.leaveGroup,
      KINESIS.VALUE.click,
      properties
    );
  }

  getProperties(params) {
    const props = super.getProperties()

    return {
      ...props,
      group_id: params?.groupId,
    }
  }
}
