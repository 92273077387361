import React from "react";
import classNames from "classnames";
import styles from "./version.module.scss";
import {shareState} from "../../app/share-state";
import {APP_CONST} from "../../consts";

export const Version = ({className}) => {
  const version = require('../../../package.json').version;

  return process.env.REACT_APP_ENVIRONMENT_TYPE !== APP_CONST.API_ENVIRONMENT.prod ? <div className={classNames(styles.version, {[styles.mobile_view]: shareState.isMobileView}, className)}>{version}</div> : null;
}
