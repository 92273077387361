import classNames from "classnames";
import styles from "../app-popups.module.scss";
import {shareState} from "../../../share-state";
import {observer} from 'mobx-react';
import {Popup} from "../../../../components/popup/popup";
import {Button} from "../../../../components/button/button";
import {AppUserAgreementPopupInput} from "./app.user-agreement.popup.input";
import {useEffect, useRef} from "react";

export const AppUserAgreementPopup = observer(({controller}) => {
    const ref = useRef();
    const {
      terms: {
        BOLAO_POPUP_DOMINOS_HEADER,
        BOLAO_POPUP_DOMINOS_TEXT,
        BOLAO_POPUP_DOMINOS_APPROVE
      }
    } = shareState.termsUtils;

    const popupProps = {
      className: classNames(styles.popup_container, styles.user_agreement, {[styles.mobile_view]: shareState.isMobileView}),
    }

    const buttonProps = {
      label: BOLAO_POPUP_DOMINOS_APPROVE,
      className: classNames(styles.popup_button),
      onClick: () => {
        shareState.userAgreementPopup = false;
        controller.changeUserAgreement();
      },
    }


    const termsAndPolicyProps = {
      className: classNames(styles.user_agreement_text),
      dangerouslySetInnerHTML: {
        __html: BOLAO_POPUP_DOMINOS_TEXT
          .replaceAll('<a', '<div')
          .replaceAll('</a>', '</div>')
          .replaceAll('href', 'data-url')
      },
      ref
    }

    const onClick = (url) => {
      shareState.isWebView ? shareState.nativeFunctionsUtils.callFunction("openBrowser", {url}) : window.open(url);
    }

    useEffect(() => {

      const children = ref.current?.children;
      if (children) {
        const arr = [].slice.call(children);

        arr?.forEach((child) => {
          child.onclick = () => onClick(child.dataset.url);
          child.className = styles.link;
        })
      }
    }, []);

    return (
      shareState.userAgreementPopup ?
        <Popup {...popupProps}>
          <div className={classNames(styles.user_agreement_header)}>{BOLAO_POPUP_DOMINOS_HEADER}</div>
          <div {...termsAndPolicyProps} />
          <AppUserAgreementPopupInput controller={controller}/>
          <Button {...buttonProps}/>
        </Popup> : null
    );
  })
;


