import {useEffect, useState} from "react";
import {FetchUtils} from "../infrastructure/utils/fetch-utils";

export const BaseComposer = (settings) => (Widget) => ((props) => {
  const [controller, setController] = useState(null);

  useEffect(() => {
    const fetchManager = new FetchUtils();
    const widgetController = new settings.controller(props, settings.state, fetchManager);

    widgetController.init();

    setController(widgetController);
  }, []);

  return controller ? <Widget {...props} controller={controller}/> : null;
});
