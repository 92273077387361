import {KinesisEvent} from "../kinesis.event";
import {KINESIS} from "../../kinesis.consts";

export class PredictionDisplayKinesisEvent extends KinesisEvent {
  constructor(properties) {
    super(
      KINESIS.CATEGORY.bolao,
      KINESIS.ACTION.prediction,
      KINESIS.LABEL.display,
      null,
      properties
    );
  }

  getProperties(params) {
    const props = super.getProperties()

    return {
      ...props,
      user_id: params?.userId,
      is_main_user: Number(!params?.isOtherUser)
    }
  }
}
