import {BaseModel} from "./base";
import {shareState} from "../app/share-state";
import {REQUEST} from "./request/requests.consts";

export class PlayerModel extends BaseModel {
  athleteId: Number;
  name: String;
  countryName: String;
  countryId: Number;
  score: Number;
  nameForUrl: String;

  constructor(model) {
    super(model);

    this.id = model.athleteID;
    this.name = model.name;
    this.countryId = model.countryID;
    this.countryName = model.countryName;
    this.score = model.score;
    this.nameForUrl = model.nameForUrl;
    this.nativeFunctionName = "athleteClicked";
  }

  get nativeLinkParams() {
    const linkParams = {
      id: this.id,
      isNational: true, //TODO: isNational should return form api
      competitionId: shareState.tournamentInfo.competitionId
    }

    return linkParams;
  }

  get link(): String {
    let linkPrefix: String = `https://${REQUEST.domain}/`

    if(shareState.language.id !== 1) {
      linkPrefix += `${shareState.language.displayName}/`
    }

    return `${linkPrefix}${shareState.tournamentInfo.sportTypeUrlName}/player/${this.nameForUrl}-${this.id}#national&competitionId=${shareState.tournamentInfo.competitionId}`
  }
}
