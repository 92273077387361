import {observer} from "mobx-react";
import {GameKeyboardButton} from "./game.keyboard.button";

export const GameKeyboardButtons = observer(({onSetValue}) => {
  const keyboardButtons = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  const onButtonClickHandle = (e, button) => {
    e.stopPropagation();
    e.preventDefault();

    onSetValue(button);
  }

  return (
    keyboardButtons.map((button: Number) => <GameKeyboardButton
      button={button}
      key={button}
      onClick={(e) => onButtonClickHandle(e, button)}
    />)
  )
});


