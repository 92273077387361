import {ImageRequestParams} from "../models/image/image-request-params";

export class BookmakerImageRequestParams extends ImageRequestParams {
  constructor(id: Number, isTransparent: Boolean = false, width: Number = 61, height: Number = 20) {
    super({width, height});

    this._resourceName = `Bookmakers/${isTransparent ? 'NoBG/' : ''}${id}`
  }

  get fallbackResourceName(): null {
    return 'Bookmakers';
  }
}