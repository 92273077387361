import classNames from "classnames";
import styles from "./profile-header.module.scss";
import {observer} from "mobx-react";
import {Image} from "../image/image";

export const ProfileHeaderUserInfo = observer(({user}) => {
  const imageProps = {
    src: user.imageURL,
    referrerPolicy: "no-referrer",
    className: classNames(styles.header_button_profile_logo)
  }

  return (
    <div className={styles.profile_button}>
      <Image {...imageProps}/>
      <div className={classNames(styles.header_button_text)}>{user.name}</div>
    </div>
  );
});

