import {Helmet} from "react-helmet-async";
import {shareState} from "../share-state";

export const AppMetadata = () => {
  const title = shareState.termsUtils.terms['MD_TITLE_BOLAO_HOMEPAGE'];
  const description = shareState.termsUtils.terms['MD_DESCRIPTION_BOLAO_HOMEPAGE'];

  return <Helmet>
    {
      process.env.REACT_APP_ENVIRONMENT_TYPE !== "production" ? <meta name="robots" content="noindex, nofollow"/> : null
    }
    <title>{title}</title>
    <meta name="description" content={description}/>
  </Helmet>
}