import '../styles/app.module.scss';
import classNames from "classnames";
import styles from "../styles/app.module.scss";
import {NavigationBarDesktop} from "../../components/navigation-bar/desktop/navigation-bar.desktop";
import {NavigationBarMobile} from "../../components/navigation-bar/mobile/navigation-bar.mobile";
import {observer} from 'mobx-react';
import {shareState} from "../share-state";

export const AppNavigationBar = observer(({controller}) => {
  return (
    <div className={classNames(styles.navigation_bar)}>
      {
        shareState.isMobileView ? <NavigationBarMobile controller={controller}/> : <NavigationBarDesktop controller={controller}/>
      }
    </div>
  );
});

