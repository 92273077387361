import {ImageRequestParams} from "../models/image/image-request-params";

export class AvatarImageRequestParams extends ImageRequestParams {
  constructor(width, color) {
    super({color, width});

    this._resourceName = `bolao24/Icons/Avatar`;
    this.ratio = 3;
    this.c = null;
  }
}
