import classNames from "classnames";
import styles from "../styles/login.module.scss";
import {LOGIN} from "../login.widget.consts";
import {LoginWidgetSteps} from "./steps/login.widget.steps";
import {shareState} from "../../../app/share-state";
import {kinesisAnalyticsUtils} from "../../../analytics/kinesis/kinesis.analytics-utils";
import {LandingPagePlayNowClickKinesisEvent} from "../../../analytics/kinesis/events/login/landing-page.play-now.click.kinesis-event";

export const LoginWidgetWelcomeContent = ({controller}) => {
  const buttonProps = {
    className: classNames(styles.play_now_button),
    onClick: () => {
      kinesisAnalyticsUtils.send(new LandingPagePlayNowClickKinesisEvent());

      controller.changePage(LOGIN.PAGE.registration)
      // window.scrollTo(0, 0);
    }
  }

  return (
    <div className={classNames(styles.bottom_content)}>
      <LoginWidgetSteps controller={controller}/>
      <div {...buttonProps}>{shareState.termsUtils.terms.WC_2022_ACCAEPT_AND_PLAY}</div>
    </div>
  )
};
