import React, {useEffect, useState} from 'react';
import {useFallbackRef} from "../../infrastructure/hooks/useFallbackRef";
import {useInView} from 'react-intersection-observer';

export const LazyElementWrapper = React.forwardRef(({children, ...props}, forwardRef) => {
  const [isInView, setIsInView] = useState(false);
  const ref = useFallbackRef(forwardRef)

  const config = {triggerOnce: false};
  const [refIntersectionObserver, inView] = useInView(config);

  useEffect(() => {
    if (ref.current) {
      refIntersectionObserver(ref.current);
    }
  }, [refIntersectionObserver]);

  useEffect(() => {
    setIsInView(inView)
  }, [inView]);

  return (
    <div ref={ref} {...props}>
      {isInView ? children : null}
    </div>
  );
});
