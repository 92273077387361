import {BaseModel} from "./base";
import {makeObservable, observable} from "mobx";
import {APP_CONST} from "../consts";

export class NativeGameConfig extends BaseModel {
  userId: String;
  langId: Number;
  groupId: Number;
  appVersion: String;
  partnerId: String;
  serverUrl: String;
  userCountryId: Number;
  @observable showInfluencers: Boolean;
  envName: String;
  appType: Number;
  deviceId: String;
  invalidGroupInvitation: Boolean;
  competitionId: Number;

  constructor(config) {
    super();

    this.userId = config.user_id;
    this.langId = parseInt(config.lang_id);
    this.groupId = parseInt(config.group_id);
    this.appVersion = config.app_version;
    this.partnerId = config.partner_id;
    this.serverUrl = config.server_url;
    this.userCountryId = parseInt(config.country_id);
    this.showInfluencers = config.show_influencers === "true";
    this.envName = config.env_name;
    this.appType = parseInt(config.app_type);
    this.deviceId = config.device_id;
    this.invalidGroupInvitation = config.invalid_group_invitation;
    this.competitionId = config.competition_id;
    this.screen = config.screen ? new NativeGameConfigScreen(config.screen) : null;

    makeObservable(this);
  }

  isVersionSupported(iOSVersion: String, androidVersion: Number) {
    let isVersionSupported = false;

    if (this.appType === APP_CONST.PLATFORMS.android) {
      isVersionSupported = !androidVersion || parseInt(this.appVersion) >= androidVersion;
    } else if (this.appType === APP_CONST.PLATFORMS.ios) {
      const appVersionWithoutBuild = this.appVersion?.split(" ")[0];
      isVersionSupported = !iOSVersion || this.isVersionSupportedIOS(appVersionWithoutBuild, iOSVersion);
    } else if (this.appType === APP_CONST.PLATFORMS.web) {
      return true;
    }

    return isVersionSupported;
  }


  isVersionSupportedIOS = (currentVersion, minVersion) => {
    // Split the version strings into their individual components
    const current = currentVersion.split('.').map(Number);
    const min = minVersion.split('.').map(Number);

    // Compare the versions component by component
    for (let i = 0; i < 3; i++) {
      if (current[i] > min[i]) {
        return true;
      } else if (current[i] < min[i]) {
        return false;
      }
    }

    // If all components are equal, return true (since it's not less than)
    return true;
  }
}

export class NativeGameConfigScreen extends BaseModel {
  name: String;
  groupId: Number;

  constructor(model) {
    super();

    this.name = model.name;
    this.groupId = model.groupId;
  }
}

