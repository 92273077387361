import classNames from "classnames";
import styles from "../profile-header.module.scss";
import {observer} from "mobx-react";

export const ProfileHeaderStripSection = observer(({name, value}) => {
  return (
    <div className={classNames(styles.strip_item)}>
      {value}
      <div className={classNames(styles.header_button_subtext)}>{name}</div>
    </div>
  );
});

