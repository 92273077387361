import {RequestModel} from "../../../request";
import {REQUEST} from "../../../requests.consts";
import {shareState} from "../../../../../app/share-state";

export class PostRequestSetTopPlayerModel extends RequestModel {
  playerId: Number;

  constructor(playerId) {
    super(REQUEST.CATEGORY.bets, REQUEST.ACTION.BETS.setTopScorer, {}, true, false, true, true);

    this.apiEnvironment = shareState.environment.baseUrl;
    this.method = REQUEST.METHOD_TYPE.post;
    this.playerId = playerId;
  }

  get body() {
    const params = this.playerId ? {'topScorerID': this.playerId} : {}

    return {...params};
  }
}
