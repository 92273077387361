import {WidgetController} from "../../controllers/widget.controller";
import {LoginWidgetState} from "./login.widget.state";
import {FirebaseAuth} from "../../models/firebase/firebase-auth";
import {AppState} from "../../app/app.state";
import {shareState} from "../../app/share-state";
import {UserModel} from "../../models/user";
import {GetRequestUserSettingsModel} from "../../models/request/category/users/get/get-request.user-settings.model";
import {PostRequestLoginModel} from "../../models/request/category/users/post/post-request.login.model";
import {REQUEST} from "../../models/request/requests.consts";
import {APP_CONST} from "../../consts";
import {PostRequestJoinGroupModel} from "../../models/request/category/groups/post/post-request.join-group.model";
import {kinesisAnalyticsUtils} from "../../analytics/kinesis/kinesis.analytics-utils";
import {LandingPageDisplayKinesisEvent} from "../../analytics/kinesis/events/login/landing-page.display.kinesis-event";
import {SignInDisplayKinesisEvent} from "../../analytics/kinesis/events/login/sign-in.display.kinesis-event";
import {localStorageUtils} from "../../infrastructure/utils/locale-storage-utils";
import {getRedirectResult} from "firebase/auth";
import {LOGIN} from "./login.widget.consts";

export class LoginWidgetController extends WidgetController {
  state: LoginWidgetState;
  appState: AppState;
  firebaseAuth: FirebaseAuth;

  constructor(props, stateType, fetchManager) {
    super(props, stateType, fetchManager);

    this.firebaseAuth = new FirebaseAuth();
  }

  async init() {
    if(this.state.redirectLoginLoading) {
      shareState.welcomePage = LOGIN.PAGE.registration;
      this.state.isLoggingIn = true;
    }
    this.initUserAgreements();
    this.state.isLoading = false;
    // this.state.isLoggingIn = false;
    await this.sendDisplayEvent();
  }

  async sendDisplayEvent() {
    const eventType: KeyboardEvent = this.state.shouldRenderWelcomePage ? LandingPageDisplayKinesisEvent : SignInDisplayKinesisEvent;

    await kinesisAnalyticsUtils.send(new eventType())
  }

  async onSignInClick(authenticatorId) {
    shareState.onLoginProcess = true;
    this.showErrorMessage(false)
    this.state.isLoggingIn = true;
    await this.signIn(authenticatorId)
  }

  async handleRedirect() {
    let firebaseToken: String = null;

    await getRedirectResult(this.firebaseAuth.auth).then((result) => {
      firebaseToken = result.user.accessToken;
      localStorageUtils.setItem(APP_CONST.LOCAL_STORAGE_KEYS.userToken, firebaseToken);
      this.signInToGame(firebaseToken, REQUEST.AUTHENTICATOR_ID.firebase);
    }).catch((error) => {
      console.error(error)
      firebaseToken = null
    });

    localStorageUtils.setItem(APP_CONST.LOCAL_STORAGE_KEYS.signedInWithRedirect, false)
    this.state.redirectLoginLoading = false
  }

  async signIn(authenticatorId: REQUEST.AUTHENTICATOR_ID) {

    if (shareState.isWebView) {
      window.setOAuthToken = (oAuthToken: String) => {
        localStorageUtils.setItem(APP_CONST.LOCAL_STORAGE_KEYS.userToken, oAuthToken);
        this.signInToGame(oAuthToken, authenticatorId);
      }

      await shareState.nativeFunctionsUtils.callFunction("loginClicked", {platform: authenticatorId});
    } else {
      const firebaseToken = await this.firebaseAuth.signIn(authenticatorId);
      await this.signInToGame(firebaseToken, authenticatorId);
    }
  }

  async signInToGame(firebaseToken, authenticatorId: REQUEST.AUTHENTICATOR_ID) {
    if (firebaseToken !== null) {
      this.initializeEnvironment();

      const data = await this.setUserLogin(firebaseToken, authenticatorId);
      if (data) {
        await shareState.nativeFunctionsUtils.callFunction("updateAuthToken", {bearer: data.userToken});
      }
      await this.getUserSettings();
      this.state.isLoggingIn = false;
    } else {
      this.showErrorMessage(true)
    }

    shareState.onLoginProcess = false;
  }

  async setUserLogin(firebaseToken: String, authenticatorId: REQUEST.AUTHENTICATOR_ID): Promise<String> {
    const currentAuthenticatorId = shareState.isWebView ? authenticatorId : REQUEST.AUTHENTICATOR_ID.firebase;

    const requestModel: PostRequestLoginModel = new PostRequestLoginModel(firebaseToken, currentAuthenticatorId, this.state.userAgreementsValue, shareState.deviceId, shareState.appType);

    return this.sendPostRequest(requestModel, (data) => {
      data.userToken && localStorageUtils.setItem(APP_CONST.LOCAL_STORAGE_KEYS.userAuthorizationToken, data.userToken)
      this.sendGoogleAdEvent();

      return data;
    }, () => this._handleFailure());
  }

  async getUserSettings() {
    const requestModel: GetRequestUserSettingsModel = new GetRequestUserSettingsModel();

    await this.sendGetRequest(requestModel, (data) => {
      shareState.user = new UserModel(data.user);

      this.addUserToInvitationGroup();
      this.initializeEnvironment(true);
      this.setWizardDisplay()
    }, () => {
      this._handleFailure();
      this.showErrorMessage(true);
    })
  }

  sendGoogleAdEvent() {
    window.dataLayer?.push({event: 'bolao_login_success_web'})
  }

  _handleFailure() {
    localStorageUtils.removeItem(APP_CONST.LOCAL_STORAGE_KEYS.userAuthorizationToken);
    localStorageUtils.removeItem(APP_CONST.LOCAL_STORAGE_KEYS.userToken);
    localStorageUtils.removeItem(APP_CONST.LOCAL_STORAGE_KEYS.baseUrl);
    localStorageUtils.removeItem(APP_CONST.LOCAL_STORAGE_KEYS.envName);
  }

  setWizardDisplay() {
    let wizardDisplayObject: Object = localStorageUtils.getJSON(APP_CONST.LOCAL_STORAGE_KEYS.wizardDisplay);
    let isWizardDisplayed = false;
    if (wizardDisplayObject) {
      try {
        isWizardDisplayed = wizardDisplayObject[shareState.user.userId];
      } catch (e) {
        isWizardDisplayed = false;
      }
    }

    shareState.wizard.isVisible = !shareState.user.isTournamentStarted && shareState.user.showWizard && !isWizardDisplayed;
  }

  async addUserToInvitationGroup() {
    const groupId: Number = this.getLocalStorageGroupId()

    if (groupId) {
      await this.joinGroup(groupId)
      localStorageUtils.removeItem(APP_CONST.LOCAL_STORAGE_KEYS.groupIdInvitation)
    }
  }

  async joinGroup(groupId) {
    const requestModel: PostRequestJoinGroupModel = new PostRequestJoinGroupModel({groupId});

    await this.sendPostRequest(requestModel);
  }

  changePage(page: Number) {
    shareState.welcomePage = page

    this.sendDisplayEvent();
  }

  setUserAgreements(value) {
    localStorageUtils.setItem(APP_CONST.LOCAL_STORAGE_KEYS.userAgreements, value);

    this.state.userAgreementsValue = value;
    this.state.showErrorUnCheckedBoxMessage = null;
  }

  initUserAgreements() {
    const shouldBeCheckedAsDefault = (shareState.environment.name === APP_CONST.GAME_ENVIRONMENT.israel) && (shareState.theme === 'dominos');
    let userAgreementsValue: Boolean = true;

    if(!shouldBeCheckedAsDefault && (localStorageUtils.getItem(APP_CONST.LOCAL_STORAGE_KEYS.userAgreements) !== undefined)) {
      userAgreementsValue = localStorageUtils.getItem(APP_CONST.LOCAL_STORAGE_KEYS.userAgreements) === 'true';
    } else {
      localStorageUtils.setItem(APP_CONST.LOCAL_STORAGE_KEYS.userAgreements, userAgreementsValue);
    }

    this.state.userAgreementsValue = userAgreementsValue
  }

  showErrorMessage(value) {
    this.state.showErrorMessage = value
  }
  showErrorUnCheckedBoxMessage(value: String) {
    this.state.showErrorUnCheckedBoxMessage = value
  }

  async initializeEnvironment(storeEnvOnLocalStorage) {
    let baseUrl = localStorageUtils.getItem(APP_CONST.LOCAL_STORAGE_KEYS.baseUrl);
    let envName = localStorageUtils.getItem(APP_CONST.LOCAL_STORAGE_KEYS.envName);

    if (!baseUrl || !envName) {
      baseUrl = shareState.nativeGameConfig?.serverUrl;
      envName = shareState.nativeGameConfig?.envName;

      if (!baseUrl || !envName) {
        //Get params from invitation link
        const queryParams = window.location.search.replace('?', '').split('&');
        const baseUrlParam = queryParams.find((query) => query.includes('env_api='));
        const envNameParam = queryParams.find((query) => query.includes('env='));
        baseUrl = baseUrlParam?.split('=')[1];
        envName = envNameParam?.split('=')[1];

        if (!baseUrl || !envName) {
          //Get params from terms
          baseUrl = shareState.settings?.environment?.baseUrl;
          envName = shareState.settings?.environment?.name;
        }
      }
    }

    if (storeEnvOnLocalStorage) {
      baseUrl && localStorageUtils.setItem(APP_CONST.LOCAL_STORAGE_KEYS.baseUrl, baseUrl);
      envName && localStorageUtils.setItem(APP_CONST.LOCAL_STORAGE_KEYS.envName, envName);
    }

    shareState.environment.baseUrl = baseUrl;
    shareState.environment.name = envName;
  }
  getLocalStorageGroupId(): Number {
    const groupId: Number = Number(localStorageUtils.getItem(APP_CONST.LOCAL_STORAGE_KEYS.groupIdInvitation))

    return groupId
  }

  onUnload() {
    super.onUnload();

    localStorageUtils.removeItem(APP_CONST.LOCAL_STORAGE_KEYS.groupIdInvitation)
  }
}
