import {KinesisEvent} from "../kinesis.event";
import {KINESIS} from "../../kinesis.consts";

export class WizardTopScorerSelectionSkipKinesisEvent extends KinesisEvent {
  constructor() {
    super(
      KINESIS.CATEGORY.bolao,
      KINESIS.ACTION.wizard,
      KINESIS.LABEL.topScorerSelection,
      KINESIS.VALUE.skip,
    );
  }
}
