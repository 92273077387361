export class BaseAnalyticsUtils {
  constructor(provider) {
    this.provider = provider;
  }

  initialize() {

  }

  send() {

  }
}
