import {WidgetState} from "../widget.state";
import {GroupModel} from "../../models/group";
import {GameModel} from "../../models/game/game";
import {computed} from "mobx";
import {GameWidgetInputModel} from "../games-widget/models/games.widget.game-input-model";
import {observable, makeObservable} from 'mobx';
import {shareState} from "../../app/share-state";
import {HomeWidgetGameModel} from "./models/home-widget.game";
import {HOME_WIDGET} from "./home.widget.consts";
import {AddonModel} from "../../models/addon";

export class HomeWidgetState extends WidgetState {
  @observable isPopupVisible: Boolean;
  @observable popupPageNumber: Number;
  @observable newGroup: GroupModel;
  @observable groupImage: String;
  @observable groupName: String;
  @observable isCreatingGroup: Boolean = false;
  @observable addonDataSource;
  @observable hasAddons: Boolean;
  @observable hasSelections: Boolean;
  selections: Map<Number, Array<Selection>>;

  constructor() {
    super()

    this.selections = new Map();
    makeObservable(this)
  }

  @computed get games(): Array<GameWidgetInputModel> {
    const games: Array<GameModel> = this.dataSource?.nextGames?.map((game: GameModel) => {
      const addon: AddonModel = this.addonDataSource?.find(addon => addon.SportifierGameId === game.sportifierGameID);
      const selections: Array<Selection> = this.selections.get(game.sportifierGameID);
      let selection: Selection = null;
      if (selections?.length > 0) {
        selection = selections[0];
      }
      const addonVM: AddonModel = addon ? new AddonModel(addon) : null;

      return new HomeWidgetGameModel(game, addonVM, selection);
    });

    return games;
  }

  @computed get groups(): Array<GroupModel> {
    const groups: Array<GroupModel> = this.dataSource?.groups?.map((group: GroupModel) => new GroupModel(group))
      .sort((a, b) => ((b.id === shareState.user.masterGroupId) - (a.id === shareState.user.masterGroupId)) || (a.id - b.id));

    return groups;
  }

  get hasNextGames(): Boolean {
    return this.games?.length > 0;
  }

  get nextGameStartTime(): Date {
    const nextGameStartTime: Date = this.hasNextGames && this.games[0].startTime;

    return nextGameStartTime;
  }

  @computed get numOfGroups(): Number {
    return this.groups?.length
  }

  @computed get shouldRenderCreateGroupPage(): Boolean {
    return this.popupPageNumber === HOME_WIDGET.POPUP_PAGES.create
  }

  @computed get shouldRenderInvitePage(): Boolean {
    return this.popupPageNumber === HOME_WIDGET.POPUP_PAGES.invite
  }

  @computed get shouldRenderFailedPage(): Boolean {
    return this.popupPageNumber === HOME_WIDGET.POPUP_PAGES.invite && !this.isCreatingGroup && !this.newGroup
  }

  @computed get createLabel(): String {
    return shareState.termsUtils.terms.WC_2022_CREATE_NEW_GROUP
  }

  @computed get countdownLabel(): String {
    return shareState.termsUtils.terms.WC_2022_PICK_CLOSE_IN
  }

  @computed get shouldRenderButtonLink(): Boolean {
    const addonGames: Array<GameModel> = this.games?.filter((game: GameModel) => game.addon);
    const shouldRenderButtonLink: Boolean = addonGames?.length === 0;

    return shouldRenderButtonLink;
  }
}
