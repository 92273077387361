import {ImageRequestParams} from "../../models/image/image-request-params";
import {makeObservable, observable} from 'mobx';

export class LoginWidgetStepModel {
  key: String;
  @observable title: String;
  @observable text: String;
  icon: ImageRequestParams;

  constructor(model) {
    makeObservable(this)

    this.key = model.key;
    this.title = model.title;
    this.text = model.text;
    this.icon = model.icon;
  }
}
