import {FetchUtils} from "../infrastructure/utils/fetch-utils";
import {WidgetState} from "../widgets/widget.state";
import {DeltaRequestModel} from "../models/request/delta-request";
import {visibleWidgetsManager} from "../infrastructure/managers/visible-widgets-manager";

export class WidgetController {
  fetchManager: FetchUtils;
  state: WidgetState;

  constructor(props, stateType: WidgetState, fetchManager: FetchUtils) {
    this.props = props;
    this.state = new stateType();
    this.fetchManager = fetchManager;
  }

  async init() {
    this.state.isLoading = true;
    visibleWidgetsManager.set(this);
  }

  updateData() {
  }

  async sendGetRequest(requestModel, onPopulate, onFailure) {
    const activeDeltaRequests: Array<DeltaRequestModel> = this.state.activeRequests?.filter((deltaRequest: DeltaRequestModel) => deltaRequest.constructor.name === requestModel.constructor.name);

    if (activeDeltaRequests?.length > 1) {
      console.warn('has more than 1 active delta requests for:', requestModel.constructor.name);
    }

    if (activeDeltaRequests?.length > 0) {
      activeDeltaRequests.forEach((deltaRequest: DeltaRequestModel) => this.fetchManager.stopDelta(deltaRequest))
      this.state.activeRequests = this.state.activeRequests.filter(request => request.startDelta)
    }

    requestModel.startDelta && this.state.activeRequests.push(requestModel);

    return this.fetchManager.sendGetRequest(requestModel,
      (data) => onPopulate?.(data),
      (error) => onFailure?.(error))
  }

  async sendPostRequest(requestModel, onPopulate, onFailure) {
    return this.fetchManager.sendPostRequest(requestModel,
      (data) => onPopulate?.(data),
      (error) => onFailure?.(error))
  }

  onDidUpdate(prev, next) {
    this.props = next
  }

  onUnload() {
    if (this.state.activeRequests?.length > 0) {
      this.state.activeRequests = this.state.activeRequests.filter((deltaRequest: DeltaRequestModel) => this.fetchManager.stopDelta(deltaRequest));
    }
    visibleWidgetsManager.remove(this)
  }
}
