import {KinesisEvent} from "../kinesis.event";
import {KINESIS} from "../../kinesis.consts";

export class FilterChoiceClickKinesisEvent extends KinesisEvent {
  constructor(properties) {
    super(
      KINESIS.CATEGORY.bolao,
      KINESIS.ACTION.filter,
      KINESIS.LABEL.choice,
      KINESIS.VALUE.click,
      properties
    );
  }

  getProperties(params) {
    const props = super.getProperties()

    return {
      ...props,
      entity_id: params.entityId,
      entity_type: params.entityType,
      source: params.source,
    }
  }
}
