import classNames from "classnames";
import styles from "./selection-list-header.module.scss";
import {observer} from "mobx-react";
import {SelectionListWidgetInput} from "../../widgets/selection-list.widget/components/selection-list.widget.input";
import {Button} from "../button/button";
import {GamesWidgetAutoFillButton} from "../../widgets/games-widget/components/games.widget.auto-fill.button";
import {shareState} from "../../app/share-state";
import classnames from "classnames";

export const SelectionListHeader = observer(({controller, buttonProps, className}) => {
  const {
    state: {
      isWizardVisible,
      shouldRenderWizardButton,
      shouldRenderAutoFillButton,
      shouldRenderInput
    }
  } = controller;

  const wizardButtonProps = {
    ...buttonProps,
    className: classnames({[styles.bottom_button]: shareState.isMobileView}, styles.button)
  };

  const autofillButtonProps = {
    controller,
    className: classNames({[styles.auto_fill]: !isWizardVisible})
  };

  return (
    <div className={classNames({[styles.mobile_view]: shareState.isMobileView}, className, styles.header, {[styles.wide_header]: isWizardVisible})}>
      {
        shouldRenderInput ? <SelectionListWidgetInput controller={controller}/> : null
      }
      {
        !shareState.isMobileView || isWizardVisible || shouldRenderAutoFillButton ? <GamesWidgetAutoFillButton {...autofillButtonProps}/> : null
      }
      {
        shouldRenderWizardButton ? <Button {...wizardButtonProps}/> : null
      }
    </div>
  );
});


