import {LoginWidgetButton} from "./login.widget.button";
import {observer} from "mobx-react";
import styles from "../styles/login.module.scss";
import {GoogleImageRequestParams} from "../../../icons/social/google";
import {FacebookImageRequestParams} from "../../../icons/social/facebook";
// import {AppleImageRequestParams} from "../../../icons/social/apple";
import {REQUEST} from "../../../models/request/requests.consts";
import {shareState} from "../../../app/share-state";
import {kinesisAnalyticsUtils} from "../../../analytics/kinesis/kinesis.analytics-utils";
import {SignInNetworkClickKinesisEvent} from "../../../analytics/kinesis/events/login/sign-in.network.click.kinesis-event";

export const LoginWidgetButtons = observer(({controller}) => {
  const {state: {userAgreementsValue}} = controller;

  const handleButtonClick = (authenticatorId) => {
    userAgreementsValue ? onButtonClick(authenticatorId) : controller.showErrorUnCheckedBoxMessage(shareState.termsUtils.terms.CHECKBOX_CONFIRMED)
  }

  const googleButtonProps = {
    label: shareState.termsUtils.terms.WC_2022_CONNECT_WITH_GOOGLE,
    source: new GoogleImageRequestParams(24, 24),
    onButtonClick: () => handleButtonClick(REQUEST.AUTHENTICATOR_ID.google)
  }

  const facebookButtonProps = {
    label: shareState.termsUtils.terms.WC_2022_CONNECT_WITH_FB,
    className: styles.facebook,
    source: new FacebookImageRequestParams(24, 24),
    onButtonClick: () => handleButtonClick(REQUEST.AUTHENTICATOR_ID.facebook)}

  // const appleButtonProps = {
  //   label: shareState.termsUtils.terms.WC_2022_CONNECT_WITH_APPLE,
  //   source: new AppleImageRequestParams(24, 24),
  //   onButtonClick: () => onButtonClick(REQUEST.AUTHENTICATOR_ID.apple)
  // }

  const onButtonClick = (authenticatorId) => {
    kinesisAnalyticsUtils.send(new SignInNetworkClickKinesisEvent({authenticatorId}))
    controller.onSignInClick(authenticatorId)
  }

  const isFacebookLoginAvailableForWebview = !shareState.isWebView || shareState.nativeGameConfig?.isVersionSupported(null, 1340)

  return (
    <>
      <LoginWidgetButton {...googleButtonProps}/>
      {
        isFacebookLoginAvailableForWebview ? <LoginWidgetButton {...facebookButtonProps}/> : null
      }
      {/*{shareState.appType === 1 ? <LoginWidgetButton {...appleButtonProps}/> : null}*/}
    </>
  )
});
