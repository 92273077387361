import classNames from "classnames";
import styles from "../selection-list-widget.module.scss";
import {SelectionListWidgetItemInfo} from "./selection-list.widget.item-info";
import {SelectionListWidgetItemCheckbox} from "./selection-list.widget.item-checkbox";
import {shareState} from "../../../app/share-state";
import {LazyElementWrapper} from "../../../components/lazy-element-wrapper/lazy-element-wrapper";
import {SelectionListWidgetItemScore} from "./selection-list.widget.item-score";
import {observer} from 'mobx-react';

export const SelectionListWidgetItem = observer(({item, controller, index}) => {
  const {state} = controller
  const {selectedItemId, entityModel} = state
  const {id, isClickable} = item;

  const isActive: Boolean = selectedItemId === id

  const itemProps = {
    className: classNames(styles.item, {[styles.is_clickable]: isClickable || shareState.user.allowTournamentBets}),
    onClick: () => {
      shareState.user.allowTournamentBets && controller.sendSelectionEvent(item.id);

      return shareState.user.allowTournamentBets ? controller.onChangeItem(item) : controller.open365EntityPage(item);
    }
  }

  const itemInfoProps = {
    index,
    item,
    entityModel,
  }

  const scoreProps = {
    entityModel,
    isActive,
    item
  }

  const checkboxProps = {
    entityModel,
    isActive
  }

  return (
    <LazyElementWrapper {...itemProps}>
      <SelectionListWidgetItemInfo {...itemInfoProps}/>
      {
        shareState.user.allowTournamentBets ? <SelectionListWidgetItemCheckbox {...checkboxProps}/> : <SelectionListWidgetItemScore {...scoreProps}/>
      }
    </LazyElementWrapper>
  );
});


