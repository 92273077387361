import styles from "../../styles/app.module.scss";
import {observer} from "mobx-react";
import {useHistory} from 'react-router-dom';
import {shareState} from "../../share-state";
import {SettingsPopup} from "../../../components/settings-button/settings.popup";
import {Button} from "../../../components/button/button";
import classnames from "classnames";
import {WIZARD} from "../../../widgets/wizard-widget/wizard.widget.consts";

export const AppHeaderSideButton = observer(({controller, isLoginPage}) => {
  const {state} = controller;
  const {shouldRenderWizardButton, currentPageModel} = state;
  const {nextPage} = currentPageModel;
  const history = useHistory();
  const shouldRenderSettingsButton: Boolean = shareState.isMobileView;
  const isFilled = currentPageModel?.hasSelection || (currentPageModel?.id === WIZARD.PAGES.games)

  const buttonProps = {
    className: classnames({
      [styles.bottom_button]: shareState.isMobileView,
      [styles.filled]: isFilled
    }, styles.wizard_button),
    label: currentPageModel?.textButton,
    isFilled,
    onClick: () => {
      if (nextPage !== null) {
        controller.changeWizardPage(nextPage);
      } else {
        controller.closeWizardDisplay()
        history.push(`/${shareState.currentDisplayName ? `/${shareState.currentDisplayName}` : ''}`)
      }
    }
  }

  return shouldRenderWizardButton ? <Button {...buttonProps}/> :
    (!isLoginPage ?
      <div className={styles.button_container}>
        <SettingsPopup shouldRenderSettingsButton={shouldRenderSettingsButton}/></div> : null);
});

