import {WidgetController} from "../../controllers/widget.controller";
import {SelectionListWidgetState} from "./selection-list.widget.state";
import {shareState} from "../../app/share-state";
import {UserModel} from "../../models/user";
import {GetRequestSearchPlayersModel} from "../../models/request/category/tournament/get/get-request.search-players.model";
import {GetRequestUserSettingsModel} from "../../models/request/category/users/get/get-request.user-settings.model";
import {RequestModel} from "../../models/request/request";
import {CompetitorModel} from "../../models/competitor";
import {KinesisEvent} from "../../analytics/kinesis/events/kinesis.event";
import {kinesisAnalyticsUtils} from "../../analytics/kinesis/kinesis.analytics-utils";

export class SelectionListWidgetController extends WidgetController {
  state: SelectionListWidgetState;

  async init() {
    super.init()

    this.state.context = this.props.context;

    await this.getAllItems()
    this.state.selectedItemId = this.state.entityModel.selectedItemId
    !shareState.wizard.isVisible && await kinesisAnalyticsUtils.send(this.state.entityModel.displayEventModel)
    this.state.isLoading = false;
  }

  async getAllItems() {
    await this.sendGetRequest(this.state.entityModel.fetchAllItems, (data) => {
      this.state.dataSource = data
      this.state.teams = data.teams
    })
  }

  async searchItemsByQuery(query: String) {
    this.state.isTopScorer ? await this.searchPlayers(query) : await this.searchTeams(query)
  }

  async searchPlayers(query: String) {
    const params = {search: query}
    const searchPlayers: GetRequestSearchPlayersModel = new GetRequestSearchPlayersModel(params);

    await this.sendGetRequest(searchPlayers, (data) => this.state.dataSource = data)
  }

  async searchTeams(query: String) {
    this.state.teams = this.state.dataSource.teams?.filter((competitor: CompetitorModel) => competitor.name.toLowerCase().includes(query.toLowerCase()))
  }

  async onChangeItem(item) {
    this.state.selectedItemId = item.id;
    const requestModel: RequestModel = new this.state.entityModel.fetchSelectedItemType(item.id);

    await this.sendPostRequest(requestModel, () => this.sendUserRequest())
  }

  async sendUserRequest() {
    const requestModel: GetRequestUserSettingsModel = new GetRequestUserSettingsModel();

    await this.sendGetRequest(requestModel, (data) => shareState.user = new UserModel(data.user))
  }

  async sendSelectionEvent(id) {
    const eventType: KinesisEvent = this.state.entityModel.selectionEventModel

    await kinesisAnalyticsUtils.send(new eventType({id: id}));
  }

  open365EntityPage(item) {
    shareState.isWebView ? shareState.nativeFunctionsUtils.callFunction(item.nativeFunctionName, item.nativeLinkParams) : window.open(item.link, '_blank');
  }
}
