import {makeObservable, computed} from 'mobx';
import {BaseModel} from "../../../models/base";
import {Component} from "react";

export class WizardWidgetPageModel extends BaseModel {
  constructor(id: Number) {
    super();

    makeObservable(this)

    this.id = id;
  }

  @computed get WidgetComponent(): Component {
    return console.error(this.constructor.name + 'Implementation is missing: WidgetComponent');
  }

  @computed get title(): String {
    return console.error(this.constructor.name + 'Implementation is missing: title');
  }

  @computed get nextPage(): Number {
    return console.error(this.constructor.name + 'Implementation is missing: nextPage');
  }

  @computed get textButton(): String {
    return console.error(this.constructor.name + 'Implementation is missing: textButton');
  }

  @computed get hasSelection(): String {
    return console.error(this.constructor.name + 'Implementation is missing: hasSelection');
  }

  @computed get context(): String {
    return console.error(this.constructor.name + 'Implementation is missing: context');
  }

  @computed get shouldRenderArrow(): Boolean {
    return console.error(this.constructor.name + 'Implementation is missing: context');
  }
}
