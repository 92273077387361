export class Environment {
  #baseUrl: String;
  name: String;
  competitionId: Number;

  constructor(model) {
    this.name = model?.Name;
    this.#baseUrl = model?.BaseURL;
    this.competitionId = model?.CompetitionId;
  }

  set baseUrl(url) {
    this.#baseUrl = decodeURIComponent(url);
  }

  get baseUrl(): String {
    return this.#baseUrl;
  }
}
