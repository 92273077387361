import classNames from "classnames";
import styles from '../acca-widget.module.scss';
import {observer} from "mobx-react";
import {AccaWidgetBetSlipBet} from "./acca-widget.bet-slip.bet";
import {AccaWidgetBetSlipSummary} from "./acca-widget.bet-slip.summary";
import {StringToJsx} from "../../../components/string-to-jsx";
import {Button} from "../../../components/button/button";
import type {Selection} from "../../../models/bets/selection";
import {useGuid} from "../../../infrastructure/hooks/useGuid";
import {useEffect} from "react";
import {kinesisAnalyticsUtils} from "../../../analytics/kinesis/kinesis.analytics-utils";
import {
  AccaAccaFormDisplayKinesisEvent
} from "../../../analytics/kinesis/events/acca/acca-form/acca.acca-form.display.kinesis-event";
import {shareState} from "../../../app/share-state";
import {
  AccaAccaFormBetNowClickKinesisEvent
} from "../../../analytics/kinesis/events/acca/acca-form/acca.acca-form.bet-now-click.kinesis-event";
import {KINESIS} from "../../../analytics/kinesis/kinesis.consts";
import {Image} from "../../../components/image/image";
import {BookmakerImageRequestParams} from "../../../icons/bookie";

export const AccaWidgetBetSlipBets = observer(({controller}) => {
  const {state: {ctaText, selections, topBookmaker, accumulatedRate}} = controller;
  const openBookieURL = useGuid();

  useEffect(() => {
    kinesisAnalyticsUtils.send(new AccaAccaFormDisplayKinesisEvent(({
      app_device_id: shareState.nativeGameConfig?.deviceId,
      web_visitor_id: shareState.websiteVisitorId,
      bookie_id: shareState.settings?.topBookmakerId,
      final_odds: accumulatedRate?.toFixed(2)
    })))
  }, []);

  const betNowProps = {
    isFilled: true,
    onClick: () => {
      const url = topBookmaker?.actionButton?.URL || topBookmaker?.url;
      const {guid, bookieLink} = openBookieURL(url);

      kinesisAnalyticsUtils.send(new AccaAccaFormBetNowClickKinesisEvent(({
        app_device_id: shareState.nativeGameConfig?.deviceId,
        web_visitor_id: shareState.websiteVisitorId,
        bookie_id: shareState.settings?.topBookmakerId,
        guid,
        url: bookieLink,
        final_odds: accumulatedRate?.toFixed(2),
        click_type: KINESIS.CLICK_TYPE.button,
      })))
    },
    className: classNames(styles.bet_now_container),
    style: {
      backgroundColor: topBookmaker?.color,
      borderColor: topBookmaker?.color,
      color: topBookmaker?.textColor
    }
  }

  return (
    selections?.length > 0 ? <div>
      {
        selections.map((selection: Selection) => <AccaWidgetBetSlipBet item={selection} key={selection.id}/>)
      }
      <AccaWidgetBetSlipSummary controller={controller}/>
      <div className={classNames(styles.cta_text)}>
        <StringToJsx domString={ctaText}/>
      </div>
      <Button {...betNowProps}>
        <span className={classNames(styles.bet_now_label)}>{shareState.termsUtils.terms.BOLAO_ACCA_SUMMARY_CTA_BUTTON}</span>
        <Image src={new BookmakerImageRequestParams(topBookmaker.id, true)} className={classNames(styles.bet_now_logo)}/>
      </Button>
    </div> : <div className={classNames(styles.empty_state)}>{shareState.termsUtils.terms.BOLAO_ACC_NO_ODDS}</div>
  )
});
