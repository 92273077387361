import {SettingsButton} from "./settings-button";
import {Popup} from "../popup/popup";
import styles from "./settings-popup.module.scss";
import {useState} from "react";
import {SettingsWidget} from "../../widgets/settings-widget/components/settings.widget";
import {shareState} from "../../app/share-state";
import classNames from "classnames";

export const SettingsPopup = ({shouldRenderSettingsButton}) => {
  const [open, setOpen] = useState(false);

  const settingsProps = {
    shouldRenderSettingsButton,
    className: styles.settings_popup_button,
    openSettingsPopup: () => setOpen(true)
  }

  const popupProps = {
    className: styles.settings_popup,
    onClose: () => setOpen(false)
  }

  return (
    <div className={ classNames(styles.container, {[styles.mobile_view]: shareState.isMobileView})}>
      <SettingsButton {...settingsProps}/>
      {
        open ? <Popup{...popupProps}>
          <SettingsWidget onClose={() => setOpen(false)}/>
        </Popup> : null
      }
    </div>
  )
};

