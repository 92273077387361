import {BaseModel} from "./base";
import {Environment} from "./environment";

export class GameSettings extends BaseModel {
  theme: String;
  countryId: Number;
  languageId: Number;
  defaultLanguageId: Number;
  environment: Environment;
  version: String;
  sportNameForURL: String;
  competitionNameForURL: String;
  isGameAvailable: Environment;
  featuresMap: Map<Object>;
  topBookmakerId: Number;
  currencySign: String;
  betValue: Number;

  constructor(model) {
    super(model);

    this.theme = model.Theme;
    this.languageId = model.LanguageId;
    this.countryId = model.CountryId;
    this.defaultLanguageId = model.DefaultLanguageId;
    this.isGameAvailable = model.IsGameAvailable;
    this.version = model.Version;
    this.sportNameForURL = model.SportNameForURL;
    this.competitionNameForURL = model.CompetitionNameForURL;
    this.environment = new Environment(model.Environment);
    this.featuresMap = this.createFeaturesMap(model.Features)
    this.topBookmakerId = model.TopBM;
    this.currencySign = model.CurrencySign;
    this.betValue = model.BetValue;
  }

  createFeaturesMap(features: Array<Object> = []) {
    const map = new Map();

    features.forEach((feature) => {
      map.set(feature.Name, feature.Value);
    })

    return map;
  }
}
