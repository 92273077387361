import {useEffect, useState} from "react";
import {LoadingIndicator} from "../loading-indicator/loading-indicator";
import classnames from "classnames";
import styles from "./loader.module.scss";

export const Loader = ({showLoader, children, className, size}) => {
  const [isLoading, setIsLoading] = useState(showLoader)

  useEffect(() => {
    showLoader && setIsLoading(true)

    return () => setIsLoading(false)
  }, [showLoader])

  const loadingIndicatorProps = {
    className,
    size
  }

  return (
    isLoading ? <div className={classnames(styles.container, className)}><LoadingIndicator {...loadingIndicatorProps}/></div> : children
  );
}
