import {BaseAnalyticsUtils} from "../base-analytics-utils";
import {KinesisEvent} from "./events/kinesis.event";
import {shareState} from "../../app/share-state";

const INTERVAL_BATCH_SEND = 5000;
const INITIALIZED_TIMEOUT = 2000;

export class KinesisAnalyticsUtils extends BaseAnalyticsUtils {
  provider: AWS.Kinesis;
  events: Array<KinesisEvent> = [];
  intervalId: Number;
  eventSequence: Number;

  constructor() {
    super();

    this.events = [];
    this.intervalId = null;
    this.eventSequence = 0;
    setTimeout(() => this.initialize(), INITIALIZED_TIMEOUT);
  }

  get partitionKey() {
    return shareState.deviceId;
  }

  initialize() {
    const IDENTITY_POOL_ID = 'us-east-1:01115446-95f7-460b-9d7d-88ff0f3ead7a';
    const REGION_NAME = 'us-east-1';

    AWS.config.region = REGION_NAME; // Region
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: IDENTITY_POOL_ID,
    });

    this.provider = new AWS.Kinesis();
  }

  createInterval() {
    this.intervalId = setInterval(() => {
      this.#_sendRecords(this.events)
        .then(() => this.clearEvents())
        .catch(() => console.log("events didn't success to be send"))
    }, INTERVAL_BATCH_SEND);
  }

  async send(event: KinesisEvent) {
    event.event_seq = ++this.eventSequence;
    this.events.push({
      PartitionKey: this.partitionKey, Data: JSON.stringify(event)
    })

    if (this.intervalId === null) {
      this.createInterval();
    }
  }

  async #_sendRecords(Records: Array, StreamName = 'web_stream_events') {
    const params = {
      Records, StreamName, /* required */
    };

    await this.provider?.putRecords(params, (err, data) => {
      if (err) {
        throw err;
      }
      return data;
    });
  }

  clearEvents(): void {
    this.events = [];
    clearInterval(this.intervalId);
    this.intervalId = null;
  }
}

export const kinesisAnalyticsUtils = new KinesisAnalyticsUtils();

window.kinesisAnalyticsUtils = kinesisAnalyticsUtils;

