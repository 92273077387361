export class TournamentInfo {
  sportTypeId: Number;
  competitionId: Number;
  sportTypeUrlName: String;
  competitionUrlName: String;

  constructor(model) {
    this.sportTypeId = model.sportTypeId;
    this.competitionId = model.competitionId;
    this.sportTypeUrlName = model.sportTypeUrlName;
    this.competitionUrlName = model.competitionUrlName;
  }
}