import {SelectionListWidgetEntityModel} from "./selection-list.widget.entity.model.js";
import {shareState} from "../../../app/share-state";
import {PostRequestSetTopPlayerModel} from "../../../models/request/category/bets/post/post-request.set-top-player.model";
import {TopScorerSelectedImageRequestParams} from "../../../icons/top-scorer/top-scorer-selected";
import {TopScorerDeselectedImageRequestParams} from "../../../icons/top-scorer/top-scorer-deselected";
import styles from "../selection-list-widget.module.scss";
import {AthleteImageRequestParams} from "../../../icons/athlete/athlete-image-request-params";
import {GetRequestTopScorersModel} from "../../../models/request/category/tournament/get/get-request.top-scorers.model";
import {GetRequestSearchPlayersModel} from "../../../models/request/category/tournament/get/get-request.search-players.model";
import {RequestModel} from "../../../models/request/request";
import {KinesisEvent} from "../../../analytics/kinesis/events/kinesis.event";
import {TopScorerDisplayKinesisEvent} from "../../../analytics/kinesis/events/selection-list/top-scorer.display.kinesis-event";
import {WizardTopScorerSelectionClickKinesisEvent} from "../../../analytics/kinesis/events/wizard/wizard.top-scorer-selection.click.kinesis-event";
import {TopScorerChangeKinesisEvent} from "../../../analytics/kinesis/events/selection-list/top-scorer.change.kinesis-event";

export class SelectionListWidgetPlayerModel extends SelectionListWidgetEntityModel {
  get fetchAllItems(): String {
    let fetchAllItems: RequestModel;
    if (!shareState.user.allowTournamentBets) {
      fetchAllItems = new GetRequestTopScorersModel();
    } else {
      const params = {search: ''}
      fetchAllItems = new GetRequestSearchPlayersModel(params);
    }

    return fetchAllItems;
  }

  get fetchSelectedItemType(): String {
    return PostRequestSetTopPlayerModel;
  }

  get searchPlaceholder(): String {
    const searchPlaceholder: String = shareState.termsUtils.terms.WC_2022_PLAYER_NAME;

    return searchPlaceholder;
  }

  get title(): String {
    const title: String = shareState.user.allowTournamentBets ? shareState.termsUtils.terms.WC_2022_SELECT_TOP_SCORER :  shareState.termsUtils.terms.WC_2022_TOURNAMENT_TOP_SCORER;

    return title;
  }

  get subtitle(): String {
    const subtitle: String = shareState.user.allowTournamentBets ? shareState.termsUtils.terms.WC_2022_SELECT_TOP_SCORER_SECOND_LINE : '';

    return subtitle;
  }

  get entityLogoSource(): String {
    return AthleteImageRequestParams;
  }

  get logoClassName(): String {
    return styles.top_scorer_logo
  }

  get selectedLogo(): TopScorerSelectedImageRequestParams {
    return new TopScorerSelectedImageRequestParams(32, 16);
  }

  get deselectedLogo(): TopScorerDeselectedImageRequestParams {
    return new TopScorerDeselectedImageRequestParams(32, 16);
  }

  get hasSearch(): Boolean {
    return shareState.user.allowTournamentBets || shareState.wizard.isVisible;
  }

  get selectedItemId(): Number {
    return shareState.user.topScorerId
  }

  get shouldRenderSubtext(): Boolean {
    return true;
  }

  get displayEventModel(): TopScorerDisplayKinesisEvent {
    return new TopScorerDisplayKinesisEvent({id: this.selectedItemId});
  }

  get selectionEventModel(): KinesisEvent {
    return shareState.wizard.isVisible ? WizardTopScorerSelectionClickKinesisEvent : TopScorerChangeKinesisEvent;
  }
}
