import {AppHeader} from "./header/app.header";
import {AppContent} from "./app.content";
import {observer} from 'mobx-react';
import {shareState} from "../share-state";
import {AppNavigationBar} from "./app.navigation-bar";
import {AppRoutes} from "./app.routes";
import {BrowserRouter} from "react-router-dom";

import {useEffect} from 'react';
import {withRouter, useHistory} from 'react-router-dom';

export const AppMain = observer(({controller}) => {
  return (
    <BrowserRouter>
      <>
        <ScrollToTop/>
        <AppHeader controller={controller}/>
        {
          shareState.isMobileView ? <>
            <AppRoutes controller={controller}/>
            <AppNavigationBar controller={controller}/>
          </> : <AppContent controller={controller}/>
        }
      </>
    </BrowserRouter>
  );
});

function ScrollToTop() {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (history.action !== 'POP') {
        window.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    }
  }, []);

  return (null);
}

export default withRouter(ScrollToTop);
