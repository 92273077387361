import {CollapsedItemModel} from "../../../models/collapsed-item";
import {GameModel} from "../../../models/game/game";
import {shareState} from "../../../app/share-state";

export class GamesWidgetGroupModel extends CollapsedItemModel {
  id: Number;
  name: String;
  games: Array<GameModel>;
  key: Number;
  title: String;
  isNextGroupGames: Boolean;

  constructor(model) {
    super(model.open, model.title);

    this.id = model.id;
    this.name = model.name;
    this.games = model.games;
    this.key = model.key;
  }

  get shouldRenderAcca(): Boolean {
    return shareState.isACCAActive && this.isNextGroupGames;
  }
}
