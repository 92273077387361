import {REQUEST} from "../../models/request/requests.consts";
import {APP_CONST, COMPETITION_PAGES} from "../../consts";

export const KINESIS = {
  table: '365.public.fact_events',
  webType: 'site',

  CATEGORY: {
    bolao: 'bolao',
    web: 'web'
  },

  CLICK_TYPE: {
    odds: 'odds',
    button: 'button'
  },

  ACTION: {
    visit: 'visit',
    exit: 'exit',
    entrance: 'entrance',
    landingPage: 'landing-page',
    signIn: 'sign-in',
    wizard: 'wizard',
    winningTeam: 'winning-team',
    topScorer: 'top-scorer',
    settings: 'settings',
    menu: 'menu',
    profile: 'profile',
    game: 'game',
    prediction: 'prediction',
    leaderboard: 'leaderboard',
    rules: 'rules',
    filter: 'filter',
    autoFill: 'autoFill',
    acca: 'acca',
  },

  LABEL: {
    display: 'display',
    click: 'click',
    playNow: 'play-now',
    network: 'network',
    teamSelection: 'team-selection',
    topScorerSelection: 'top-scorer-selection',
    changeTeam: 'change-team',
    changeScorer: 'change-scorer',
    logout: 'logout',
    createNewGroup: 'create-new-group',
    createGroup: 'create-group',
    groupInvite: 'group-invite',
    groupCopy: 'group-copy',
    group: 'group',
    game: 'game',
    invite: 'invite',
    groupMember: 'group-member',
    begin: 'begin',
    signIn: 'sign-in',
    image: 'image',
    name: 'name',
    leaveGroup: 'leave-group',
    editGroup: 'edit-group',
    removeUser: 'remove-user',
    done: 'done',
    choice: 'choice',
    prediction: 'prediction',
    nextGame: 'next_game',
    banner: 'banner',
    topNavigation: 'top-navigation',
    accaForm: 'acca-form',
    predictionForm: 'prediction-form',
  },

  VALUE: {
    click: 'click',
    display: 'display',
    filter: 'filter',
    skip: 'skip',
    prediction: 'prediction',
    change: 'change',
    linkClick: 'link_click',
    linkDisplay: 'link_display',
    betNowClick: 'bet-now-click',
  },

  DEVICES: {
    console: 'console',
    mobile: 'mobile',
    tablet: 'tablet',
    other: 'other'
  },

  SOURCES: {
    leaderboard: 'leaderboard',
    settings: 'settings',
    prediction: 'prediction',
    wizard: 'wizard'
  }
}

export const DEVICE_TYPES = {
  [KINESIS.DEVICES.console]: 'desktop',
  [KINESIS.DEVICES.mobile]: 'handset',
  [KINESIS.DEVICES.tablet]: 'tablet',
};

export const NETWORK_ID = {
  [REQUEST.AUTHENTICATOR_ID.google]: 1,
  [REQUEST.AUTHENTICATOR_ID.facebook]: 2,
  [REQUEST.AUTHENTICATOR_ID.apple]: 3,
};

export const ROUTE_INDEX = {
  [APP_CONST.ROUTE.home]: 1,
  [APP_CONST.ROUTE.prediction]: 2,
  [APP_CONST.ROUTE.leaderboard]: 3,
  [APP_CONST.ROUTE.rules]: 4,
  [COMPETITION_PAGES.standings]: 5,
  [COMPETITION_PAGES.brackets]: 6,
  [COMPETITION_PAGES.stats]: 7
};

export const GAME_STATUS = {
  [APP_CONST.GAME_STATUS.schedule]: 0,
  [APP_CONST.GAME_STATUS.ended]: 1,
  [APP_CONST.GAME_STATUS.live]: 2,
};