import {makeObservable, observable} from 'mobx';
import {BaseModel} from "../../../models/base";
import {RequestModel} from "../../../models/request/request";
import {ImageRequestParams} from "../../../models/image/image-request-params";
import {KinesisEvent} from "../../../analytics/kinesis/events/kinesis.event";

export class SelectionListWidgetEntityModel extends BaseModel {
  context: String;
  @observable items: Array<BaseModel>;

  constructor(
    context: String,
    items: Array<BaseModel>,
  ) {
    super();

    makeObservable(this);

    this.context = context;
    this.items = items;
  }

  get fetchAllItems(): String {
    return console.error(this.constructor.name + 'Implementation is missing: fetchAllItems');
  }

  get fetchSelectedItemType(): RequestModel {
    return console.error(this.constructor.name + 'Implementation is missing: fetchSelectedItemType');
  }

  get entityPage(): String {
    return console.error(this.constructor.name + 'Implementation is missing: entityPage');
  }

 get searchPlaceholder(): String {
    return console.error(this.constructor.name + 'Implementation is missing: searchPlaceholder');
  }

  get logoClassName(): String {
    return console.error(this.constructor.name + 'Implementation is missing: logoClassName');
  }

  get selectedLogo(): ImageRequestParams {
    return console.error(this.constructor.name + 'Implementation is missing: selectedLogo');
  }

  get deselectedLogo(): ImageRequestParams {
    return console.error(this.constructor.name + 'Implementation is missing: deselectedLogo');
  }

  get entityLogoSource(): String {
    return console.error(this.constructor.name + 'Implementation is missing: entityLogoSource');
  }

  get selectedItemId(): Number {
    return console.error(this.constructor.name + 'Implementation is missing: hasSearch');
  }

  get shouldRenderSubtext(): Boolean {
    return console.error(this.constructor.name + 'Implementation is missing: shouldRenderSubtext');
  }

  get displayEventModel(): KinesisEvent {
    return console.error(this.constructor.name + 'Implementation is missing: displayEventModel');
  }

  get selectionEventModel(): KinesisEvent {
    return console.error(this.constructor.name + 'Implementation is missing: selectionEventModel');
  }
}
