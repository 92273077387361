import classNames from "classnames";
import styles from "../selection-list-widget.module.scss";
import {observer} from "mobx-react";
import {Image} from "../../../components/image/image";

export const SelectionListWidgetItemInfo = observer(({item, entityModel}) => {
  const {entityLogoSource, shouldRenderSubtext} = entityModel;
  const {id, name, countryName} = item;

  const entityLogoProps = {
    className: classNames(styles.entity_logo),
    src: new entityLogoSource(id)
  }

  return (
    <div className={classNames(styles.competitor)}>
      <Image {...entityLogoProps}/>
      <div>
      {name}
      {
        shouldRenderSubtext ? <div className={classNames(styles.competitor_subtext)}>{countryName}</div> : null
      }
      </div>
    </div>
  );
});


