import {observer} from "mobx-react";
import {SettingsWidgetMemberItem} from "./settings.widget.member-item";
import {UserModel} from "../../../../models/user";

export const SettingsWidgetMembers = observer(({controller}) => {
  const {state} = controller;
  const {table} = state;

  return table?.members?.map((member: UserModel) => <SettingsWidgetMemberItem
      key={member.userId}
      member={member}
      isAdmin={table.isAdmin}
      controller={controller}
    />
  )
});
