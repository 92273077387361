import classNames from "classnames";
import styles from "../navigation-bar.module.scss";
import {NavigationBarButtonDesktop} from "./navigation-bar.button.desktop";
import {APP_CONST} from "../../../consts";
import {GameRulesImageRequestParams} from "../../../icons/game-rules";
import {LeaderboardImageRequestParams} from "../../../icons/leaderboard";
import {ProfileImageRequestParams} from "../../../icons/profile";
import {shareState} from "../../../app/share-state";
import {PredictionImageRequestParams} from "../../../icons/prediction";
import {LeaguesImageRequestParams} from "../../../icons/leagues";
import {AccaButtonLarge} from "../../acca/acca-button.large";

export const NavigationBarButtonsDesktop = ({controller}) => {
  const {state: {shouldRenderLeagueNavigationButton}} = controller;

  const homeProps = {
    source: APP_CONST.ROUTE.home,
    iconType: ProfileImageRequestParams,
    label: shareState.termsUtils.terms.WC_2022_PROFILE
  }

  const predictionProps = {
    source: APP_CONST.ROUTE.prediction,
    iconType: PredictionImageRequestParams,
    label: shareState.termsUtils.terms.WC_2022_SCORES_BUTTON
  }

  const leaderboardProps = {
    source: APP_CONST.ROUTE.leaderboard,
    iconType: LeaderboardImageRequestParams,
    label: shareState.termsUtils.terms.WC_2022_LEADERBOARD_BUTTON
  }

  const leaguesProps = {
    source: APP_CONST.ROUTE.leagues,
    iconType: LeaguesImageRequestParams,
    label: shareState.termsUtils.terms.WC_2022_LEAGUES_BUTTON,
    shouldRenderNavigationButton: shouldRenderLeagueNavigationButton
  }

  const gameRulesProps = {
    source: APP_CONST.ROUTE.rules,
    iconType: GameRulesImageRequestParams,
    label: shareState.termsUtils.terms.WC_2022_RULES_BUTTON
  }

  return (
    <div className={classNames(styles.buttons)}>
      <NavigationBarButtonDesktop {...homeProps}/>
      <NavigationBarButtonDesktop {...predictionProps}/>
      <NavigationBarButtonDesktop {...leaderboardProps}/>
      {
        shareState.isACCAActive ? <AccaButtonLarge /> : null
      }
      <NavigationBarButtonDesktop {...leaguesProps}/>
      <NavigationBarButtonDesktop {...gameRulesProps}/>
    </div>
  );
};

