import classNames from "classnames";
import styles from '../acca-widget.module.scss';
import {shareState} from "../../../app/share-state";
import {LoadingIndicator} from "../../../components/loading-indicator/loading-indicator";
import {observer} from "mobx-react";
import {useEffect} from "react";
import {AccaWidgetBetSlipBets} from "./acca-widget.bet-slip.bets";
import {ACCA_WIDGET} from "../acca-widget.consts";
import {BackImageRequestParams} from "../../../icons/back";
import {Image} from "../../../components/image/image";

export const AccaWidgetBetSlip = observer(({controller}) => {
  useEffect(() => {
    controller.getBetSlip().then(() => {

    });
  }, []);

  const {state: {isLoadingBetSlip}} = controller;

  const backProps = {
    src: new BackImageRequestParams(11, 20),
    className: classNames(styles.back),
    onClick: () => controller.state.page = ACCA_WIDGET.pages.bets,
  };

  const containerProps = {
    className: classNames(styles.bets_container),
  }

  return (
    <div {...containerProps}>
      <Image {...backProps} />
      <div className={classNames(styles.title)}>
        {
          shareState.termsUtils.terms.BOLAO_ACCA_IS_READY
        }
      </div>
      {
        isLoadingBetSlip ? <LoadingIndicator/> : <AccaWidgetBetSlipBets controller={controller}/>
      }
    </div>
  )
});
