import {RequestModel} from "../../../request";
import {REQUEST} from "../../../requests.consts";
import {shareState} from "../../../../../app/share-state";

export class GetRequestDeleteGroupModel extends RequestModel {
  constructor(requestParams) {
    super(REQUEST.CATEGORY.groups, REQUEST.ACTION.GROUPS.delete, requestParams, false, false, true, false);

    this.apiEnvironment = shareState.environment.baseUrl;
    this.method = REQUEST.METHOD_TYPE.get;
  }
}
