import {SelectionListWidgetEntityModel} from "./selection-list.widget.entity.model.js";
import {shareState} from "../../../app/share-state";
import {GetRequestAllTeamsModel} from "../../../models/request/category/tournament/get/get-request.all-teams.model";
import {PostRequestSetWinnerTeamModel} from "../../../models/request/category/bets/post/post-request.set-winner-team.model";
import {RequestModel} from "../../../models/request/request";
import {CupSelectedImageRequestParams} from "../../../icons/cup/cup-selected";
import {CupDeselectedImageRequestParams} from "../../../icons/cup/cup-deselected";
import styles from "../selection-list-widget.module.scss";
import {CompetitorFlagImageRequestParams} from "../../../icons/competitor/competitor-flag";
import {KinesisEvent} from "../../../analytics/kinesis/events/kinesis.event";
import {WinningTeamDisplayKinesisEvent} from "../../../analytics/kinesis/events/selection-list/winning-team.display.kinesis-event";
import {WizardTeamSelectionClickKinesisEvent} from "../../../analytics/kinesis/events/wizard/wizard.team-selection.click.kinesis-event";
import {WinningTeamChangeKinesisEvent} from "../../../analytics/kinesis/events/selection-list/winning-team.change.kinesis-event";

export class SelectionListWidgetCompetitorModel extends SelectionListWidgetEntityModel {
  get fetchAllItems(): String {
    const fetchAllItems: GetRequestAllTeamsModel = new GetRequestAllTeamsModel();

    return fetchAllItems;
  }

  get fetchSelectedItemType(): RequestModel {
    return PostRequestSetWinnerTeamModel;
  }

  get searchPlaceholder(): String {
    const searchPlaceholder: String = shareState.termsUtils.terms.WC_2022_COUNTRY_NAME;

    return searchPlaceholder;
  }

  get title(): String {
    const title: String = shareState.user.allowTournamentBets ? shareState.termsUtils.terms.WC_2022_SELECT_WINNING_TEAM :  shareState.termsUtils.terms.WC_2022_QUALIFIED_TEAMS;

    return title;
  }

  get subtitle(): String {
    const subtitle: String = shareState.user.allowTournamentBets ? shareState.termsUtils.terms.WC_2022_SELECT_WINNIG_TEAM_SECOND_LINE : '';

    return subtitle;
  }

  get entityLogoSource(): String {
    return CompetitorFlagImageRequestParams;
  }

  get logoClassName(): String {
    return styles.cup_logo
  }

  get selectedLogo(): CupSelectedImageRequestParams {
    return new CupSelectedImageRequestParams(32, 32);
  }

  get deselectedLogo(): CupDeselectedImageRequestParams {
    return new CupDeselectedImageRequestParams(32, 32);
  }

  get hasSearch(): Boolean {
    return shareState.user.allowTournamentBets || shareState.wizard.isVisible;
  }

  get selectedItemId(): Number {
    return shareState.user.winnerTeamId;
  }

  get shouldRenderSubtext(): Boolean {
    return false;
  }

  get displayEventModel(): WinningTeamDisplayKinesisEvent {
    return new WinningTeamDisplayKinesisEvent({id: this.selectedItemId})
  }

  get selectionEventModel(): KinesisEvent {
    return shareState.wizard.isVisible ? WizardTeamSelectionClickKinesisEvent : WinningTeamChangeKinesisEvent;
  }
}
