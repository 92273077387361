import classnames from "classnames";
import styles from './acca-button.module.scss';
import {Image} from "../image/image";
import {shareState} from "../../app/share-state";
import {AccaImageRequestParams} from "../../icons/acca";
import {BackImageRequestParams} from "../../icons/back";
import {useEffect} from "react";
import {kinesisAnalyticsUtils} from "../../analytics/kinesis/kinesis.analytics-utils";
import {AccaBannerDisplayKinesisEvent} from "../../analytics/kinesis/events/acca/banner/acca-banner.display.kinesis-event";
import {AccaBannerClickKinesisEvent} from "../../analytics/kinesis/events/acca/banner/acca-banner.click.kinesis-event";
import {useInView} from "react-intersection-observer";

export const AccaButtonLarge = ({onClick}) => {
  const config = {triggerOnce: true};
  const [ref, inView] = useInView(config);

  useEffect(() => {
    inView && kinesisAnalyticsUtils.send(new AccaBannerDisplayKinesisEvent({
      app_device_id: shareState.nativeGameConfig?.deviceId,
      web_visitor_id: shareState.websiteVisitorId,
    }));
  }, [inView]);

  const containerProps = {
    ref,
    className: classnames(styles.container, {[styles.small_view]: shareState.isMobileView}),
    onClick: () => {

      shareState.changeACCAVisibility(true);
      kinesisAnalyticsUtils.send(new AccaBannerClickKinesisEvent({
        app_device_id: shareState.nativeGameConfig?.deviceId,
        web_visitor_id: shareState.websiteVisitorId,
      }));
      onClick?.()
    }
  }

  const iconProps = {
    className: classnames(styles.icon),
    src: new AccaImageRequestParams()
  }

  const arrowIconProps = {
    className: classnames(styles.icon, styles.arrow),
    src: new BackImageRequestParams(11, 20),
  }
  return <div {...containerProps}>
    <Image {...iconProps} />
    <span className={classnames(styles.main_text)}>
    {
      shareState.termsUtils.terms.BOLAO_ACCA_PROMOTION_TEXT_MAIN
    }
    </span>
    <span className={classnames(styles.secondary_text)}>
    {
      shareState.termsUtils.terms.BOLAO_ACCA_PROMOTION_TEXT_SECONDARY
    }
    </span>
    <Image {...arrowIconProps} />
  </div>
}