import {RequestModel} from "../../../request";
import {REQUEST} from "../../../requests.consts";
import {shareState} from "../../../../../app/share-state";

export class GetRequestACCAGamesModel extends RequestModel {
  constructor() {
    super(REQUEST.CATEGORY.games, REQUEST.ACTION.GAMES.getACCAGames, {}, false, true, true, true); //change to getACCAGames

    this.apiEnvironment = shareState.environment.baseUrl;
    this.method = REQUEST.METHOD_TYPE.get;
  }
}
