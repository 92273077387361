import {KinesisEvent} from "../kinesis.event";
import {KINESIS} from "../../kinesis.consts";

export class PredictionGameAutoFillKinesisEvent extends KinesisEvent {
  constructor(properties) {
    super(
      KINESIS.CATEGORY.bolao,
      KINESIS.ACTION.autoFill,
      KINESIS.LABEL.click,
      null,
      properties
    );
  }

  getProperties(params) {
    const props = super.getProperties()

    return {
      ...props,
      num_of_games_auto_fill: params.numOfGames,
      source: params.source,
    }
  }
}
