import classNames from "classnames";
import styles from '../styles/games-widget.module.scss';
import {observer} from "mobx-react";
import {Game} from "../../../components/game/game";
import {CollapsedItem} from "../../../components/collapsed-item/collapsed-item";
import {GameWidgetInputModel} from "../models/games.widget.game-input-model";
import {AccaButtonLarge} from "../../../components/acca/acca-button.large";
import {shareState} from "../../../app/share-state";

export const GamesWidgetGroup = observer(({controller, group, shouldConsiderAcca}) => {
  const {state} = controller;
  const {disableCollapsibleGroups, currentGameId} = state;
  const {shouldRenderAcca} = group;

  return (
    <div key={group.key} className={classNames(styles.group)}>
      <CollapsedItem disable={disableCollapsibleGroups} item={group}>
        {
          group.games.map((game: GameWidgetInputModel) => <Game
            item={game}
            controller={controller}
            shouldRenderGameBets={true}
            key={game.id}
            isCurrentGame={game.sportifierGameId === currentGameId}
          />)
        }
        {
          shareState.isMobileView && shouldConsiderAcca && shouldRenderAcca ? <AccaButtonLarge /> : null
        }
      </CollapsedItem>
    </div>
  )
});
