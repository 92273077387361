import {BaseModel} from "./base";
import {WizardWidgetPageModel} from "../widgets/wizard-widget/models/wizard-widget.page.model";
import {WizardWidgetPageWinningTeamModel} from "../widgets/wizard-widget/models/wizard-widget.page.winning-team.model";
import {WIZARD} from "../widgets/wizard-widget/wizard.widget.consts";
import {WizardWidgetPageTopScorerModel} from "../widgets/wizard-widget/models/wizard-widget.page.top-scorer.model";
import {WizardWidgetPageGamesModel} from "../widgets/wizard-widget/models/wizard-widget.page.games.model";
import {observable, makeObservable, computed} from 'mobx';

export class WizardDisplayModel extends BaseModel {
  @observable currentPage: Number;
  @observable isVisible: Boolean;

  constructor() {
    super();

    makeObservable(this)

    this.currentPage = 0;
    this.isVisible = false;
  }

  @computed get pages(): Array<WizardWidgetPageModel> {
    let pages: Array<WizardWidgetPageModel> = [];

    const winningPage: WizardWidgetPageWinningTeamModel = new WizardWidgetPageWinningTeamModel(WIZARD.PAGES.winningTeam);
    const topScorerPage: WizardWidgetPageTopScorerModel = new WizardWidgetPageTopScorerModel(WIZARD.PAGES.topScorer);
    const gamesPage: WizardWidgetPageGamesModel = new WizardWidgetPageGamesModel(WIZARD.PAGES.games);

    pages.push(winningPage);
    pages.push(topScorerPage);
    pages.push(gamesPage);

    return pages
  }

  @computed get currentPageModel(): WizardWidgetPageModel {
    const currentPageModel: WizardWidgetPageModel = this.pages.find((page: WizardWidgetPageModel) => page.id === this.currentPage);

    return currentPageModel
  }
}