import {GameKeyboard} from "./keyboard/game.keyboard";
import {GameBoard} from "./game.board";
import {observer} from "mobx-react";
import {useCurrentPath} from "../../hooks/use-current-path";
import {GameWrapper} from "./game-wrapper";

export const Game = observer(({item, controller, shouldRenderGameBets, isSmallView, isCurrentGame}) => {
  const {pathName} = useCurrentPath();

  const boardProps = {
    item,
    controller,
    shouldRenderGameBets,
    setActive: (e, index) => controller?.setActive(item, index),
    onInputChange: (e, value, index) => controller?.onChangeBetScore(item, value, index, pathName),
  }

  const keyboardProps = {
    item,
    isSmallView,
    onSetValue: (value) => controller?.onChangeBetScore(item, value, null, pathName)
  }


  return (
    <GameWrapper item={item} isSmallView={isSmallView} isCurrentGame={isCurrentGame}>
      <GameBoard {...boardProps}/>
      <GameKeyboard {...keyboardProps}/>
    </GameWrapper>
  );
});


