import {KinesisEvent} from "../kinesis.event";
import {KINESIS} from "../../kinesis.consts";

export class SettingsClickKinesisEvent extends KinesisEvent {
  constructor() {
    super(
      KINESIS.CATEGORY.bolao,
      KINESIS.ACTION.settings,
      KINESIS.LABEL.click,
      null
    );
  }
}
