import classNames from "classnames";
import styles from '../game.module.scss';
import {observer} from "mobx-react";
import {GameKeyboardButtons} from "./game.keyboard.buttons";
import {shareState} from "../../../app/share-state";

export const GameKeyboard = observer(({item, onSetValue, isSmallView}) => {
  const buttonsProps = {
    item,
    onSetValue
  }

  return (
    (isSmallView || shareState.isMobileView) && item.isActive ? <div className={classNames(styles.keyboard)}>
      <GameKeyboardButtons {...buttonsProps}/>
    </div> : null
  );
});
