import {GameWidgetInputModel} from "../widgets/games-widget/models/games.widget.game-input-model";
import {HomeWidgetState} from "../widgets/home-widget/home.widget.state";
import {GameSelectionModel} from "../models/game/game.selection";
import {GameSetBet} from "../models/game/game.set-bet";
import {PostRequestSetGameBetsModel} from "../models/request/category/bets/post/post-request.set-game-bets.model";
import {GameSetSelection} from "../models/game/game.set-selection";
import {action} from 'mobx';
import {GamesWidgetGroupModel} from "../widgets/games-widget/models/games-widget.group.model";
import {kinesisAnalyticsUtils} from "../analytics/kinesis/kinesis.analytics-utils";
import {GamePredictionKinesisEvent} from "../analytics/kinesis/events/game/game.prediction.kinesis-event";
import {WidgetController} from "./widget.controller";
import {Bookmaker} from "../models/bets/bookmaker";
import {LineType} from "../models/bets/line-type";
import {Line} from "../models/bets/line";
import {Selection} from "../models/bets/selection";

export class GamesBetsWidgetController extends WidgetController {
  state: HomeWidgetState;

  @action onChangeBetScore(game: GameWidgetInputModel, value: Number, index: Number, source: String) {
    let currentIndex: Number = game?.bets[1].active ? 1 : 0;

    if (index) {
      currentIndex = index;
      game.bets[index].score = value;
    } else {
      game.activeBet.score = value;
    }

    this.activeNextCell(game, currentIndex, source);
  }

  setActive(game: GameWidgetInputModel, index: Number) {
    if(game) {
      this.closeActiveCells(game.id);
      game.setActive(index);
    }
  }

  closeActiveCells(gameId: Number) {
    this.state.games.forEach((game: GameWidgetInputModel) => {
      const hasActiveBets: Boolean = game.bets && (game.bets[0].active || game.bets[1].active);
      const isDifferentGame: Boolean = game !== gameId;

      if(hasActiveBets && isDifferentGame) {
        game.setInactive()
      }
    });
  }

 @action activeNextCell(currentGame: GameWidgetInputModel, betIndex: Number, source: String) {
    if (currentGame.isBetsFulfilled) {
      this.sendBet(currentGame.id, currentGame.bets[0].score , currentGame.bets[1].score,source)

      currentGame.setInactive();

      let nextBetIndex: Number;
      let nextGame: GameWidgetInputModel;
      const currentGameIndex: Number = this.state.games.findIndex((game: GameWidgetInputModel) => currentGame.id === game.id);

      if(betIndex === 0) {
        nextGame = currentGame;
        nextBetIndex = 1;
      } else if(this.state.games?.length !== currentGameIndex) {
        nextGame = this.state.games.find((game: GameWidgetInputModel,index: Number) => index > currentGameIndex && game.isBetable)
        nextBetIndex = 0
      }

      const currentGroup: GamesWidgetGroupModel = this.state.groups?.find((group: GamesWidgetGroupModel) => !!group.games?.find((game: GameWidgetInputModel) => game.id === currentGame.id));
      const nextGroup: GamesWidgetGroupModel = this.state.groups?.find((group: GamesWidgetGroupModel) => !!group.games?.find((game: GameWidgetInputModel) => game.id === nextGame?.id));

      if(nextGroup?.key !== currentGroup?.key) {
        currentGroup.open = false;
        if(nextGroup) {
          nextGroup.open = true;
        }
      }

      nextGame?.isBetable && nextGame?.setActive(nextBetIndex)
    } else {
      currentGame.setActive();
    }
  }

  async sendBet(gameId: Number, team1, team2, source: String) {
    const selection: GameSelectionModel = new GameSetSelection({Team1: team1, Team2: team2})
    const bet: GameSetBet = new GameSetBet({gameId, selection})

    const requestModel: PostRequestSetGameBetsModel = new PostRequestSetGameBetsModel([bet]);

    await this.sendPostRequest(requestModel);
    await kinesisAnalyticsUtils.send(new GamePredictionKinesisEvent({id: gameId, source: source}))
  }

  async onPopulateGames(dataSource) {
    this.state.dataSource = dataSource;
  }

  createSelections(data: Object) {
    if(!data) {
      return null;
    }

    const bookmakers: Array<Bookmaker> = data.Bookmakers?.map((bookmaker) => new Bookmaker(bookmaker));
    const lineTypes: Array<LineType> = data.LineTypes?.map((lineType) => new LineType(lineType));
    const lines: Array<Line> = data.Lines?.map((line) => {
      const currentBookmaker = bookmakers.find((bookmaker) => bookmaker.id === line.BMID);
      const currentLineType = lineTypes.find((lineType) => lineType.id === line.Type);

      return new Line(line, currentBookmaker, currentLineType);
    });

    const selections: Array<Selection> = data.Selections?.map((selection) => {
      const currentLine = lines.find((line) => line.id === selection.LineId);
      return new Selection(selection, currentLine);
    })?.sort((a, b) => a.line.lineType === 126 - b.line.lineType === 126)

    return selections;
  }
}
