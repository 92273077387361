import {GamesWidgetState} from "./games.widget.state";
import {GetRequestAllGamesModel} from "../../models/request/category/games/get/get-request.all-games.model";
import {
  GetRequestOtherUserGamesModel
} from "../../models/request/category/games/get/get-request.other-user-games.model";
import {GamesBetsWidgetController} from "../../controllers/games-bets.widget.controller";
import {shareState} from "../../app/share-state";
import {GAMES_WIDGET} from "./games-widget.consts";
import {
  PostRequestRandomizeEmptyBetsModel
} from "../../models/request/category/bets/post/post-request.randomize-empty-bets.model";
import {GetRequestLiveGamesModel} from "../../models/request/category/games/get/get-request.live-games.model";
import {GameModel} from "../../models/game/game";
import {GameWidgetInputModel} from "./models/games.widget.game-input-model";
import {REQUEST} from "../../models/request/requests.consts";
import {DeltaRequestModel} from "../../models/request/delta-request";
import {RequestModel} from "../../models/request/request";
import {
  PostRequestSetWinnerTeamModel
} from "../../models/request/category/bets/post/post-request.set-winner-team.model";
import {GetRequestUserSettingsModel} from "../../models/request/category/users/get/get-request.user-settings.model";
import {UserModel} from "../../models/user";
import {PostRequestSetTopPlayerModel} from "../../models/request/category/bets/post/post-request.set-top-player.model";
import {kinesisAnalyticsUtils} from "../../analytics/kinesis/kinesis.analytics-utils";
import {
  PredictionDisplayKinesisEvent
} from "../../analytics/kinesis/events/prediction/prediction.display.kinesis-event";
import {
  PredictionGameAutoFillKinesisEvent
} from "../../analytics/kinesis/events/prediction/prediction.game.auto-fill.kinesis-event";

export class GamesWidgetController extends GamesBetsWidgetController {
  state: GamesWidgetState;

  async init() {
    await super.init();

    this.state.selectedSortId = shareState.wizard.isVisible ? GAMES_WIDGET.GROUPS_ORDER.byGroup : GAMES_WIDGET.GROUPS_ORDER.byDate;
    this.props.userId ? await this.getOtherUserGames() : await this.getAllGames();

    await kinesisAnalyticsUtils.send(new PredictionDisplayKinesisEvent({
      isOtherUser: Boolean(this.props?.userId),
      userId: this.props?.userId || shareState.user.userId
    }));

    shareState.registerToAccaVisibilityCallback('GamesWidgetController', (isVisible) => !isVisible && this.getAllGames());


    this.state.isLoading = false;
  }

  updateData() {
    this.getAllGames()
  }

  async getAllGames() {
    const requestModel: GetRequestAllGamesModel = new GetRequestAllGamesModel();

    await this.sendGetRequest(requestModel, async (data) => {
      await this.onPopulateAllGames(data)
      this.getLiveGame()
    });
  }

  async getLiveGame() {
    this.state.hasLiveGames = this.state.games?.find((game: GameModel) => game.isLive);
    const requestModel: GetRequestLiveGamesModel = new GetRequestLiveGamesModel(this.state.hasLiveGames);

    await this.sendGetRequest(requestModel, (data) => this.onPopulateLiveGames(data));
  }

  async onPopulateLiveGames(data) {
    if (data.games?.length > 0) {
      this.setLiveInterval()
      this.updateLiveGames(data)
    } else {
      await this.setNotLiveInterval()
    }
  }

  setLiveInterval() {
    if (this.activeLiveRequest?.interval !== REQUEST.UPDATE_TIME_INTERVALS.live) {
      this.fetchManager.stopDelta(this.activeLiveRequest)
      this.clearActiveRequests()
      const requestModel: GetRequestLiveGamesModel = new GetRequestLiveGamesModel(true);
      this.state.activeRequests.push(requestModel)
      this.fetchManager.startDelta(requestModel, (data) => this.onPopulateLiveGames(data))
    }
  }

  get activeLiveRequest(): DeltaRequestModel {
    let activeLiveRequest: DeltaRequestModel = this.state.activeRequests?.find((request: DeltaRequestModel) => request instanceof GetRequestLiveGamesModel);

    return activeLiveRequest
  }

  updateLiveGames(data) {
    const gameModels: Array<GameModel> = data.games?.map((game: GameModel) => new GameWidgetInputModel(game))

    gameModels.forEach((game: GameModel) => this.updateLiveGame(game))

    if (this.state.liveGamesAmount !== data.games?.length && data.games?.length > 0) {
      this.getAllGames()
    }
  }

  updateLiveGame(game) {
    let currentLiveGameIndex = this.state._games?.findIndex((gameModel: GameModel) => gameModel.id === game.id);
    if ((currentLiveGameIndex > -1) && this.state._games[currentLiveGameIndex]) {
      this.state._games[currentLiveGameIndex] = game
    }
  }

  async setNotLiveInterval() {
    if (this.activeLiveRequest.interval !== REQUEST.UPDATE_TIME_INTERVALS.default) {
      this.fetchManager.stopDelta(this.activeLiveRequest)
      this.clearActiveRequests()

      await this.getAllGames()
    }
  }

  async getOtherUserGames() {
    this.state.userId = Number(this.props.userId);

    const params = {otherUserId: this.props.userId}
    const requestModel: GetRequestOtherUserGamesModel = new GetRequestOtherUserGamesModel(params);

    await this.sendGetRequest(requestModel, async (data) => {
      await this.onPopulateAllGames(data)
    });
  }

  async onPopulateAllGames(data) {
    await this.onPopulateGames(data, data.games)
    await this.createGames(data.games)
  }

  async getRandomizeBets() {
    const requestModel: PostRequestRandomizeEmptyBetsModel = new PostRequestRandomizeEmptyBetsModel();
    const numOfUnGuessesGames = this.state.numOfUnGuessesGames;

    await this.sendPostRequest(requestModel, (data) => {
      !shareState.user.isTournamentStarted && this.onPopulateUserSelections(data);
      this.onPopulateAllGames(data)
    })

    await kinesisAnalyticsUtils.send(new PredictionGameAutoFillKinesisEvent({
      numOfGames: numOfUnGuessesGames,
      source: this.props.source
    }))
  }

  async onPopulateUserSelections(dataSource) {
    const winnerTeamRequestModel: RequestModel = new PostRequestSetWinnerTeamModel(dataSource.tournamentBets?.winnerTeamID);
    const topScorerRequestModel: RequestModel = new PostRequestSetTopPlayerModel(dataSource.tournamentBets?.topScorerID);

    await this.sendPostRequest(winnerTeamRequestModel)
    await this.sendPostRequest(topScorerRequestModel)
    await this.sendUserRequest()
  }

  async sendUserRequest() {
    const requestModel: GetRequestUserSettingsModel = new GetRequestUserSettingsModel();

    await this.sendGetRequest(requestModel, (data) => shareState.user = new UserModel(data.user))
  }

  async createGames(games): Array<GameWidgetInputModel> {
    const currentGames: Array<GameModel> = games?.map((game: GameModel) => {
      return new GameWidgetInputModel(game);
    });

    this.state.games = currentGames;
  }

  clearActiveRequests() {
    this.state.activeRequests = [];
  }

  onUnload() {
    super.onUnload();

    shareState.registerToAccaVisibilityCallback('GamesWidgetController', null);

  }
}
