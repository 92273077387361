import {makeObservable, observable, computed} from 'mobx';
import {BaseModel} from "../../../models/base";
import {UserModel} from "../../../models/user";

export class ProfileHeaderButtonModel extends BaseModel {
  @observable user: UserModel;
  pathName: String;
  isOtherUser: Boolean;

  constructor(
    user: UserModel,
    pathName: String,
    isOtherUser: Boolean,
  ) {
    super();

    makeObservable(this)

    this.user = user;
    this.pathName = pathName;
    this.isOtherUser = isOtherUser;
  }

  @computed get entityId(): Number {
    return console.error(this.constructor.name + 'Implementation is missing: entityId');
  }

  @computed get wizardPage(): Number {
    return console.error(this.constructor.name + 'Implementation is missing: wizardPage');
  }

  @computed get source(): String {
    return console.error(this.constructor.name + 'Implementation is missing: source');
  }

  @computed get name(): String {
    return console.error(this.constructor.name + 'Implementation is missing: name');
  }

  @computed get imageSource(): String {
    return console.error(this.constructor.name + 'Implementation is missing: imageSource');
  }

  get sectionName(): String {
    return console.error(this.constructor.name + 'Implementation is missing: sectionName');
  }

  get iconClassName(): String {
    return console.error(this.constructor.name + 'Implementation is missing: iconClassName');
  }

  @computed get hasNoSelection(): Boolean {
    return console.error(this.constructor.name + 'Implementation is missing: searchPlaceholder');
  }

  @computed get isRouteActive(): Boolean {
    return console.error(this.constructor.name + 'Implementation is missing: isRouteActive');
  }

  @computed get clickEventModel(): Boolean {
    return console.error(this.constructor.name + 'Implementation is missing: clickEventModel');
  }
}
