import React, {Suspense} from "react";
import "./leaderboard-page.module.scss";
import {LoadingIndicator} from "../../components/loading-indicator/loading-indicator";
import {useParams} from "react-router-dom";

const LeaderboardPage = React.lazy(() => import("./leaderboard-page" /* webpackChunkName: "leaderboard.page" */ ));

const LeaderboardPageElement = ({location}) => {
  const {groupId} = useParams();

  return (
    <Suspense fallback={<LoadingIndicator/>}>
      <LeaderboardPage location={location} groupId={parseInt(groupId)}/>
    </Suspense>
  )
};

export default LeaderboardPageElement;