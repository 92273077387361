import classNames from "classnames";
import styles from "../selection-list-widget.module.scss";
import {observer} from "mobx-react";
import {SelectionListWidgetItem} from "./selection-list.widget.item";
import {SelectionListWidgetEntityModel} from "../models/selection-list.widget.entity.model";
import {shareState} from "../../../app/share-state";

export const SelectionListWidgetList = observer(({controller}) => {
  const {state} = controller;
  const {entityModel, hasNoItems} = state;

  return (
    !hasNoItems ? <div className={classNames(styles.list)}>
      {
        entityModel.items?.map((item: SelectionListWidgetEntityModel, index: Number) =>
            <SelectionListWidgetItem
              key={item.id}
              item={item}
              index={index + 1}
              controller={controller}
            />
        )
      }
    </div> : <div className={classNames(styles.empty_state)}>{shareState.termsUtils.terms.WC_2022_NO_DATA}</div>
  );
});


