import {DEVICE, GA4_COOKIE, GUID} from "../../consts";
import {shareState} from "../../app/share-state";
import {cookieUtils} from "../utils/cookie-utils";

export function useGuid() {
  const openBookieURL = (url) => {
    let bookieLink: String = url;
    let guid: String;

    if (bookieLink.includes(GUID.PARAMETER)) {
      guid = generateGUID(GUID.LENGTH, GUID.CHARS);

      bookieLink = bookieLink.replace(GUID.PARAMETER, guid);
    }

    if (bookieLink.includes(DEVICE.PARAMETER) && shareState.nativeGameConfig?.deviceId) {
      bookieLink = bookieLink.replace(DEVICE.PARAMETER, shareState.nativeGameConfig?.deviceId);
    }

    bookieLink = replaceGa4SessionId(replaceGa4ClientId(bookieLink));


    shareState.isWebView ? shareState.nativeFunctionsUtils.callFunction("openBrowser", {url: bookieLink}) :
      window.open(bookieLink, '_blank')

    return {guid, bookieLink};
  }


  const generateGUID: String = (length, characters) => {
    let result = '';

    for (let i = 0; i < length; i++) {
      const rand: Number = Math.random();
      const index: Number = Math.floor(rand * characters.length);
      result += characters.charAt(index);
    }

    return result;
  }

  const replaceGa4ClientId: String = (link) => {
    let bookieLink: String = link;

    if (bookieLink.includes(GA4_COOKIE.PARAMS.CLIENT_ID)) {
      const ga4ClientIdParam: String = cookieUtils.getCookieByName(GA4_COOKIE.KEYS.CLIENT_ID);
      let ga4ClientId: String = ga4ClientIdParam?.split('.')?.slice(2, ga4ClientIdParam.length).join('_');
      ga4ClientId = ga4ClientId?.replaceAll(/\./g, "");

      bookieLink = bookieLink.replace(GA4_COOKIE.PARAMS.CLIENT_ID, ga4ClientId);
    }

    return bookieLink;
  }

  const replaceGa4SessionId: String = (link) => {
    let bookieLink: String = link;

    if (bookieLink.includes(GA4_COOKIE.PARAMS.SESSION_ID)) {
      const ga4SessionIdParams: Array<String> = cookieUtils.getCookiesBySubstring(GA4_COOKIE.KEYS.SESSION_ID);
      let ga4SessionId: String = ga4SessionIdParams?.first()?.last()?.split('.')[2];
      ga4SessionId = ga4SessionId?.replaceAll(/\./g, "");

      bookieLink = bookieLink.replace(GA4_COOKIE.PARAMS.SESSION_ID, ga4SessionId);
    }

    return bookieLink;
  }

  return (url) => openBookieURL(url)
}