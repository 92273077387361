import {WidgetState} from "../widget.state";
import {GroupModel} from "../../models/group";

import {computed, makeObservable, observable} from "mobx";
import {TableModel} from "../../models/table";
import {shareState} from "../../app/share-state";

export class SettingsWidgetState extends WidgetState {
  @observable mainGroupId: Number;
  @observable tableEditMode: TableModel;
  @observable tableDataSource;

  constructor() {
    super()

    makeObservable(this)
  }

  @computed get groups(): Array<GroupModel> {
    const groups: Array<GroupModel> = this.dataSource?.groups?.map((group: GroupModel) => new GroupModel(group))
      .sort((a, b) => ((b.id === shareState.user.masterGroupId) - (a.id === shareState.user.masterGroupId)) || (a.id - b.id));

    return groups;
  }

  @computed get selectedGroup(): GroupModel {
    const selectedGroup: GroupModel = this.groups?.find((group: GroupModel) => group.id === this.mainGroupId);

    return selectedGroup;
  }

  @computed get isAdmin(): Boolean {
    const isAdmin: Boolean = this.selectedGroup?.isAdmin

    return isAdmin;
  }

  @computed get table(): Array<GroupModel> {
    const table: Array<GroupModel> = this.tableDataSource && new TableModel(this.tableDataSource);

    return table;
  }
}
