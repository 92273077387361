import classNames from "classnames";
import styles from "../navigation-bar.module.scss";
import {APP_CONST, ROUTE_NAME} from "../../../consts";
import {Image} from "../../image/image";
import {useCurrentPath} from "../../../hooks/use-current-path";
import {COLORS} from "../../../styles/colors";
import {shareState} from "../../../app/share-state";
import {Link} from "../../link/link";
import {kinesisAnalyticsUtils} from "../../../analytics/kinesis/kinesis.analytics-utils";
import {MenuClickKinesisEvent} from "../../../analytics/kinesis/events/navigation-bar/menu.click.kinesis-event";

export const NavigationBarButtonDesktop = ({source, iconType, label, shouldRenderNavigationButton = true}) => {
  const {pathName} = useCurrentPath();

  const isActive = ROUTE_NAME[source] === pathName;
  const isHome = ROUTE_NAME[source] === ROUTE_NAME[APP_CONST.ROUTE.home];

  const imageProps = {
    className: classNames(styles.button_logo),
    src: new iconType(24, 24, shareState.wizard.isVisible ? COLORS[shareState.theme].disable : COLORS[shareState.theme].text)
  }

  const linkProps = {
    to: !shareState.wizard.isVisible ? `${shareState.currentDisplayName ? `/${shareState.currentDisplayName}` : ''}${!isHome ? source : '/'}` : null,
    className: classNames(styles.button,
      {[styles.active_button]: isActive},
      {[styles.inactive_button]: shareState.wizard.isVisible}
    ),
    onClick: () => {
      kinesisAnalyticsUtils.send(new MenuClickKinesisEvent({source}));
      // window.scrollTo(0, 0);

      return true;
    }
  }

  return shouldRenderNavigationButton ? <Link {...linkProps}>
    <Image {...imageProps}/>
    {label}
  </Link> : null;
};


