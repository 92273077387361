import {TermModel} from "../../models/term";
import {Terms} from "../../terms";

export class TermsUtils {
  terms: Terms;

  constructor() {
    this.terms = new Terms();
  }

  setTerm(term: TermModel) {
    this.terms[term.aliasName] = term.value
  }
}
