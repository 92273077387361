import {observer} from "mobx-react";
import classnames from "classnames";
import styles from "../../styles/app.module.scss"
import {shareState} from "../../share-state";
import {Image} from "../../../components/image/image";
import {AccaImageRequestParams} from "../../../icons/acca";
import {useEffect} from "react";
import {kinesisAnalyticsUtils} from "../../../analytics/kinesis/kinesis.analytics-utils";
import {
  AccaTopNavigationDisplayKinesisEvent
} from "../../../analytics/kinesis/events/acca/top-navigation/acca-top-navigation.display.kinesis-event";
import {
  AccaTopNavigationClickKinesisEvent
} from "../../../analytics/kinesis/events/acca/top-navigation/acca-top-navigation.click.kinesis-event";

export const AppHeaderAccaButton = observer(({controller, isLoginPage}) => {
  const {state} = controller;
  const {shouldRenderACCAButton} = state;

  useEffect(() => {
    shouldRenderACCAButton && !isLoginPage && kinesisAnalyticsUtils.send(new AccaTopNavigationDisplayKinesisEvent(({
      app_device_id: shareState.nativeGameConfig?.deviceId,
      web_visitor_id: shareState.websiteVisitorId,
    })))
  }, []);

  const handleOpen = () => {
    shareState.changeACCAVisibility(true);

    kinesisAnalyticsUtils.send(new AccaTopNavigationClickKinesisEvent(({
      app_device_id: shareState.nativeGameConfig?.deviceId,
      web_visitor_id: shareState.websiteVisitorId,
    })))
  }

  const buttonProps = {
    className: classnames(styles.acca_button),
    src: new AccaImageRequestParams(),
    onClick: handleOpen
  }

  return (

    <div className={styles.button_container}>
      {
        shouldRenderACCAButton && !isLoginPage && !shareState.wizard.isVisible ? <Image {...buttonProps}/> : <div className={styles.button_container}/>
      }
    </div>
  )
});

