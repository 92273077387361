import {BaseModel} from "../../models/base";

export class NativeFunctionsUtils extends BaseModel {
  async callFunction(name = "", params = {}) {
    const stringParams = JSON.stringify(params);
    
    console.log(name, stringParams);

    if (window.webkit?.messageHandlers) {
      window.webkit?.messageHandlers[name]?.postMessage(stringParams);
    }

    if (typeof window.Bolao_Android != "undefined") {
      const returnValue = (window.Bolao_Android[name])?.(stringParams);

      return returnValue;
    }

    return null;
  }
}
