export class CookieUtils {

  static injectables = [];

  getCookieByName(cookieName) {
    const nameEQ = cookieName + "=";
    const ca = document.cookie?.split(';');
    for (let i = 0; i < ca?.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

   setCookie(name, value, expireDays) {
    const d = new Date();
    d.setTime(d.getTime() + (expireDays*24*60*60*1000));
    const expires = "expires="+ d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/;domain=."+window.location.host;
  }

  getCookiesBySubstring(substring) {
    const ca = document.cookie?.split(';');
    const cookieDict = ca?.map(item => item.split(/=(.*)/)?.filter(cell => cell !== ''))
    const caFiltered = cookieDict?.filter(item => item[0]?.includes(substring))

    return caFiltered;
  }
}

export const cookieUtils = new CookieUtils();
