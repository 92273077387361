import {ProfileHeader} from "../../profile-header/profile-header";
import {NavigationBarButtonsDesktop} from "./navigation-bar.buttons.desktop";
import {shareState} from "../../../app/share-state";
import {observer} from "mobx-react";
import {NavigationBarDesktopButtonsLinks} from "./navigation-bar.desktop.buttons-links";

export const NavigationBarDesktop = observer(({controller}) => {
  const profileProps = {
    user: shareState.user,
  }

  return (
    <>
      <ProfileHeader {...profileProps}/>
      <NavigationBarButtonsDesktop controller={controller}/>
      <NavigationBarDesktopButtonsLinks/>
    </>
  );
});

