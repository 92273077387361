import {shareState} from "../../../app/share-state";
import {GameWidgetInputModel} from "../../games-widget/models/games.widget.game-input-model";
import {GameModel} from "../../../models/game/game";
import {AddonModel} from "../../../models/addon";
import {COLORS} from "../../../styles/colors";
import {computed, makeObservable, observable, override} from "mobx";

export class HomeWidgetGameModel extends GameWidgetInputModel {
  @observable selection;

  constructor(model: GameModel, addon: AddonModel, selection: Selection) {
    super(model, addon);

    this.selection = selection;

    makeObservable(this)
  }

  @override get bottomStatus() {
    if (this.isLive || this.isEnded) {
      return shareState.termsUtils.terms.WC_2022_POINTS;
    }

    return `${this.formatDate(this.startTime)} - ${this.formatTime(this.startTime)}`;
  }

  @computed get ctaText(): Number {
    if(!this.isBetsFulfilled) {
      return shareState.termsUtils.terms.BOLAO_CTA_EXACT_SCORE_BEFORE_ENTER_PREDICTION_BUTTON
    }

    const predictionTextTerm = {
      1: shareState.termsUtils.terms.BOLAO_CTA_EXACT_SCORE,
      126: shareState.termsUtils.terms.BOLAO_CTA_EXACT_SCORE
    }

    const predictionText = predictionTextTerm[this.selection?.line.lineType.id]?.format({
      "CURRENCY": shareState.settings.currencySign,
      "AMOUNT": shareState.settings.betValue,
      "TOTAL_AMOUNT": this.selection?.totalAmount,
    }).colorSubString(`${this.selection?.line?.bookmaker?.color || COLORS[shareState.theme].primary}`)

    return predictionText
  }

  @computed get predictionText(): Number {
    if(!this.isBetsFulfilled) {
      return shareState.termsUtils.terms.BOLAO_CTA_EXACT_SCORE_BEFORE_ENTER_PREDICTION_HEADER;
    }

    const predictionTextTerm = {
      1: this.isDrawBet ? shareState.termsUtils.terms.BOLAO_FULL_TIME_RESULT_DRAW: shareState.termsUtils.terms.BOLAO_FULL_TIME_RESULT_TO_WIN,
      126: shareState.termsUtils.terms.BOLAO_CTA_EXACT_SCORE_SURE_ABOUT
    }

    const homeScore: Number = this.selection?.line.parameterValues ? this.selection?.line.parameterValues[0] : this.bets[0].score;
    const awayScore: Number = this.selection?.line.parameterValues ? this.selection?.line.parameterValues[1] : this.bets[1].score;


    const predictionText = predictionTextTerm[this.selection?.line.lineType.id]?.format({
      "GAME_RESULT": `${homeScore}-${awayScore}`,
      "COMPETITOR_NAME": this.homeToWinBet ? this.competitors[0].name : this.competitors[1].name
    }).colorSubString(`${COLORS[shareState.theme].text}`)

    return predictionText
  }

  @computed get shouldShowAddon(): Boolean {
    return shareState.isExactScoreAvailable && (!this.isBetsFulfilled || this.selection)
  }

  @computed get isDrawBet(): Boolean {
    return this.bets[0].score === this.bets[1].score
  }

  @computed get homeToWinBet(): Boolean {
    return this.bets[0].score > this.bets[1].score
  }
}
