import {KinesisEvent} from "../../kinesis.event";
import {KINESIS} from "../../../kinesis.consts";

export class AccaPredictionFormClickKinesisEvent extends KinesisEvent {
  constructor(properties) {
    super(
      KINESIS.CATEGORY.bolao,
      KINESIS.ACTION.acca,
      KINESIS.LABEL.predictionForm,
      KINESIS.VALUE.click,
      properties
    );
  }

  getProperties(params) {
    const props = super.getProperties()

    return {
      ...props,
      app_device_id: params.app_device_id || null,
      web_visitor_id: params.web_visitor_id || null,
    }
  }
}
