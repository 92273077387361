import {BaseModel} from "../base";

export class Rate extends BaseModel {
  num: Number;
  rate: Number;
  fractional: String;
  american: String;
  oldRate: Number;

  constructor(model) {
    super(model);

    this.num = model.Num;
    this.rate = model.Rate?.toFixed(2);
    this.fractional = model.Fractional;
    this.american = model.American;
    this.oldRate = model.OldRate;
  }

  get isTrendUp() {
    return this.rate > this.oldRate
  }

  get isTrendDown() {
    return this.rate < this.oldRate
  }

}