import {ImageRequestParams} from "../models/image/image-request-params";
import {COLORS} from "../styles/colors";
import {shareState} from "../app/share-state";

export class CompetitionPageSectionRequestParams extends ImageRequestParams {
  constructor(width: Number, height: Number, id: String, color = COLORS[shareState.theme].text) {
    super({width, height, color});

    this._resourceName = `bolao24/Icons/${id}`;
    this.ratio = 3;
    this.c = null;
  }
}
