export const LANGUAGES = [
  {
    id: 1,
    dir: 'ltr',
    name: 'English',
    displayName: '',
    cultureName: 'en',
  },
  {
    id: 2,
    dir: 'rtl',
    name: 'עברית',
    displayName: 'he',
    cultureName: 'he-il',
    displayInCountries: [6]
  },
  {
    id: 31,
    dir: 'ltr',
    name: 'Português (Brasil)',
    displayName: 'pt-br',
    cultureName: 'pt-br',
  },
  {
    id: 29,
    dir: 'ltr',
    name: "Español (Latinoamérica)",
    displayName: 'es',
    cultureName: 'es-es',
  },
  {
    id: 27,
    dir: 'rtl',
    name: 'العربية',
    displayName: 'ar',
    cultureName: 'ar',
  }
];
