import {makeObservable, observable} from "mobx";
import {DeltaRequestModel} from "../models/request/delta-request";

export class WidgetState {
  @observable activeRequests: Array<DeltaRequestModel>;
  @observable dataSource;
  @observable isLoading;

  constructor() {
    makeObservable(this)

    this.activeRequests = []
  }
}