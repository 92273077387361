import {RequestModel} from "../../../request";
import {REQUEST} from "../../../requests.consts";
import {shareState} from "../../../../../app/share-state";

export class PostRequestSetUserSettingsModel extends RequestModel {
  constructor({userImage, userName, masterGroupId, userAgreement, userAgreementPopupDisplayed}) {
    super(REQUEST.CATEGORY.users, REQUEST.ACTION.USERS.setUserSettings, {}, true, false, true, true);

    this.apiEnvironment = shareState.environment.baseUrl;
    this.method = REQUEST.METHOD_TYPE.post;
    this.userName = userName;
    this.userImage = userImage;
    this.masterGroupId = masterGroupId;
    this.userAgreement = userAgreement;
    this.userAgreementPopupDisplayed = userAgreementPopupDisplayed;
  }

  get body() {
    const name = this.userName ? {name: this.userName} : {}
    const base64ProfileImage = this.userImage ? {base64ProfileImage: this.userImage} : {}
    const masterGroupId = this.masterGroupId ? {mgID: this.masterGroupId} : {}
    const userAgreement = this.userAgreement ? {userAgreement: this.userAgreement} : {}
    const userAgreementPopupDisplayed = this.userAgreementPopupDisplayed ? {userAgreementPopupDisplayed: this.userAgreementPopupDisplayed} : {}

    return {...name, ...base64ProfileImage, ...masterGroupId, ...userAgreement, ...userAgreementPopupDisplayed};
  }
}
