import {observer} from 'mobx-react';
import {Button} from "../../../components/button/button";
import styles from '../styles/games-widget.module.scss';
import classNames from "classnames";

export const GamesWidgetAutoFillButton = observer(({controller, className}) => {
  const {state} = controller;
  const {shouldRenderAutoFillButton, autoFillLabel} = state;

  const autoFillProps = {
    onClick: () => controller.getRandomizeBets(),
    label: autoFillLabel,
    className: classNames(className, styles.auto_fill),
  }

  return shouldRenderAutoFillButton ?
    <Button {...autoFillProps}/> : null
});
