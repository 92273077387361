import {BaseModel} from "../base";

export class GameSelectionModel extends BaseModel {
  home: Number;
  away: Number;

  constructor(model) {
    super(model);

    this.home = model.team1;
    this.away = model.team2;
  }
}