import classNames from "classnames";

export const DropdownArrow = ({isOpen, onClick, styles}) => {
  const arrowDownProps = {
    className: classNames(styles.arrow, isOpen ? styles.arrow_up : styles.arrow_down),
    onClick: () => onClick && onClick()
  }

  return (
     <div {...arrowDownProps}/>
  );
};
