import {WidgetState} from "../widgets/widget.state";
import {computed, makeObservable, observable} from "mobx";
import {WizardWidgetPageModel} from "../widgets/wizard-widget/models/wizard-widget.page.model";
import {APP_CONST} from "../consts";
import {shareState} from "./share-state";

export class AppState extends WidgetState {
  @observable userAgreementsValue;
  @observable isLoadingApp;
  @observable userAgreementsPromise: Promise;
  constructor() {
    super();

    this.userAgreementsValue = false;
    this.isLoadingApp = true;

    makeObservable(this);
  }
  @computed get shouldRenderWizardButton(): Boolean {
    return shareState.isMobileView && shareState.wizard.isVisible
  }
  @computed get shouldRenderACCAButton(): Boolean {
    return !shareState.wizard.isVisible && shareState.isMobileView && shareState.isACCAActive;
  }

  @computed get currentPageModel(): WizardWidgetPageModel {
    return shareState.wizard.currentPageModel
  }

  @computed get contentPageOffset(): Number {
    const wizardOffset: Number = shareState.isMobileView ? APP_CONST.ELEMENT_SIZE.navigationBar : APP_CONST.ELEMENT_SIZE.desktopHeader;
    const mobileOffset: Number = APP_CONST.ELEMENT_SIZE.navigationBar + APP_CONST.ELEMENT_SIZE.mobileHeader;
    const desktopOffset: Number = APP_CONST.ELEMENT_SIZE.desktopHeader;
    const offset: Number = shareState.wizard.isVisible ? wizardOffset : (shareState.isMobileView ? mobileOffset : desktopOffset);

    return offset
  }

  @computed get shouldRenderBrGameBadge() {
    return shareState.environment.name === APP_CONST.GAME_ENVIRONMENT.brazil
  }

  @computed get shouldRenderLeagueNavigationButton(): Boolean {
    return shareState.settings.featuresMap.get(APP_CONST.FEATURES.leagues);
  }
}
