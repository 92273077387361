import classNames from "classnames";
import styles from "../profile-header.module.scss";
import {ProfileHeaderButton} from "./profile-header.button";
import {SettingsPopup} from "../../settings-button/settings.popup";
import {observer} from "mobx-react";
import {shareState} from "../../../app/share-state";
import {useCurrentPath} from "../../../hooks/use-current-path";
import {ProfileHeaderButtonTopScorerModel} from "../models/profile-header.button.top-scorer.model";
import {ProfileHeaderButtonWinningTeamModel} from "../models/profile-header.button.winning-team.model";

export const ProfileHeaderButtons = observer(({user, isOtherUser}) => {
  const {pathName} = useCurrentPath();
  const topScorerModel: ProfileHeaderButtonTopScorerModel = new ProfileHeaderButtonTopScorerModel(user, pathName, isOtherUser);
  const winnerTeamModel: ProfileHeaderButtonWinningTeamModel = new ProfileHeaderButtonWinningTeamModel(user, pathName, isOtherUser);

  return (
    <div className={classNames(styles.header_buttons)}>
      <ProfileHeaderButton model={winnerTeamModel}/>
      <ProfileHeaderButton model={topScorerModel}/>
      {
        !isOtherUser && !shareState.wizard.isVisible ? <SettingsPopup shouldRenderSettingsButton={!shareState.isMobileView}/> : null
      }
    </div>
  );
});

