import {useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import classNames from "classnames";
import styles from "./modal.module.scss";

export const Modal = (props) => {
  const container = useRef(document.createElement('div'));
  const modalRoot = document.getElementById('bolao-modal-root');

  useEffect(() => {
      modalRoot.appendChild(container.current);
      modalRoot.classList.add(classNames(styles.show));
      modalRoot.classList.add(classNames(styles.modal_root));
      container.current.classList.add(classNames(styles.modal_container));
      document.body.style.overflow = 'hidden';

      return () => {
        modalRoot.classList.remove(classNames(styles.show));
        modalRoot.classList.remove(classNames(styles.modal_root));
        modalRoot.removeChild(container.current);
        document.body.style.overflow = null;
      }
    }, []
  )

  return ReactDOM.createPortal(props.children, container.current);
}
