import {BaseModel} from "../../models/base";
import {RequestModel} from "../../models/request/request";
import {DeltaRequestModel} from "../../models/request/delta-request";

export class FetchUtils extends BaseModel {
  async sendGetRequest(requestModel: RequestModel, onSuccess: Function, onFailure: Function) {
    return this.executeFetch(requestModel, onSuccess, onFailure).then((response) => {
      onSuccess(response);

      requestModel.startDelta && this.startDelta(requestModel, onSuccess, onFailure);

      return response;
    });
  }

  async executeFetch(requestModel: RequestModel, onSuccess: Function, onFailure: Function) {
    return fetch(requestModel.fullUrl, requestModel.headers)
      .then(res => res.json())
      .catch(error => onFailure && onFailure(error));
  }

  async sendPostRequest(requestModel: RequestModel, onSuccess: Function, onFailure: Function) {
    const {fullUrl, method, headers, body} = requestModel;

    return fetch(fullUrl, {method, headers, body: JSON.stringify(body)})
      .then(async res => res.json())
      .then((data) => onSuccess && onSuccess(data))
      .catch(error => onFailure && onFailure(error));
  }

  startDelta(deltaRequest: DeltaRequestModel, onSuccess: Function) {
    deltaRequest.intervalId = setInterval(async () => {
      const response = await this.executeFetch(deltaRequest, onSuccess);

      onSuccess(response);
    }, deltaRequest.interval);
    window.bolaoIntervalsIds.push(deltaRequest.intervalId)
    console.log(`deltaRequest interval: ${deltaRequest.intervalId} started in ${this.constructor.name}`);
  }

  stopDelta(deltaRequest: DeltaRequestModel) {
    if (window.bolaoIntervalsIds.includes(deltaRequest.intervalId)) {
      clearInterval(deltaRequest.intervalId);
      deltaRequest.intervalId = null;
      window.bolaoIntervalsIds = window.bolaoIntervalsIds.filter(id => id !== deltaRequest.intervalId);
      console.log(`deltaRequest interval: ${deltaRequest.intervalId} clear successfully from ${this.constructor.name}`);
    } else {
      console.log(`deltaRequest interval: ${deltaRequest.intervalId} not found in ${this.constructor.name}`);
    }

    return true;
  }
}
