import {WidgetController} from "../../controllers/widget.controller";
import {makeObservable, observable} from 'mobx';
import {BaseModel} from "../../models/base";

class VisibleWidgetsManager extends BaseModel {
  @observable controllers: Array<WidgetController>;

  constructor() {
    super()

    this.controllers = []

    makeObservable(this)
  }

  set(controller: WidgetController) {
    if (!this.controllers.find((existController: WidgetController) => controller.constructor.name === existController.constructor.name)) {
      this.controllers.push(controller);
    }
  }

  remove(controllerType) {
    this.controllers = this.controllers.filter(controller => !(controller.constructor.name === controllerType.constructor.name))
  }

  update(currentController) {
    this.controllers?.forEach(async (controller: WidgetController) => {
      const isCurrentController: Boolean = controller.constructor.name === currentController.constructor.name;
      const isAppController: Boolean = controller.constructor.name === 'AppController';

      if(!isCurrentController && !isAppController) {
        await controller.updateData()
      }
    });
  }

  updateData() {
    this.controllers?.forEach(async (controller: WidgetController) => controller.updateData());
  }
}

export const visibleWidgetsManager = new VisibleWidgetsManager()
