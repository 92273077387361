import classNames from "classnames";
import styles from "../profile-header.module.scss";
import {Image} from "../../image/image";
import {observer} from "mobx-react";

export const ProfileHeaderButtonIcon = observer(({iconType}) => {
  const iconProps = {
    className: classNames(styles.button_image_logo),
    src: new iconType(16, 16),
  }

  return (
    <div className={classNames(styles.header_edit_button)}>
      <Image {...iconProps}/>
    </div>
  );
});

