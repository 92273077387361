import {ImageRequestParams} from "../models/image/image-request-params";
export class PredictionImageRequestParams extends ImageRequestParams {
  constructor(width, height, color) {
    super({width, color});

    this._resourceName = `bolao24/Icons/Prediction_Yellow`;
    this.ratio = 3;
    this.c = null;
  }
}
