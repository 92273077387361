import classNames from "classnames";
import styles from "../selection-list-widget.module.scss";
import {shareState} from "../../../app/share-state";

export const SelectionListWidgetTitles = ({title, subtitle}) => {

  return (
    !shareState.isMobileView ? <div className={classNames(styles.titles)}>
      {title}
      <div className={classNames(styles.subtitle)}> {subtitle}</div>
    </div> : null
  );
};


