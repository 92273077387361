import {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import {APP_CONST, ROUTE_NAME} from "../consts";

export function useCurrentPath() {
  const location = useLocation();
  const [pathName, setPathName] = useState('');

  useEffect(() => {
    let currentPath = location.pathname;

    getPathName(currentPath);
  }, [location]);

  const getPathName = (currentPath) => {
    const routes = Object.values(APP_CONST.ROUTE);
    const currentRoute = routes.find((route: String) => `/${currentPath}`.includes(route));
    const currentPathName = currentPath === '' ? ROUTE_NAME[currentRoute] : ROUTE_NAME[currentRoute];

    setPathName(currentPathName)
  }

  return {pathName}
}
