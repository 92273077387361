import {useEventListener} from "../hooks/use-event-listener";
import {shareState} from "./share-state";
import {useState} from "react";

export const AppComposer = (Widget) => (props) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEventListener('resize', () => setWidth(window.innerWidth))

  shareState.isMobileView = width < 768;

  return <Widget {...props}/>;
}
