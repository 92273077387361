import styles from './dropdown.module.scss';

export const DropdownComposer = (Widget) => ({
                                               items,
                                               selectedItemId,
                                               onChange,
                                               customStyles,
                                               className,
                                               headerClassName,
                                               source,
                                               itemHeight = 58,
                                               hideHeaderLogo = false,
                                               logoIncluded = true,
                                               openToTop = false,
                                             }) => {
  const getFilteredItems = () => {
    const getFilteredItems = items?.filter(item => item.id !== selectedItemId);

    return getFilteredItems;
  }

  const getSelectedItem = () => {
    const getSelectedItem = items?.filter(item => item.id === selectedItemId)

    return getSelectedItem[0];
  }

  const newProps = {
    items: getFilteredItems(),
    selectedItem: getSelectedItem(),
    styles: {...styles, ...customStyles},
    onChange,
    logoIncluded,
    hideHeaderLogo,
    className,
    source,
    headerClassName,
    itemHeight,
    openToTop,
    isOneItemOnly: items.length === 1
  }

  return <Widget {...newProps}/>
}

