import classNames from "classnames";
import styles from "../styles/login.module.scss";
import {observer} from "mobx-react";
import {shareState} from "../../../app/share-state";

export const LoginWidgetUserAgreements = observer(({controller}) => {
  const {state: {userAgreementsValue, showErrorUnCheckedBoxMessage}} = controller;

  const checkboxProps = {
    type: "checkbox",
    checked: userAgreementsValue,
    className: classNames(styles.checkbox),
    onChange: (e) => controller.setUserAgreements(e.target.checked)
  }

  return (
    <div className={classNames(styles.user_agreements_container)}>
      <div className={classNames(styles.user_agreements)}>
        <input {...checkboxProps}/>
        <div>
          {
            shareState.termsUtils.terms.CONFIRMATION_NEWSLETTER
          }
        </div>
      </div>
      {
        showErrorUnCheckedBoxMessage ? (<div className={classNames(styles.error_message)}>
          {
            showErrorUnCheckedBoxMessage
          }
        </div>) : null
      }
    </div>
  )
});
