import classNames from "classnames";
import styles from '../../styles/settings-widget.module.scss';
import {observer} from "mobx-react";
import {BackButton} from "../../../../components/back-button/back-button";
import {APP_CONST} from "../../../../consts";
import {shareState} from "../../../../app/share-state";

export const SettingsWidgetGroupSettingsHeader = observer(({controller}) => {
  const {state} = controller;
  const {table} = state;
  const isDevEnvironment: Boolean = process.env.REACT_APP_ENVIRONMENT_TYPE === APP_CONST.API_ENVIRONMENT.dev;

  const buttonProps = {
    className: classNames(styles.delete_button),
    onClick: () => controller.deleteGroup(table.id),
  }

  const backProps = {
    className: styles.back,
    iconClassName: styles.back_icon,
    onBackClick: () =>  controller.closeEditMode(),
  }

  return (
    <div className={classNames(styles.header)}>
      <BackButton {...backProps}/>
      <div className={classNames(styles.subtitle)}>{shareState.termsUtils.terms.WC_2022_GROUP_MEMBERS}</div>
      {
        table.isAdmin && isDevEnvironment ? <div {...buttonProps}>Delete Group</div> : null
      }
    </div>
 )
});
