import classNames from "classnames";
import styles from '../acca-widget.module.scss';
import {observer} from "mobx-react";
import type {Selection} from "../../../models/bets/selection";
import {useGuid} from "../../../infrastructure/hooks/useGuid";
import {kinesisAnalyticsUtils} from "../../../analytics/kinesis/kinesis.analytics-utils";
import {
  AccaAccaFormBetNowClickKinesisEvent
} from "../../../analytics/kinesis/events/acca/acca-form/acca.acca-form.bet-now-click.kinesis-event";
import {shareState} from "../../../app/share-state";
import {KINESIS} from "../../../analytics/kinesis/kinesis.consts";
import type {Rate} from "../../../models/bets/rate";

export const AccaWidgetBetSlipBet = observer(({item}) => {
  const openBookieURL = useGuid();
  const {recommendation, rate, gameText, url, line: {lineType}}: Selection = item;
  const {isTrendDown, isTrendUp}: Rate = rate


  const containerProps = {
    className: classNames(styles.bet_container),
  }

  const onRateClick = () => {
    const {guid, bookieLink} = openBookieURL(url);

    kinesisAnalyticsUtils.send(new AccaAccaFormBetNowClickKinesisEvent(({
      app_device_id: shareState.nativeGameConfig?.deviceId,
      web_visitor_id: shareState.websiteVisitorId,
      bookie_id: shareState.settings?.topBookmakerId,
      guid,
      url: bookieLink,
      final_odds: rate.rate,
      click_type: KINESIS.CLICK_TYPE.odds,
    })))
  }


  return (
    <div {...containerProps}>
      <div className={classNames(styles.bet_text_top)}>
        {
          recommendation
        }
      </div>
      <div className={classNames(styles.bet_text_center)}>
        {
          gameText
        }
      </div>
      <div className={classNames(styles.bet_text_bottom)}>
        {
          lineType.title
        }
      </div>
      <div className={classNames(styles.rate_container)}>
        <div className={classNames(styles.rate, {[styles.up_trend]: isTrendUp, [styles.down_trend]: isTrendDown})} onClick={() => onRateClick()}>
          {
            rate.rate
          }
        </div>
      </div>
    </div>
  )
});
