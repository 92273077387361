import {BaseModel} from "../base";

export class GameSetSelection extends BaseModel {
  Team1: Number;
  Team2: Number;

  constructor(model) {
    super(model);

    this.Team1 = model.Team1;
    this.Team2 = model.Team2;
  }
}