import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {BaseComposer} from "../models/base.composer";
import {LoadingIndicator} from "../components/loading-indicator/loading-indicator";

export const WidgetComposer = (settings, Loader) => (Widget) => BaseComposer(settings)(observer((props) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);

    return () => {
      props.controller.onUnload();
    }
  }, []);

  useEffect(() => {
    if (loaded) {
      props.controller.onDidUpdate(props.controller.props, props);
    }
  }, [props]);


  return props.controller.state.isLoading ? (Loader ? <Loader /> : <LoadingIndicator />) : <Widget {...props} />
}));
