import {KinesisEvent} from "../kinesis.event";
import {KINESIS, ROUTE_INDEX} from "../../kinesis.consts";

export class MenuClickKinesisEvent extends KinesisEvent {
  constructor(properties) {
    super(
      KINESIS.CATEGORY.bolao,
      KINESIS.ACTION.menu,
      KINESIS.LABEL.click,
      null,
      properties
    );
  }

  getProperties(params) {
    const props = super.getProperties()

    return {
      ...props,
      selection: ROUTE_INDEX[params.source] //TODO: add influencers index
    }
  }
}
