import {DATE_FORMATS} from "../../date-formats";
import {DateTime, Info} from 'luxon';
import {DateFormat} from "../../models/date-format";

export class DatetimeUtils {
  datetime;
  dateFormats: DateFormat;
  cultureName: String;

  constructor() {
    this.datetime = DateTime;
    this.cultureName = 'en';
    this.dateFormats = null;
  }

  initialize(cultureName: String): void {
    const domainDateFormats: DateFormat = this.createDateFormatDomainObject(DATE_FORMATS[cultureName]);
    this.dateFormats = domainDateFormats;
    this.cultureName = cultureName;
  }

  createDateFormatDomainObject(apiModel): DateFormat {
    const shortTimePattern: String = apiModel.Use24Clock ? apiModel.ShortTimePattern24 : apiModel.ShortTimePattern12;
    const longTimePattern: String = apiModel.Use24Clock ? apiModel.LongTimePattern24 : apiModel.LongTimePattern12;
    const fullDateTimePattern: String = apiModel.Use24Clock ? apiModel.FullDateTimePattern24 : apiModel.FullDateTimePattern12;

    const domain: DateFormat = new DateFormat(apiModel, shortTimePattern, longTimePattern, fullDateTimePattern);

    return domain;
  }

  fromString(date: String = '', inputFormat: String = 'dd-MM-yyyy hh:mm'): Date {
    const luxonDatetime = this._toLuxonDatetimeObject(date, false, inputFormat);
    const dateObject: Date = this.toDateObject(luxonDatetime);

    return dateObject;
  }

  toISO(date) {
    return date && date.toISOString();
  }

  fromISO(isoString: String = new Date().toISOString()): Date {
    const dateObject: Date = new Date(isoString);

    return dateObject;
  }

  _addOffsetToLuxonDatetime(luxonDatetime: DateTime): Object {
    const localOffset = this.datetime.local().offset * 60000;
    const newLuxonDatetime = luxonDatetime.plus({milliseconds: localOffset});

    return newLuxonDatetime;
  }

  _toLuxonDatetimeObject(date: Date = new Date(), useOffset: Boolean = false, inputFormat: String, ignoreLocale: Boolean = false): Object {
    const dateTest = inputFormat ? this.datetime.fromFormat(date, inputFormat) : this.datetime.fromISO(date.toISOString());
    const luxonDatetime = dateTest.setLocale(ignoreLocale ? 'en' : this.cultureName);
    const luxonDatetimeWithOffset: DateTime = useOffset ? this._addOffsetToLuxonDatetime(luxonDatetime) : luxonDatetime;

    return luxonDatetimeWithOffset;
  }

  _toFormat(date: Date = new Date(), useOffset: Boolean = false, format: String, ignoreLocale: Boolean = false): String {
    const luxonDatetime = this._toLuxonDatetimeObject(date, useOffset, null, ignoreLocale);
    const result: String = luxonDatetime.toFormat(format);

    return result;
  }

  toMillisecondes(date: Date =  new Date()) {
    return date.getTime();
  }

  toDateObject(date: DateTime = this._toLuxonDatetimeObject()): Date {
    const dateObject: Date = new Date(date.valueOf());

    return dateObject;
  }

  toURLDate(date: Date = new Date(), useOffset: Boolean = false): String {
    const result: String = this._toFormat(date, useOffset, 'yyyy-MM-dd', true);

    return result;
  }

  toShortDate(date: Date = new Date(), useOffset: Boolean = false): String {
    const result: String = this._toFormat(date, useOffset, this.dateFormats.shortDatePattern);

    return result;
  }

  toShortestDate(date: Date = new Date(), useOffset: Boolean = false): String {
    const result: String = this._toFormat(date, useOffset, this.dateFormats.shortestDatePattern);

    return result;
  }

  toShortTime(date: Date = new Date(), useOffset: Boolean = false): String {
    const result: String = this._toFormat(date, useOffset, this.dateFormats.shortTimePattern);

    return result;
  }

  toShortNameMonth(date: Date = new Date(), useOffset: Boolean = false): String {
    const result: String = this._toFormat(date, useOffset, this.dateFormats.shortMonthNamePattern);

    return result;
  }

  toShortNamedDate(date: Date = new Date(), useOffset: Boolean = false): String {
    const result: String = this._toFormat(date, useOffset, this.dateFormats.desktopPickerPattern);

    return result;
  }

  toLongNamedDate(date: Date = new Date(), useOffset: Boolean = false): String {
    const result: String = this._toFormat(date, useOffset, 'cccc, MMMM d');

    return result;
  }

  toShortNamedDay(date: Date = new Date(), useOffset: Boolean = false): String {
    const result: String = this._toFormat(date, useOffset, this.dateFormats.shortDayNamedPattern);

    return result;
  }

  toShortDatetime(date: Date = new Date(), useOffset: Boolean = false): String {
    const result: String = this._toFormat(date, useOffset, this.dateFormats.shortDatePattern + ' ' + this.dateFormats.shortTimePattern);

    return result;
  }

  toServerDate(date: Date = new Date(), useOffset: Boolean = false): String {
    const result: String = this._toFormat(date, useOffset, 'dd/MM/yyyy', true);

    return result;
  }

  addDays(datetime: Date = new Date(), days: Number = 0): Date {
    const luxonDatetime = this._toLuxonDatetimeObject(datetime);
    const newDate = luxonDatetime.plus({days});
    const dateObject: Date = this.toDateObject(newDate);

    return dateObject;
  }

  addYears(datetime: Date = new Date(), years: Number = 0): Date {
    const luxonDatetime = this._toLuxonDatetimeObject(datetime);
    const newDate = luxonDatetime.plus({years});
    const dateObject: Date = this.toDateObject(newDate);

    return dateObject;
  }

  isToday(date): Boolean {
    const isToday = this.diffDaysByDate(date, new Date()) === 0;

    return isToday;
  }

  isTomorrow(date): Boolean {
    const isTomorrow = this.diffDaysByDate(date, new Date()) === 1;

    return isTomorrow;
  }

  isYesterday(date): Boolean {
    const isYesterday = this.diffDaysByDate(date, new Date()) === -1;

    return isYesterday;
  }

  getDaysFromToday(date: Date = new Date()): Number {
    const luxonCompareDate = this._toLuxonDatetimeObject(date);
    const diffDays: Number = luxonCompareDate.diff(this.datetime.local(), 'days').days;

    return diffDays;
  }

  diffDaysByDate(firstDate: Date = new Date(), secDate: Date = new Date()): Number {
    const cloneDate: Date = new Date(firstDate);
    const currentDate: Date = new Date(cloneDate.setHours(0, 0, 0, 0));
    const luxonCompareDate = this._toLuxonDatetimeObject(currentDate);
    const luxonCurrentDate = this._toLuxonDatetimeObject(new Date(secDate.setHours(0, 0, 0, 0)));
    const diffDays: Number = luxonCompareDate.diff(luxonCurrentDate, 'days').days;

    return diffDays;
  }

  diffSecondsByDate(firstDate: Date = new Date(), secDate: Date = new Date()): Number {
    const luxonCompareDate = this._toLuxonDatetimeObject(firstDate);
    const luxonCurrentDate = this._toLuxonDatetimeObject(secDate);
    const diffSeconds: Number = luxonCompareDate.diff(luxonCurrentDate, 'seconds').seconds;

    return diffSeconds;
  }

  getLocaleObject(): Object {
    const locale: String = this.cultureName;
    const localeObject: Object = {
      locale,
      months: Info.months('long', {locale}),
      weekdaysLong: this.weekdaysLong,
      weekdaysShort: this.weekdaysShort,
      firstDayOfWeek: this.dateFormats.firstDayOfWeek || 0,
    }

    return localeObject;
  }

  diff(firstDate: Date = new Date(), lastDate: Date = new Date()) {
    const luxunFirstDatetime = datetimeUtils._toLuxonDatetimeObject(firstDate);
    const luxunLastDatetime = datetimeUtils._toLuxonDatetimeObject(lastDate);
    const diffTime = luxunFirstDatetime.diff(luxunLastDatetime, 'milliseconds');

    return diffTime;
  }

  isDateInRange(date: Date, first: Date, last: Date): Boolean {
    if(!date || !first || !last) {
      return false;
    }

    const diffDaysFromFirst = this.diffDaysByDate(first, date);
    const diffDaysFromLast = this.diffDaysByDate(date, last);

    return diffDaysFromFirst < 0 && diffDaysFromLast < 0;
  }

  get weekdaysShort(): Array<String> {
    const weekdaysFromMonday: Array<String> = [...(Info.weekdays('short', {locale: this.cultureName}))];
    const weekdaysFromSunday: Array<String> = weekdaysFromMonday.rotate(-1);

    return weekdaysFromSunday;
  }

  get weekdaysLong(): Array<String> {
    const weekdaysFromMonday: Array<String> = [...(Info.weekdays('long', {locale: this.cultureName}))];
    const weekdaysFromSunday: Array<String> = weekdaysFromMonday.rotate(-1);

    return weekdaysFromSunday;
  }

  addMinutes(datetime: Date = new Date(), minutes: Number = 0): Date {
    const luxonDatetime = this._toLuxonDatetimeObject(datetime);
    const newDate = luxonDatetime.plus({minutes});
    const dateObject: Date = this.toDateObject(newDate);

    return dateObject;
  }
}


export const datetimeUtils = new DatetimeUtils();
