import firebase from 'firebase/compat/app';
import {ARGS} from "../../args";

const firebaseConfig = {
  apiKey: ARGS.REACT_APP_FIREBASE_API_KEY,
  authDomain: ARGS.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: ARGS.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: ARGS.REACT_APP_FIREBASE_STORAGE_BUCKET,
  appId: ARGS.REACT_APP_FIREBASE_APP_ID,
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);

//  apiKey: "AIzaSyCvlg6-Oj8alRUKIsTbTRlmFgkaMagVD7U",
//   authDomain: "scores-bolao.firebaseapp.com",
//   projectId: "scores-bolao",
//   storageBucket: "scores-bolao.appspot.com",
//   messagingSenderId: "303230937238",
//   appId: "1:303230937238:web:12fb576ed07a747405bab7",
//   measurementId: "G-H4YK7GKHHP"