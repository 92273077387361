import {observable, makeObservable} from 'mobx';
import {BaseModel} from "./base";

export class CollapsedItemModel extends BaseModel {
  @observable open: Boolean;
  title: String;

  constructor(open: Boolean, title: String) {
    super();

    makeObservable(this);

    this.open = open;
    this.title = title;
  }
}
