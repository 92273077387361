import {BaseModel} from "./base";

const DATE_DESKTOP_PICKER_FORMAT = 'ccc, MMM dd';
const SHORT_DAY_NAMED = 'ccc';
const SHORT_MONTH_NAME = 'MMM';

export class DateFormat extends BaseModel {
  shortDatePattern: String;
  shortestDatePattern: String;
  shortTimePattern: String;
  longTimePattern: String;
  fullDateTimePattern: String;
  firstDayOfWeek: Number;
  use24Clock: Boolean;
  desktopPickerPattern: String;
  shortDayNamedPattern: String;
  shortMonthNamePattern: String;

  constructor(model: DateFormat ,shortTimePattern: String, longTimePattern: String, fullDateTimePattern: String) {
    super();

    this.shortDatePattern = model.ShortDatePattern;
    this.shortestDatePattern = model.ShortestDatePattern;
    this.shortTimePattern = shortTimePattern;
    this.longTimePattern = longTimePattern;
    this.fullDateTimePattern = fullDateTimePattern;
    this.firstDayOfWeek = model.FirstDayOfWeek;
    this.use24Clock = model.Use24Clock;
    this.desktopPickerPattern = DATE_DESKTOP_PICKER_FORMAT;
    this.shortDayNamedPattern = SHORT_DAY_NAMED;
    this.shortMonthNamePattern = SHORT_MONTH_NAME;
  }
}
