import classNames from "classnames";
import styles from "../selection-list-widget.module.scss";
import {SelectionListWidgetItemCheckbox} from "./selection-list.widget.item-checkbox";
import {shareState} from "../../../app/share-state";

export const SelectionListWidgetItemScore = ({item, entityModel, isActive}) => {
  const selectedProps = {
    selectedClassName: shareState.user.allowTournamentBets ? styles.item_score_logo : styles.selected_item_score_logo,
    entityModel,
    isActive
  }

  return (
    <div className={classNames(styles.item_data)}>
      {
        isActive ? <SelectionListWidgetItemCheckbox {...selectedProps}/> : null
      }
      <div className={classNames(styles.item_score)}>{item.score}</div>
    </div>
  );
};


