import classNames from "classnames";
import styles from '../../styles/settings-widget.module.scss';
import {observer} from "mobx-react";
import {Image} from "../../../../components/image/image";
import {EditImageRequestParams} from "../../../../icons/edit";
import {LeaveGroupImageRequestParams} from "../../../../icons/leave-group";
import {kinesisAnalyticsUtils} from "../../../../analytics/kinesis/kinesis.analytics-utils";
import {SettingsEditGroupClickKinesisEvent} from "../../../../analytics/kinesis/events/settings/settings.edit-group.click.kinesis-event";

export const SettingsWidgetIcons = observer(({controller, group, onLeaveClick}) => {
  const {preventLeave, isAdmin} = group;

  const leaveProps = {
    className: classNames(styles.icon),
    src: new LeaveGroupImageRequestParams(20, 20),
    onClick: () => onLeaveClick && onLeaveClick()
  }

  const editProps = {
    className: classNames(styles.icon),
    src: new EditImageRequestParams(20,20),
    onClick: () => {
      controller.getGroupById(group.id)
      kinesisAnalyticsUtils.send(new SettingsEditGroupClickKinesisEvent({groupId: group.id}))
    }
  }

  return (
    isAdmin ? <Image {...editProps}/> : !preventLeave ? <Image {...leaveProps}/> : null
  )
});