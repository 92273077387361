import {REQUEST} from "../../../requests.consts";
import {RequestModel} from "../../../request";

export class GetRequestLocaleModel extends RequestModel {
  constructor() {
    super(REQUEST.CATEGORY.data, REQUEST.ACTION.DATA.localdata, {}, false, true, false, false);

    this.method = REQUEST.METHOD_TYPE.get;
    this.apiEnvironment = `https://${this.getEnv()}.${this.domain}`
  }

  getEnv() {
    let apiType: String = process.env.REACT_APP_API_ENV;

    return apiType;
  }
}
