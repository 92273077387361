import {RequestModel} from "../../../request";
import {REQUEST} from "../../../requests.consts";
import {GameSetBet} from "../../../../game/game.set-bet";
import {shareState} from "../../../../../app/share-state";

export class PostRequestSetGameBetsModel extends RequestModel {
  bets: Array<GameSetBet>;

  constructor(bets: Array<GameSetBet>) {
    super(REQUEST.CATEGORY.bets, REQUEST.ACTION.BETS.setGameBets, {}, true, false, true, true);

    this.apiEnvironment = shareState.environment.baseUrl;
    this.method = REQUEST.METHOD_TYPE.post;
    this.bets = bets
  }

  get body() {
    const params = this.bets.length > 0 ? {bets: this.bets} : {}

    return {...params};
  }
}
