import {BaseModel} from "./base";
import {LanguageImageRequestParams} from "../icons/language";

export class LanguageModel extends BaseModel {
  name: String;
  dir: String;
  displayName: String;
  cultureName: String;
  displayInCountries: String;

  constructor(model) {
    super(model);

    this.name = model.name;
    this.dir = model.dir
    this.displayName = model.displayName;
    this.cultureName = model.cultureName;
    this.displayInCountries = model.displayInCountries;
  }

  get imageURL(): LanguageImageRequestParams {
    return new LanguageImageRequestParams(this.id)
  }
}
