export const APP_CONST = {
  PLATFORMS: {
    ios: 1,
    android: 2,
    web: 5
  },

  FEATURES: {
    leagues: 'LEAGUES',
    exactScore: 'EXACT_SCORE',
    acca: 'ACCA'
  },

  website: 'https://www.365scores.com',
  defaultImageGroup: 'https://imagecache.365scores.com/image/upload/q_auto:eco/default.png',

  TERMS: {
    category: 'WC2022'
  },

  API_ENVIRONMENT: {
    prod: 'production',
    dev: 'development'
  },

  GAME_ENVIRONMENT: {
    global: 'global',
    israel: 'il',
    brazil: 'br',
    latam: 'latam',
    arabic: 'ar'
  },

  DEVICE_ORIENTATIONS: {
    portrait: 'portrait',
    landscape: 'landscape',
  },

  ENTITY_TYPE: {
    group: 'group',
    league: 'league'
  },

  LANGUAGES_IDS: {
    en: 1,
    he: 2,
    en_us: 9,
    ar: 27,
    es: 29,
    pt_br: 31,
  },

  ELEMENT_SIZE: {
    desktopHeader: 120,
    mobileHeader: 56,
    navigationBar: 56
  },

  ROUTE: {
    wizard: '/wizard',
    topScorer: '/top-scorer',
    winningTeam: '/winning-team',
    prediction: '/prediction',
    leaderboard: '/leaderboard',
    leagues: '/leagues',
    rules: '/rules',
    home: '/',
  },

  KEY_CODES: {
    escape: 27,
    left: 37,
    up: 38,
    right: 39,
    down: 40,
    enter: 13,
  },

  GAME_STATUS: {
    schedule: 1,
    live: 2,
    ended: 3,
  },

  LOCAL_STORAGE_KEYS: {
    siteLangId: 'langId',
    siteCountryId: 'userCountryId',
    langId: 'bolao_langId',
    theme: 'bolao_theme',
    userToken: 'bolao_ut',
    userAuthorizationToken: 'bolao_uat',
    wizardDisplay: 'bolao_wizardDisplay',
    groupIdInvitation: 'bolao_groupIdInvitation',
    countryId: 'bolao_countryId',
    deviceId: 'bolao_deviceId',
    userAgreements: 'bolao_userAgreements',
    baseUrl: 'bolao_baseUrl',
    envName: 'bolao_envName',
    apiType: 'apiType',
    kinesisPartitionKey: 'kinesisPartitionKey',
    sessionVisitId: 'sessionVisitId',
    signedInWithRedirect: 'signedInWithRedirect'
  },

  IMAGE_EXTENSION: {
    jpg: 'image/jpg',
    jpeg: 'image/jpeg',
    png: 'image/png',
  },

  COMPETITION_PAGES_BUTTON_IDS: {
    stats: 'Stats_link',
    standings: 'Groups_link',
    brackets: 'Brackets_link'
  }
}

export const ROUTE_NAME = {
  [APP_CONST.ROUTE.home]: 'Home',
  [APP_CONST.ROUTE.wizard]: 'Wizard',
  [APP_CONST.ROUTE.topScorer]: 'Top Scorer',
  [APP_CONST.ROUTE.winningTeam]: 'Winning Team',
  [APP_CONST.ROUTE.prediction]: 'Prediction',
  [APP_CONST.ROUTE.leaderboard]: 'Leaderboard',
  [APP_CONST.ROUTE.leagues]: 'leagues',
  [APP_CONST.ROUTE.rules]: 'Rules',
};

export const COMPETITION_PAGES = {
  standings: 'standings',
  brackets: 'brackets',
  stats: 'stats'
};

export const EMPTY_SCORE = -1;

export const GA4_COOKIE = {
  PARAMS: {
    CLIENT_ID: '$GA_CLIENT',
    SESSION_ID: '$GA4_SESSION'
  },

  KEYS: {
    CLIENT_ID: '_ga',
    SESSION_ID: '_ga_RLK',
  }
}

export const GUID = {
  PARAMETER: '$GUID',
  LENGTH: 32,
  CHARS: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
}
export const DEVICE = {
  PARAMETER: '$DeviceHashedId',
}