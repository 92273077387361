import {action, makeObservable, observable} from "mobx";

import {BaseModel} from "./base";
import {UserModel} from "./user";

export class TableModel extends BaseModel {
  id: Number;
  name: String;
  imageURL: String;
  invitationLink: String;
  isAdmin: Boolean;
  @observable members: Array<UserModel>;
  @observable membersCount: Number;

  constructor(model) {
    super(model);

    makeObservable(this);

    this.id = model.groupID;
    this.name = model.name;
    this.membersCount = model.membersCount;
    this.imageURL = model.imageURL.replace('http://', 'https://').replace('imagescache', 'imagecache');
    this.invitationLink = model.invitationLink;
    this.isAdmin = model.isAdmin;
    this.members = model.members.map((member: UserModel) => new UserModel(member))
  }

  get membersCountDisplay() {
    return this.numberWithCommas(this.membersCount)
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  @action deleteUser(userId: Number) {
    const userIndex = this.members.findIndex((member: UserModel) => member.userId === userId);

    this.members.splice(userIndex, 1);
  }

  @action updateUserIsRemoving(userId: Number) {
    const userModel: UserModel = this.members.find((member: UserModel) => member.userId === userId);

    if(userModel) {
      userModel.isRemoving = true;
    }
  }
}
