import {RequestModel} from "../../../request";
import {REQUEST} from "../../../requests.consts";
import {shareState} from "../../../../../app/share-state";

export class GetRequestAllGamesModel extends RequestModel {
  constructor() {
    super(REQUEST.CATEGORY.games, REQUEST.ACTION.GAMES.getAllGames, {}, false, true, true, false);

    this.apiEnvironment = shareState.environment.baseUrl;
    this.method = REQUEST.METHOD_TYPE.get;
  }
}
