import React, {useState} from "react";

import classNames from "classnames";
import styles from "./entity-info.module.scss";
import {Image} from "../image/image";
import {debounce} from "../../infrastructure/functions/debounce";
import {EditImageRequestParams} from "../../icons/edit";
import {shareState} from "../../app/share-state";
import {COLORS} from "../../styles/colors";

export const EntityInfoInput = ({item, onChangeName}) => {
  const [editColor, setEditColor] = useState(COLORS[shareState.theme].text)

  const inputProps = {
    className: styles.name_input,
    placeholder: item?.name,
    maxLength: 30,
    onFocus: () => setEditColor(COLORS[shareState.theme].partial_success),
    onBlur: () => setEditColor(COLORS[shareState.theme].text),
    onChange: debounce((e) => onInputChange(e), 300)
  }

  const onInputChange = (e) => {
    onChangeName && onChangeName(e, e.target.value)
  }

  const iconProps = {
    className: classNames(styles.edit_icon),
    src: new EditImageRequestParams(16,16, editColor),
  }

  return (
    <div className={classNames(styles.input_container)}>
      <Image {...iconProps}/>
      <input {...inputProps}/>
    </div>
  )
};


