import classNames from "classnames";
import {DropdownItem} from "./dropdown.item";

export const DropdownList = ({items, isOpen, onItemChange, logoIncluded, styles, listHeight, openToTop}) => {
  const listContainerProps = {
    className: classNames(styles.list, {[styles.open]: isOpen}),
    style: {
      height: isOpen ? listHeight : 'unset',
      overflowY: isOpen ? 'auto' : 'none',
      top: openToTop && isOpen ? `${-(listHeight)}px` : null
    }
  };

  return (
    isOpen ? <div {...listContainerProps}>
      {
        items.map(item => <DropdownItem
          item={item}
          key={item.id}
          logoIncluded={logoIncluded}
          onChange={(id) => onItemChange(id)}
          styles={styles}
        />)
      }
    </div> : null
  )
};
