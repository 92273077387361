import {LoginWidgetStep} from "./login.widget.step";
import {LoginWidgetStepModel} from "../../login-widget.step.model";
import {shareState} from "../../../../app/share-state";
import classNames from "classnames";
import styles from "../../styles/login.module.scss";

export const LoginWidgetSteps = ({controller}) => {
  const {state: {steps}} = controller;

  return (
    <div className={classNames(styles.steps)}>
      {
        steps?.map((step: LoginWidgetStepModel, index: Number) => <LoginWidgetStep
          step={step}
          key={step.key}
          shouldRenderLine={shareState.isMobileView && (index !== steps.length - 1)}
        />)
      }
    </div>
  )
};
