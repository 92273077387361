import {BaseModel} from "./base";

export class RankingModel extends BaseModel {
  mainGroupRank: String;
  score: Number;
  wins: Number;
  bullsEyes: Number;
  direction: Number;


  constructor(model) {
    super(model);

    this.mainGroupRank = model.mainGroupRank;
    this.score = model.score;
    this.wins = model.wins;
    this.bullsEyes = model.bullseyes;
    this.direction = model.direction;
  }
}