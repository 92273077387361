import {BaseModel} from "../base";
import type {Bookmaker} from "./bookmaker";
import type {LineType} from "./line-type";
import {Rate} from "./rate";

export class Line extends BaseModel {
  bookmaker: Bookmaker;
  lineType: LineType;
  link: String;
  parameterValues: Array<Number>;

  constructor(model, bookmaker: Bookmaker, lineType: LineType) {
    super(model);

    this.id = model.ID;
    this.bookmaker = bookmaker;
    this.lineType = lineType;
    this.link = model.Link;
    this.options = model.Options.map((rate) => new Rate(rate));
    this.parameterValues = model.PVs;
  }

  get url(): String {
    return this.link || this.bookmaker?.link;
  }
}