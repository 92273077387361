// eslint-disable-next-line no-extend-native
String.prototype.addColorToSubString = function (color) {
  const headerSpanTag = `<span style="color: ${color}">${this}</span>`;

  return headerSpanTag
}

// eslint-disable-next-line no-extend-native
Array.prototype.rotate = function (count) {
  count -= this.length * Math.floor(count / this.length);
  this.push.apply(this, this.splice(0, count));

  return this
}

// eslint-disable-next-line no-extend-native
String.prototype.convertToNumberArray = function () {
  return this.split(',').map(Number)
}

// eslint-disable-next-line no-extend-native
String.prototype.colorSubString = function (color) {
  const style = `color: ${color}`
  const start = '{{';
  const end = '}}';

  return changeSubString(this, '', style, start, end);
}

// eslint-disable-next-line no-extend-native
String.prototype.boldSubString = function () {
  const className = 'bold';
  const start = '#';
  const end = '#';

  return changeSubString(this, className, null, start, end);
}

// eslint-disable-next-line no-extend-native
String.prototype.underlineSubString = function () {
  const className = 'underline';
  const start = '{_';
  const end = '_}';

  return changeSubString(this, className, null, start, end);
}

// eslint-disable-next-line no-extend-native
String.prototype.italicSubString = function () {
  const className = 'italic';
  const start = '{/';
  const end = '/}';

  return changeSubString(this, className, null, start, end);
}

// eslint-disable-next-line no-extend-native
String.prototype.linkSubString = function (className, url) {
  const start = '{<';
  const end = '>}';

  const headerSpanTag = `<a href=${url} class=${className}>`;
  const newString = this.replaceAll(start, headerSpanTag).replaceAll(end, '</a>')

  return newString
}

const changeSubString = (text, className, style, start, end) => {
  const headerSpanTag = `<span style="${style}" class=${className}>`;
  const newString = text.replaceAll(start, headerSpanTag).replaceAll(end, '</span>')

  return newString
}

// eslint-disable-next-line no-extend-native
Array.prototype.last = function (callback = null) {
  if (callback) {
    return this.filter(callback).last();
  }
  return this[this.length - 1];
}

// eslint-disable-next-line no-extend-native
Array.prototype.first = function (callback = null) {
  if (callback) {
    return this.find(callback);
  }

  return this.length > 0 ? this[0] : null;
}

// eslint-disable-next-line no-extend-native
String.prototype.format = function (params = {}, prefix = '#') {
  let formattedString = this;

  for (const key in params) {
    formattedString = formattedString.replaceAll(prefix + key, params[key]);
  }

  return formattedString;
}

