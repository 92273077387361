import React, {Suspense} from "react";
import "./home-page.module.scss";
import {LoadingIndicator} from "../../components/loading-indicator/loading-indicator";

const HomePage = React.lazy(() => import("./home.page" /* webpackChunkName: "home.page" */ ));

const HomePageElement = () => {
  return (
    <Suspense fallback={<LoadingIndicator/>}>
      <HomePage/>
    </Suspense>
  )
};

export default HomePageElement;