import {shareState} from "../share-state";
import {LoginWidget} from "../../widgets/login-widget/components/login.widget";
import {AppHeader} from "./header/app.header";
import {observer} from 'mobx-react';

export const AppLogin = observer(({controller}) => {
  return (
    <>
      {
        shareState.isMobileView ? <AppHeader controller={controller} isLoginPage={true}/> : null
      }
      <LoginWidget/>
    </>
  );
});
