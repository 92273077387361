import React, {Suspense} from "react";
import {LoadingIndicator} from "../../components/loading-indicator/loading-indicator";

const WinningTeamPage = React.lazy(() => import("./winning-team.page" /* webpackChunkName: "winning-team.page" */ ));

const WinningTeamPageElement = () => {
  return (
    <Suspense fallback={<LoadingIndicator/>}>
      <WinningTeamPage/>
    </Suspense>
  )
};

export default WinningTeamPageElement;