import classNames from "classnames";
import styles from '../../styles/settings-widget.module.scss';
import {observer} from "mobx-react";
import {SettingsWidgetItemInfo} from "../settings.widget.item.info";
import {shareState} from "../../../../app/share-state";
import {Image} from "../../../../components/image/image";
import {CaptainImageRequestParams} from "../../../../icons/captain";
import {RemoveUserImageRequestParams} from "../../../../icons/remove-user";
import {LoadingIndicator} from "../../../../components/loading-indicator/loading-indicator";
import {kinesisAnalyticsUtils} from "../../../../analytics/kinesis/kinesis.analytics-utils";
import {SettingsRemoveUserClickKinesisEvent} from "../../../../analytics/kinesis/events/settings/settings.remove-user.click.kinesis-event";

export const SettingsWidgetMemberItem = observer(({controller, member}) => {
  const {state} = controller
  const {table} = state
  const {userId, name, isRemoving, isUserRemovable} = member;

  const infoProps = {
    item: member,
    shouldRenderBadge: shareState.user.userId === userId,
    icon: new CaptainImageRequestParams(16, 16),
    label: name
  }

  const removeUserProps = {
    className: classNames(styles.icon),
    src: new RemoveUserImageRequestParams(25,20),
    onClick: () => {
      controller.kickUserFromGroup(table.id, userId)
      kinesisAnalyticsUtils.send(new SettingsRemoveUserClickKinesisEvent({userId, groupId: table.id}))
    }
  }

  const loadingIndicatorProps = {
    className: classNames(styles.loader),
    size: 20,
    style: {
      width: 20,
      height: 20
    },
  }

  return (
    <div className={classNames(styles.item)}>
      <SettingsWidgetItemInfo {...infoProps}/>
      {
        isRemoving ? <LoadingIndicator {...loadingIndicatorProps}/> : (isUserRemovable ? <Image {...removeUserProps}/> : null)
      }
    </div>
 )
});
