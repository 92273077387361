import {WidgetComposer} from "../../widget.composer";
import {SettingsWidgetController} from "../settings.widget.controller";
import {SettingsWidgetState} from "../settings.widget.state";
import classNames from "classnames";
import styles from '../styles/settings-widget.module.scss';
import {shareState} from "../../../app/share-state";
import {EntityInfo} from "../../../components/entity-info/entity-info";
import {SettingsWidgetMainSettings} from "./main/settings.widget.main-settings";
import {observer} from "mobx-react";
import {SettingsWidgetGroupSettings} from "./group/settings.widget.group-settings";
import {Version} from "../../../components/version/version";

export const settings = {
  controller: SettingsWidgetController,
  state: SettingsWidgetState
}

export const SettingsWidget = WidgetComposer(settings)(observer(({controller, onClose}) => {
  const {state} = controller;
  const {table} = state;

  const entityInfoProps = {
    item: table || shareState.user,
    onImageUploaded: (fileUploaded) => table ? controller.changeGroupPicture(table.id, fileUploaded) : controller.changeUserImage(fileUploaded),
    onChangeName: (e, name) => {
      if(e.target.value.length > 2) {
        table ? controller.changeGroupName(table.id, name) : controller.changeUserName(name)
      }
    }
  }

  return (
    <div className={classNames(styles.container, {[styles.mobile_view]: shareState.isMobileView})}>
      <EntityInfo {...entityInfoProps}/>
      {
        table ? <SettingsWidgetGroupSettings controller={controller}/> : <SettingsWidgetMainSettings controller={controller} onClose={onClose}/>
      }
      <Version className={styles.version}/>
    </div>
  )
}));
