import React, {useEffect, useState} from "react";
import {observer} from 'mobx-react';

import classNames from "classnames";
import styles from './collapsed-item.module.scss';
import {shareState} from "../../app/share-state";
import {CollapsedItemHeader} from "./collapsed-item.header";
import {CollapsedItemContent} from "./collapsed-item.content";

export const CollapsedItem = observer(({children, item, disable = false}) => {
  const {open} = item;
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open])

  const headerProps = {
    item,
    isOpen,
    disable,
    className: classNames(styles.header, {[styles.open]: isOpen}, {[styles.disable]: disable}),
    onHeaderClick: () => !disable && setIsOpen(!isOpen)
  }

  const contentProps = {
    isOpen: isOpen || disable,
    children,
  }

  return (
    <div className={classNames(styles.container, {[styles.mobile_view]: shareState.isMobileView})}>
      <CollapsedItemHeader {...headerProps}/>
      <CollapsedItemContent {...contentProps}/>
    </div>
  )
});
