import {RequestModel} from "../../../request";
import {REQUEST} from "../../../requests.consts";
import {shareState} from "../../../../../app/share-state";

export class PostRequestLeaveGroupModel extends RequestModel {
  groupId: Number;

  constructor({groupId}) {
    super(REQUEST.CATEGORY.groups, REQUEST.ACTION.GROUPS.leave, {}, true, false, true, true);

    this.apiEnvironment = shareState.environment.baseUrl;
    this.method = REQUEST.METHOD_TYPE.post;
    this.groupId = groupId;
  }

  get body() {
    const lang = {"lang": shareState.language.id}
    const groupId = this.groupId ? {'groupID': this.groupId} : {}

    return {...groupId, ...lang};
  }
}
