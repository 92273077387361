import {ProfileHeaderButtonModel} from "./profile-header.button.model";
import styles from "../profile-header.module.scss";
import {APP_CONST, ROUTE_NAME} from "../../../consts";
import {AthleteImageRequestParams} from "../../../icons/athlete/athlete-image-request-params";
import {TopScorerDefaultImageRequestParams} from "../../../icons/top-scorer/top-scorer-default";
import {ImageRequestParams} from "../../../models/image/image-request-params";
import {override} from "mobx";
import {WIZARD} from "../../../widgets/wizard-widget/wizard.widget.consts";
import {shareState} from "../../../app/share-state";
import {TopScorerClickKinesisEvent} from "../../../analytics/kinesis/events/profile-header/top-scorer.click.kinesis-event";

export class ProfileHeaderButtonTopScorerModel extends ProfileHeaderButtonModel {
  @override get entityId(): Number {
    return this.user.topScorerId;
  }

  @override get wizardPage(): Number {
    return WIZARD.PAGES.topScorer
  }

  @override get source(): String {
    return !this.isOtherUser ? APP_CONST.ROUTE.topScorer: APP_CONST.ROUTE.leaderboard;
  }

  @override get name(): String {
    return this.user.topScorerName || (this.isOtherUser ? '-' : shareState.termsUtils.terms.WC_20222_SELECT);
  }

  @override get imageSource(): ImageRequestParams {
    const imageSource: ImageRequestParams = this.entityId ? new AthleteImageRequestParams(this.entityId) : new TopScorerDefaultImageRequestParams(32, 16);

    return imageSource;
  }

  get sectionName(): String {
    return shareState.termsUtils.terms.WC_2022_TOP_SCORER;
  }

  get iconClassName(): String {
    return styles.top_scorer_icon;
  }

  @override get hasNoSelection(): Boolean {
    return !this.entityId;
  }

  @override get isRouteActive(): Boolean {
    const isRouteActive: Boolean = ROUTE_NAME[APP_CONST.ROUTE.topScorer] === this.pathName;
    const isPageActive: Boolean = WIZARD.PAGES.topScorer === shareState.wizard.currentPage && shareState.wizard.isVisible;
    const isTopScorerActive: Boolean = isRouteActive || isPageActive;

    return isTopScorerActive && !this.entityId;
  }

  @override get clickEventModel(): TopScorerClickKinesisEvent {
    return new TopScorerClickKinesisEvent({id: this.entityId});
  }
}