import classNames from "classnames";
import styles from './game.module.scss';
import {GameStatus} from "./game.status";
import {GameCenter} from "./game.center";

export const GameScores = ({
                             item,
                             controller,
                             setActive,
                             onChangeValue,
                             shouldRenderGameBets,
                             isHomePressed}) => {
  const {bottomStatus} = item;
  const gameCenterProps = {
    item,
    controller,
    isHomePressed,
    shouldRenderGameBets,
    setActive: (e, index) => setActive(e, index),
    onChangeValue: (e, value, index) => onChangeValue(e, value, index),
  }

  return (
    <div className={classNames(styles.score_container)}>
      <GameStatus item={item}/>
      <GameCenter {...gameCenterProps}/>
      <div className={classNames(styles.status_text)}>{bottomStatus}</div>
    </div>
  );
};


