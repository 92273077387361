import {WidgetState} from "../widget.state";
import {computed, makeObservable, observable, values} from "mobx";
import {GameWidgetInputModel} from "../games-widget/models/games.widget.game-input-model";
import {ACCA_WIDGET} from "./acca-widget.consts";
import {shareState} from "../../app/share-state";
import type {Bookmaker} from "../../models/bets/bookmaker";
import type {Selection} from "../../models/bets/selection";

export class AccaWidgetState extends WidgetState {
  @observable _games: Array<GameWidgetInputModel>;
  @observable isLoadingGames;
  @observable isLoadingBetSlip;
  @observable selections: Array<Selection>;
  @observable page: ACCA_WIDGET.pages;
  @observable _accumulatedRate: Number;

  constructor() {
    super();

    this.isLoadingGames = true;
    this.isLoadingBetSlip = true;
    this.page = ACCA_WIDGET.pages.bets;
    this.amount = shareState.settings.amount;

    makeObservable(this);
  }

  set games(games) {
    this._games = games;
  }

  @computed get games(): Array<GameWidgetInputModel> {
    return this._games?.length > 0 ? values(this._games).sort((a: GameWidgetInputModel, b: GameWidgetInputModel) => a.startTime - b.startTime) : [];
  }

  @computed get allGamesFilled(): Boolean {
    let allGamesFilled = true;

    this.games?.forEach((game) => allGamesFilled &= game.isBetsFulfilled || game.isEnded);

    return allGamesFilled;
  }

  @computed get totalAmount(): Number {
    return (shareState.settings.betValue * this.accumulatedRate)?.toFixed(0);
  }

  @computed get ctaText(): String {
    return shareState.termsUtils.terms.BOLAO_ACCA_SUMMARY_CTA_TEXT?.format({
      "CURRENCY": shareState.settings.currencySign,
      "AMOUNT": shareState.settings.betValue,
      "TOTAL_AMOUNT": this.totalAmount,
    }).colorSubString('#FFC107')
  }

  @computed get topBookmaker(): Bookmaker {
    return this.selections?.find((selection: Selection) => selection.line.bookmaker?.id === shareState.settings.topBookmakerId)?.line.bookmaker;

  }

  get accumulatedRate() {
    return this._accumulatedRate || this.selections?.map(s => s.rate.rate)?.reduce((a: Number, b: Number) => a * b, 1);
  }
}