import {GameModel} from "../../../models/game/game";
import {makeObservable, action, computed, observable} from "mobx";
import {EMPTY_SCORE} from "../../../consts";
import {GameInputBetModel} from "./games.widget.game-input-bet-model";
import {shareState} from "../../../app/share-state";
import {AddonModel} from "../../../models/addon";

export class GameWidgetInputModel extends GameModel {
  @observable bets: Array<GameInputBetModel>;

  constructor(model: GameModel, addon: AddonModel) {
    super(model, addon);

    makeObservable(this);

    this.bets = [new GameInputBetModel(model.gameBet?.selection?.team1), new GameInputBetModel(model.gameBet?.selection?.team2)]
  }

  @computed get isBetsFulfilled(): Boolean {
    return this.bets.filter(bet => bet.score > EMPTY_SCORE)?.length === this.bets.length;
  }

  @computed get isActive(): Boolean {
    return Boolean(this.activeBet);
  }

  @computed get activeBet(): GameInputBetModel {
    return this.bets.find(bet => bet.active);
  }

  @computed get vsLabel(): String {
    return shareState.termsUtils.terms.WC_2022_VS;
  }

  @action setInactive() {
    this.bets.forEach(bet => bet.active = false);
  }

  @action setActive(index: Number = -1) {
    let bet: GameInputBetModel;

    if(index === -1) {
      bet = this.bets.find((bet: GameInputBetModel) => bet.score === EMPTY_SCORE);
    } else {
      bet = this.bets[index];
    }

    this.setInactive();

    if (bet) {
      bet.active = true;
    }
  }

  @computed get hasHomeBet(): Boolean {
    return this.bets[0].score > -1
  }

  @computed get hasAwayBet(): Boolean {
    return this.bets[1].score > -1
  }

  @computed get lastHomeScoreBet(): Number {
    return this.hasHomeBet ? this.bets[0].score : this.gameBet?.selection?.home
  }

  @computed get lastAwayScoreBet(): Number {
    return this.hasAwayBet ? this.bets[1].score : this.gameBet?.selection?.away
  }
}
