import {BaseModel} from "../base";

export class GameBetOutcomesModel extends BaseModel {
  partial: Number;
  full: Number;

  constructor(model) {
    super(model);

    this.partial = model.partial;
    this.full = model.full;
  }
}