import classNames from "classnames";
import {DropdownHeader} from "./dropdown.header";
import {useEffect, useRef, useState} from "react";
import {DropdownComposer} from "./dropdown.composer";
import {shareState} from "../../app/share-state";
import {DropdownList} from "./dropdown.list";
import {FilterClickKinesisEvent} from "../../analytics/kinesis/events/dropdown/filter.click.kinesis-event";
import {kinesisAnalyticsUtils} from "../../analytics/kinesis/kinesis.analytics-utils";

export const Dropdown = DropdownComposer(({
                                            items,
                                            selectedItem,
                                            styles,
                                            onChange,
                                            isOneItemOnly,
                                            hideHeaderLogo,
                                            logoIncluded,
                                            source,
                                            itemHeight,
                                            openToTop
                                          }) => {
  const [isOpen, setIsOpen] = useState(false);
  const listHeight: Number = items.length > 5 ? 320 : (items.length * itemHeight);

  const ref = useRef();

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    }
  }, [isOpen]);

  const handleOutsideClick = (event) => {
    if(isOpen) {
      const isClickInside = ref.current?.contains(event.target)

      if (!isClickInside) {
        setIsOpen(false);
      }
    }
  }

  const headerProps = {
    isOpen,
    logoIncluded,
    selectedItem,
    isOneItemOnly,
    hideHeaderLogo,
    styles,
    onArrowClick: () => {
      !isOpen && kinesisAnalyticsUtils.send(new FilterClickKinesisEvent({source}))

      !isOneItemOnly && setIsOpen(!isOpen)
    }
  }

  const listProps = {
    items,
    isOpen,
    logoIncluded,
    styles,
    listHeight,
    openToTop,
    onItemChange: (id) => onItemChange(id),
  }

  const onItemChange = (id) => {
    onChange(id);
    setIsOpen(false);
  }


  return (
    <div className={classNames(styles.container, {[styles.mobile_view]: shareState.isMobileView, [styles.top]: openToTop})} ref={ref}>
      {
        openToTop ? <DropdownList {...listProps}/> : null
      }
      <DropdownHeader {...headerProps}/>
      {
        !openToTop ? <DropdownList {...listProps}/> : null
      }
    </div>
  );
});
