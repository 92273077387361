import classNames from "classnames";
import styles from "../navigation-bar.module.scss";
import {APP_CONST, ROUTE_NAME} from "../../../consts";
import {Image} from "../../image/image";
import {useCurrentPath} from "../../../hooks/use-current-path";
import {COLORS} from "../../../styles/colors";
import {shareState} from "../../../app/share-state";
import {Link} from "../../link/link";
import {kinesisAnalyticsUtils} from "../../../analytics/kinesis/kinesis.analytics-utils";
import {MenuClickKinesisEvent} from "../../../analytics/kinesis/events/navigation-bar/menu.click.kinesis-event";

export const NavigationBarButtonMobile = ({source, iconType, label, shouldRenderNavigationButton = true}) => {
  const {pathName} = useCurrentPath();

  const isActive = ROUTE_NAME[source] === pathName;
  const isHome = ROUTE_NAME[source] === ROUTE_NAME[APP_CONST.ROUTE.home];

  const imageProps = {
    className: styles.button_logo,
    src: new iconType(40, 40, isActive ? COLORS[shareState.theme].text : COLORS[shareState.theme].disable)
  }

  const linkProps = {
    className: classNames(styles.button, {[styles.active_button]: isActive}),
    to: !shareState.wizard.isVisible && `${shareState.currentDisplayName ? `/${shareState.currentDisplayName}` : ''}${!isHome ? source : '/'}`,
    onClick: () => {
      kinesisAnalyticsUtils.send(new MenuClickKinesisEvent({source}));
      // window.scrollTo(0, 0);

      return true;
    }
  }

  return (
    shouldRenderNavigationButton ? <Link {...linkProps}>
      <Image {...imageProps}/>
      <span>{label}</span>
    </Link> : null
  );
};


