import classNames from "classnames";
import styles from "../profile-header.module.scss";
import {ProfileHeaderButtonImage} from "./profile-header.button-image";
import {observer} from "mobx-react";

export const ProfileHeaderButton = observer(({model}) => {
  const {name, sectionName} = model

  return (
    <div className={classNames(styles.secondary_button)}>
      <ProfileHeaderButtonImage model={model}/>
      <div className={classNames(styles.header_button_text)}>{name}</div>
      <div className={classNames(styles.header_button_subtext)}>{sectionName}</div>
    </div>
  );
});

