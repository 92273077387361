import {RequestModel} from "../../../request";
import {REQUEST} from "../../../requests.consts";
import {APP_CONST} from "../../../../../consts";
import {shareState} from "../../../../../app/share-state";
import {localStorageUtils} from "../../../../../infrastructure/utils/locale-storage-utils";

export class PostRequestLoginModel extends RequestModel {
  userToken: String;
  authenticatorId: Number;
  userAgreement: Boolean;

  constructor(userToken: String, authenticatorId: Number = REQUEST.AUTHENTICATOR_ID.firebase, userAgreement: Boolean = false, deviceId: String = null, appType: APP_CONST.PLATFORMS.web) {
    super(REQUEST.CATEGORY.users, REQUEST.ACTION.USERS.login, {}, true, false, false, true);

    this.apiEnvironment = shareState.environment.baseUrl;
    this.method = REQUEST.METHOD_TYPE.post;
    this.userToken = userToken;
    this.authenticatorId = authenticatorId;
    this.userAgreement = userAgreement;
    this.deviceId = deviceId;
    this.appType = appType;
  }

  get body() {
    const token = this.userToken ? {"authToken": this.userToken} : {}
    const authenticatorId = this.authenticatorId ? {"authenticatorID": this.authenticatorId} : {};
    const countryId = Number(localStorageUtils.getItem(APP_CONST.LOCAL_STORAGE_KEYS.countryId));

    const params = {
      "userAgreement": this.userAgreement,
      "appType": this.appType,
      "countryId": countryId,
      "deviceID": this.deviceId,
    }

    return {...token, ...authenticatorId, ...params};
  }
}
