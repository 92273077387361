import classNames from "classnames";
import styles from "../profile-header.module.scss";
import {ProfileHeaderStripSection} from "./profile-header.strip.section";
import {observer} from 'mobx-react';
import {shareState} from "../../../app/share-state";

export const ProfileHeaderStrip = observer(({user}) => {
  const rankProps = {
    name: shareState.termsUtils.terms.WC_2022_OVERALL_RANKING,
    value: user.ranking?.mainGroupRank
  };

  const scoreProps = {
    name: shareState.termsUtils.terms.WC_2022_POINTS,
    value: user.ranking?.score
  };

  const bullsEyesProps = {
    name: shareState.termsUtils.terms.WC_2022_EXACT_SCORE,
    value: user.ranking?.bullsEyes
  };

  return (
    user.ranking && !shareState.wizard.isVisible ? <div className={classNames(styles.strip)}>
      <ProfileHeaderStripSection {...rankProps}/>
      <ProfileHeaderStripSection {...scoreProps}/>
      <ProfileHeaderStripSection {...bullsEyesProps}/>
    </div> : null
  );
});
