export const SELECTION_LIST_WIDGET = {
  debounceDelay: 250,
  minimumCharacters: 3,

  LOGO_SIZE: {
    ENTITY: {
      width: 32,
      height: 32
    },
    CHECK_MARK: {
      width: 12,
      height: 20
    },
    SEARCH: {
      width: 20,
      height: 20
    }
  }
}