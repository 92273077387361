import classNames from "classnames";
import styles from "../../styles/login.module.scss";
import {LoginWidgetStepIcon} from "./login-widget.step-icon";
import {LoginWidgetStepDescription} from "./login.widget.step-description";

export const LoginWidgetStepItem = ({step}) => {
  return (
    <div className={classNames(styles.step)}>
      <LoginWidgetStepIcon step={step}/>
      <LoginWidgetStepDescription step={step}/>
    </div>
  )
};
