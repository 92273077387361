import {firebaseApp} from "./firebase";
import {getAuth, FacebookAuthProvider, GoogleAuthProvider, OAuthProvider, signInWithPopup, signInWithRedirect, signOut} from "firebase/auth";
import {APP_CONST} from "../../consts";
import {REQUEST} from "../request/requests.consts";
import {localStorageUtils} from "../../infrastructure/utils/locale-storage-utils";
import {userAgentUtils} from "../../infrastructure/utils/user-agent-utils";
import {shareState} from "../../app/share-state";

export class FirebaseAuth {
  auth;
  googleProvider: GoogleAuthProvider;
  facebookProvider: FacebookAuthProvider;

  constructor() {
    this.auth = getAuth(firebaseApp)
    this.facebookProvider = new FacebookAuthProvider();
    this.appleProvider = new OAuthProvider('apple.com');
    this.googleProvider = new GoogleAuthProvider();
  }

  async signIn(authenticatorId) {
    let userToken: String = null;
    const provider = this.getAuthProvider(authenticatorId);

    if(process.env.REACT_APP_ENVIRONMENT_TYPE !== "development" && !shareState.isWebView && userAgentUtils.isAndroid && authenticatorId === REQUEST.AUTHENTICATOR_ID.facebook) {
      localStorageUtils.setItem(APP_CONST.LOCAL_STORAGE_KEYS.signedInWithRedirect, true)
      await signInWithRedirect(this.auth, provider)
    } else {
      await signInWithPopup(this.auth, provider).then((result) => {
        userToken = result.user.accessToken;
        localStorageUtils.setItem(APP_CONST.LOCAL_STORAGE_KEYS.userToken, userToken)
      }).catch((error) => {
        console.error(error)
        userToken = null
      });
    }

    return userToken;
  }

  getAuthProvider(authenticatorId) {
    if (authenticatorId === REQUEST.AUTHENTICATOR_ID.google) {
      return this.googleProvider;
    } else if (authenticatorId === REQUEST.AUTHENTICATOR_ID.facebook) {
      return this.facebookProvider;
    } else if (authenticatorId === REQUEST.AUTHENTICATOR_ID.apple) {
      return this.appleProvider;
    }
  }

  async signOut() {
    await signOut(this.auth).then(() => {
      localStorageUtils.removeItem(APP_CONST.LOCAL_STORAGE_KEYS.userToken)
      localStorageUtils.removeItem(APP_CONST.LOCAL_STORAGE_KEYS.userAuthorizationToken)
    }).catch((error) => {
      console.log('An error happened')
    });
  }
}
