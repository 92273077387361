import React, {Suspense} from "react";
import {LoadingIndicator} from "../../components/loading-indicator/loading-indicator";

const TopScorerPage = React.lazy(() => import("./top-scorer.page" /* webpackChunkName: "top-scorer.page" */ ));

const TopScorerPageElement = () => {
  return (
    <Suspense fallback={<LoadingIndicator/>}>
      <TopScorerPage/>
    </Suspense>
  )
};

export default TopScorerPageElement;