
export class LocaleStorageUtils {
  storage: Storage;

  constructor() {
    this.storage = localStorage;
  }


  getItem(key) {
    const value: String = this.storage.getItem(key);

    return value ? value : undefined;
  }

  getJSON(key) {
    const value: String = this.storage.getItem(key);

    return value ? JSON.parse(value) : undefined;
  }

  setItem(key, value) {
    return this.storage.setItem(key, value);
  }

  setJSON(key, value) {
    return this.storage.setItem(key, JSON.stringify(value));
  }

  removeItem(key) {
    this.storage.removeItem(key);
  }

  deleteAll() {
    this.storage.clear();
  }
}

export const localStorageUtils = new LocaleStorageUtils();
