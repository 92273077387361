import React from 'react';
import styles from './game.module.scss';
import classNames from "classnames";
import {observer} from "mobx-react";
import {COLORS} from "../../styles/colors";
import {shareState} from "../../app/share-state";
import {VSImageRequestParams} from "../../icons/assets/vs";
import {Image} from "../image/image";

export const GameTimeAndPoints = observer(({item, shouldRenderGameBets}) => {
  const {isSchedule, gameBet} = item;

  const pointsProps = {
    className: classNames(styles.points),
    style: {
      backgroundColor: gameBet?.betOutcomeColor || COLORS[shareState.theme].live
    }
  }

  const vsProps = {
    src: new VSImageRequestParams(),
    className: classNames(styles.vs_image),
  }

  return isSchedule ? <Image {...vsProps}/> : (shouldRenderGameBets ? <div {...pointsProps}>{gameBet?.gainedPoints || 0}</div> : <div/>)
});
