import {observer} from "mobx-react";
import {shareState} from "../../app/share-state";
import {Dropdown} from "../dropdown/dropdown";
import {localStorageUtils} from "../../infrastructure/utils/locale-storage-utils";
import {APP_CONST} from "../../consts";

export const LanguagesDropdown = observer(({styles, openToTop}) => {
  const onChange = (id) => {
    localStorageUtils.setItem(APP_CONST.LOCAL_STORAGE_KEYS.langId, id);

    window.location.reload();
  }

  const dropdownProps = {
    items: shareState.languages,
    logoIncluded: true,
    customStyles: styles,
    selectedItemId: shareState.language.id,
    openToTop,
    itemHeight: 42,
    onChange
  }

  return (
    <Dropdown {...dropdownProps}/>
  )
});
