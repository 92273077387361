import {ImageRequestParams} from "../../models/image/image-request-params";

export class AthleteImageRequestParams extends ImageRequestParams {
  constructor(id, width: Number = 48, height: Number = 48) {
    super({width, height});

    this._resourceName = `Athletes/NationalTeam/${id}`;
    this.ratio = 2;
    this.r = 'max';
    this.c = 'thumb';
    this.g = 'face';
    this.zoom = 0.65;
  }

  get fallbackResourceName(): null {
    return  `Athletes:default`;
  }
}
