import {SettingsWidgetState} from "./settings.widget.state";
import {shareState} from "../../app/share-state";
import {GetRequestUserGroupsModel} from "../../models/request/category/groups/get/get-request.user-groups.model";
import {PostRequestSetUserSettingsModel} from "../../models/request/category/users/post/post-request.set-user-settings.model";
import {GetRequestDeleteGroupModel} from "../../models/request/category/groups/get/get-request.delete-group.model";
import {PostRequestLeaveGroupModel} from "../../models/request/category/groups/post/post-request.leave-group.model";
import {PostRequestKickGroupModel} from "../../models/request/category/groups/post/post-request.kick-group.model";
import {PostRequestChangeGroupPictureModel} from "../../models/request/category/groups/post/post-request.change-group-picture.model";
import {WidgetController} from "../../controllers/widget.controller";
import {GetRequestGroupTableModel} from "../../models/request/category/groups/get/get-request.group-table.model";
import {UserModel} from "../../models/user";
import {PostRequestRenameGroupModel} from "../../models/request/category/groups/post/post-request.rename-group.model";
import {visibleWidgetsManager} from "../../infrastructure/managers/visible-widgets-manager";
import {GroupModel} from "../../models/group";
import {kinesisAnalyticsUtils} from "../../analytics/kinesis/kinesis.analytics-utils";
import {SettingsImageChangeKinesisEvent} from "../../analytics/kinesis/events/settings/settings.image.change.kinesis-event";
import {SettingsNameChangeKinesisEvent} from "../../analytics/kinesis/events/settings/settings.name.change.kinesis-event";
import {SettingsLeaveGroupClickKinesisEvent} from "../../analytics/kinesis/events/settings/settings.leave-group.click.kinesis-event";

export class SettingsWidgetController extends WidgetController {
  state: SettingsWidgetState;

  async init() {
    await super.init();
    this.state.mainGroupId = Number(this.props.groupId || shareState.user.masterGroupId);

    await this.getAllGroups();

    this.state.isLoading = false;
  }

  updateData() {
    super.updateData();

    this.getAllGroups();
  }


  async getAllGroups() {
    const requestModel = new GetRequestUserGroupsModel();

    await this.sendGetRequest(requestModel, (data) => this.onPopulateGroups(data))
  }

  async onPopulateGroups(dataSource) {
    if (dataSource) {
      this.state.dataSource = dataSource;

      if (!this.state.mainGroupId) {
        this.state.mainGroupId = dataSource.groups[0].groupID;
      }
    }
  }

  async getGroupById(id) {
    const params = {groupID: id}
    const requestModel: GetRequestGroupTableModel = new GetRequestGroupTableModel(params);

    await this.sendGetRequest(requestModel, (data) => this.onPopulateGroup(data))
  }

  onPopulateGroup(dataSource) {
    if (dataSource) {
      this.state.tableDataSource = dataSource.table
    }
  }

  async changeUserName(value) {
    const requestModel = new PostRequestSetUserSettingsModel({userName: value});

    await this.sendPostRequest(requestModel, (data) => this.onPopulateUser(data))
    await kinesisAnalyticsUtils.send(new SettingsNameChangeKinesisEvent())
  }

  async changeUserImage(image) {
    const base64Image: String = await shareState.getBase64Image(image);
    const requestModel = new PostRequestSetUserSettingsModel({userImage: base64Image});

    await this.sendPostRequest(requestModel, (data) => this.onPopulateUser(data))
    await kinesisAnalyticsUtils.send(new SettingsImageChangeKinesisEvent())
  }

  async changeGroupPicture(groupId, image) {
    const base64Image: String = await shareState.getBase64Image(image);
    const requestModel: PostRequestChangeGroupPictureModel = new PostRequestChangeGroupPictureModel({groupId, groupImage: base64Image});

    await this.sendPostRequest(requestModel, () => visibleWidgetsManager.update(this))
    await kinesisAnalyticsUtils.send(new SettingsImageChangeKinesisEvent({groupId}))
  }

  async changeGroupName(groupId, groupName) {
    const requestModel: PostRequestRenameGroupModel = new PostRequestRenameGroupModel({groupId, groupName});

    await this.sendPostRequest(requestModel, () => visibleWidgetsManager.update(this))
    await kinesisAnalyticsUtils.send(new SettingsNameChangeKinesisEvent({groupId}))
  }

  async kickUserFromGroup(groupId, otherUserId) {
    const requestModel: PostRequestKickGroupModel = new PostRequestKickGroupModel(groupId, otherUserId);
    this.state.table?.updateUserIsRemoving(otherUserId);

    await this.sendPostRequest(requestModel)
      .then(() => this.state.table?.deleteUser(otherUserId))
      .catch(() => console.log(`couldn't delete user:${otherUserId}`));
  }

  async leaveGroup(groupId) {
    const requestModel: PostRequestLeaveGroupModel = new PostRequestLeaveGroupModel({groupId});

    await this.sendPostRequest(requestModel, () => this.onPopulateDeleteGroup(groupId))
    await kinesisAnalyticsUtils.send(new SettingsLeaveGroupClickKinesisEvent({groupId}))
  }

  async deleteGroup(id) {
    const requestModel: GetRequestDeleteGroupModel = new GetRequestDeleteGroupModel({groupId: id});

    await this.sendGetRequest(requestModel, () => this.onPopulateDeleteGroup(id))
  }

  onPopulateDeleteGroup(id) {
    const isMainGroup = this.state.mainGroupId === id;
    const groups: Array<GroupModel> = this.state.groups?.filter(group => group.id !== id);
    const firstGroup: GroupModel = groups?.length > 1 ? groups.find(group => group.id !== id && !group.preventSetMaster) : groups[0];

    isMainGroup && this.changeMainGroupId(firstGroup.id);
    this.getAllGroups()
    this.closeEditMode()
    visibleWidgetsManager.update(this)
  }

  async changeMainGroupId(id) {
    const requestModel = new PostRequestSetUserSettingsModel({masterGroupId: id});

    await this.sendPostRequest(requestModel, (data) => this.onPopulateUser(data))

    this.state.mainGroupId = id;
  }

  async onPopulateUser(dataSource) {
    shareState.user = new UserModel(dataSource.user)
  }

  closeEditMode() {
    this.state.tableDataSource = null
  }
}
