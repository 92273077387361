import {BaseModel} from "./base";

export class GroupModel extends BaseModel {
  id: Number;
  adminId: Number;
  membersCount: Number;
  averageScore: Number;
  name: String;
  imageURL: String;
  invitationLink: String;
  isAdmin: Boolean;
  preventLeave: Boolean;
  preventSetMaster: Boolean;

  constructor(model) {
    super(model);

    this.id = model.groupID;
    this.adminId = model.adminID;
    this.membersCount = model.membersCount;
    this.averageScore = model.averageScore?.toFixed(2);
    this.name = model.name;
    this.imageURL = model.imageURL.replace('http://', 'https://').replace('imagescache', 'imagecache');
    this.invitationLink = model.invitationLink;
    this.isAdmin = model.isAdmin;
    this.preventLeave = model.preventLeave;
    this.preventSetMaster = model.preventSetMaster;
  }

  get membersCountDisplay() {
    return this.numberWithCommas(this.membersCount)
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

}
