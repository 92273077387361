import {APP_CONST} from "../../../consts";
import classNames from "classnames";
import styles from "../navigation-bar.module.scss";
import {shareState} from "../../../app/share-state";
import {NavigationBarButtonMobile} from "./navigation-bar.button.mobile";
import {GameRulesImageRequestParams} from "../../../icons/game-rules";
import {LeaderboardImageRequestParams} from "../../../icons/leaderboard";
import {ProfileImageRequestParams} from "../../../icons/profile";
import {PredictionImageRequestParams} from "../../../icons/prediction";
import {observer} from 'mobx-react';
import {LeaguesImageRequestParams} from "../../../icons/leagues";

export const NavigationBarMobile = observer(({controller}) => {
  const {state: {shouldRenderLeagueNavigationButton}} = controller;

  const homeProps = {
    source: APP_CONST.ROUTE.home,
    iconType: ProfileImageRequestParams,
    label: shareState.termsUtils.terms.WC_2022_PROFILE
  }

  const predictionProps = {
    source: APP_CONST.ROUTE.prediction,
    iconType: PredictionImageRequestParams,
    label: shareState.termsUtils.terms.WC_2022_SCORES_BUTTON
  }

  const leaderboardProps = {
    source: APP_CONST.ROUTE.leaderboard,
    iconType: LeaderboardImageRequestParams,
    label: shareState.termsUtils.terms.WC_2022_LEADERBOARD_BUTTON
  }

  const leaguesProps = {
    source: APP_CONST.ROUTE.leagues,
    iconType: LeaguesImageRequestParams,
    label: shareState.termsUtils.terms.WC_2022_LEAGUES_BUTTON,
    shouldRenderNavigationButton: shouldRenderLeagueNavigationButton
  }

  const gameRulesProps = {
    source: APP_CONST.ROUTE.rules,
    iconType: GameRulesImageRequestParams,
    label: shareState.termsUtils.terms.WC_2022_RULES_BUTTON
  }

  return (
    !shareState.wizard.isVisible ? <div className={classNames({[styles.mobile_view]: shareState.isMobileView})}>
      <NavigationBarButtonMobile {...homeProps}/>
      <NavigationBarButtonMobile {...predictionProps}/>
      <NavigationBarButtonMobile {...leaderboardProps}/>
      <NavigationBarButtonMobile {...leaguesProps}/>
      <NavigationBarButtonMobile {...gameRulesProps}/>
    </div> : null
  );
});

