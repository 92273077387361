import {ImageRequestParams} from "./image-request-params";


export class EnvironmentImageRequestParams extends ImageRequestParams {
  get resourceName() {
    return `${this.env}${this._resourceName}`;
  }

  get fallbackResourceName() {
    return `${this.fallbackEnv}${this._resourceName}.png`.replaceAll('/', ':');
  }

}