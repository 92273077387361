import styles from '../styles/games-widget.module.scss';
import {observer} from "mobx-react";
import classNames from "classnames";
import {SelectionListWidgetTitles} from "../../selection-list.widget/components/selection-list.widget.titles";
import {SelectionListHeader} from "../../../components/selection-list-header/selection-list-header";
import {shareState} from "../../../app/share-state";

export const GamesWidgetHeader = observer(({controller, currentPageModel, onButtonClick}) => {
  const {state} = controller;
  const {isOtherUser, shouldRenderWizardButton, shouldRenderAutoFillButton} = state;

  const headerButtonsProps = {
    buttonProps: {
      label: currentPageModel?.textButton,
      isFilled: true,
      onClick: () => onButtonClick && onButtonClick(),
    },
    className: classNames({
      [styles.one_button]: !shouldRenderAutoFillButton || shareState.isMobileView,
      [styles.two_buttons]: shouldRenderAutoFillButton && !shareState.isMobileView
    }),
    controller
  }

  const shouldRenderHeader: Boolean = !isOtherUser;

  return (
    shouldRenderHeader ? <div className={classNames(styles.header, {[styles.no_header]: !shouldRenderWizardButton})}>
      <SelectionListWidgetTitles title={state.title} subtitle={state.subtitle}/>
      <SelectionListHeader {...headerButtonsProps}/>
    </div> : null
  )
});
