import {BaseModel} from "../base";
import {makeObservable, observable} from "mobx";

export class GameScoreModel extends BaseModel {
  @observable home: Number;
  @observable away: Number;

  constructor(model) {
    super(model);

    this.home = model.team1 < 0 ? '?' : model.team1;
    this.away = model.team2 < 0 ? '?' : model.team2;

    makeObservable(this);
  }
}