import classNames from "classnames";
import styles from '../styles/settings-widget.module.scss';
import {observer} from "mobx-react";
import {Image} from "../../../components/image/image";

export const SettingsWidgetItemInfo = observer(({item, shouldRenderBadge, icon, label, activeClassName}) => {
  const userImageProps = {
    className: classNames(styles.item_logo, {[styles.active_item_logo]: shouldRenderBadge}),
    src: item.imageURL
  }

  const selectedMainGroupProps = {
    className: classNames(styles.main_item),
    src: icon
  }

  return (
    <div className={classNames(styles.item_info, activeClassName)}>
      {
        shouldRenderBadge ? <Image {...selectedMainGroupProps}/> : null
      }
      <Image {...userImageProps}/>
      <bdi className={classNames(styles.item_name)}>{label}</bdi>
    </div>
 )
});