import {BaseModel} from "../base";

export class LineType extends BaseModel {
  title: String;

  constructor(model) {
    super(model);

    this.id = model.ID;
    this.title = model.Title;
  }

}