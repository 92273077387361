import classNames from "classnames";
import styles from '../../styles/settings-widget.module.scss';
import dropdownStyles from '../../styles/settings-widget-dropdown.module.scss';
import {observer} from "mobx-react";
import {SettingsWidgetMembers} from "./settings.widget.members";
import {SettingsWidgetGroupSettingsHeader} from "./settings.widget.group-settings.header";

export const SettingsWidgetGroupSettings = observer(({controller}) => {
  return (
    <div className={classNames(styles.groups_sections, dropdownStyles.main_group)}>
      <SettingsWidgetGroupSettingsHeader controller={controller}/>
      <SettingsWidgetMembers controller={controller}/>
    </div>
  )
});