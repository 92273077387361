import {observer} from "mobx-react";
import {SettingsWidgetGroupItem} from "./settings.widget.group-item";

export const SettingsWidgetGroups = observer(({controller}) => {
  const {state} = controller;
  const {groups} = state;

  return groups?.map(group => <SettingsWidgetGroupItem
      key={group.id}
      group={group}
      controller={controller}
    />
  )
});

