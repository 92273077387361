import classNames from "classnames";
import styles from '../acca-widget.module.scss';
import {observer} from "mobx-react";
import {shareState} from "../../../app/share-state";
export const AccaWidgetBetSlipSummary = observer(({controller}) => {
  const containerProps = {
    className: classNames(styles.summary),
  }

  const {state: {accumulatedRate}} = controller;

  return (
      <div {...containerProps}>
        <div className={classNames(styles.text)}>
          {
            shareState.termsUtils.terms.BOLAO_ACCA_ODDS_SUMMARY
          }
        </div>
        <div className={classNames(styles.rate_container)}>
          {
            accumulatedRate?.toFixed(2)
          }
        </div>
      </div>
  )
});
