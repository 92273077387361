import '../styles/app.module.scss';
import classNames from "classnames";
import styles from "../styles/app.module.scss";
import {AppNavigationBar} from "./app.navigation-bar";
import {AppRoutes} from "./app.routes";
import {shareState} from "../share-state";

export const AppContent = ({controller}) => {
  return (
    <div className={classNames(styles.content)}>
      {
        !shareState.isMobileView ? <AppNavigationBar controller={controller}/> : null
      }
      <AppRoutes controller={controller}/>
    </div>
  );
};
