import classNames from "classnames";
import styles from './game.module.scss';
import {GameGoal} from "./game.goal";
import {GameTimeAndPoints} from "./game.time-and-points";
import {observer} from "mobx-react";

export const GameCenter = observer(({controller, item, setActive, onChangeValue, shouldRenderGameBets}) => {
  const {scores, gameBet, isSchedule, bets, lastHomeScoreBet, lastAwayScoreBet} = item
  const homeScore: String = gameBet?.selection.home > -1 ? gameBet?.selection.home : '-'
  const awayScore: String = gameBet?.selection.away > -1 ? gameBet?.selection.away : '-'

  const homeGoalProps = {
    item,
    controller,
    bet: lastHomeScoreBet,
    score: scores.home,
    setActive:(e) => setActive(e, 0),
    onChangeValue: (e, value) => bets[0].active && onChangeValue(e, value, 0),
    isInputActive: bets && bets[0].active,
  }

  const awayGoalProps = {
    item,
    controller,
    bet: lastAwayScoreBet,
    score: scores.away,
    setActive:(e) => setActive(e, 1),
    onChangeValue: (e, value) =>  bets[1].active && onChangeValue(e, value, 1),
    isInputActive: bets && bets[1].active,
  }

  const pointsProps = {
    item,
    shouldRenderGameBets
  }

  return (
    <div className={styles.center}>
      {
        !isSchedule && shouldRenderGameBets ? <div className={classNames(styles.bet, styles.home_bet)}>(&nbsp;{homeScore}&nbsp;)</div> : <div/>
      }
      <GameGoal {...homeGoalProps}/>
      <GameTimeAndPoints {...pointsProps}/>
      <GameGoal {...awayGoalProps}/>
      {
        !isSchedule && shouldRenderGameBets ? <div className={classNames(styles.bet, styles.away_bet)}>(&nbsp;{awayScore}&nbsp;)</div> : <div/>
      }
    </div>
  );
});


