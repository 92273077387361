import {shareState} from "../app/share-state";
import {EnvironmentImageRequestParams} from "../models/image/environment-image-request-params";

export class NativeLogoImageRequestParams extends EnvironmentImageRequestParams {
  constructor(width: Number, height: Number) {
    super({width, height});

    this._resourceName = `/logo/${shareState.language.id}`;
  }

  get fallbackResourceName(): string {
    return `bolao24:${shareState.environment.name}:${shareState.theme}:logo:1.png`
  }
}
