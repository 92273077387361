export class Terms {
  WC_2022_SELECT_LANGUAGE = "Select language";
  WC_2022_SELECT_MAIN_GROUP = "Select main group";
  WC_2022_SETTING_LOGOUT = "Logout";
  WC_2022_WELCOME_TAGLINE = "Create & Play your own Prediction Game with your friends for the Football Cup 2022 in Qatar";
  WC_2022_LIVE_BADGE = "Live";
  WC_2022_TERMS_AND_CONDITION_CONTENT = "Registration for the game starts on the October 1st and closes on the December 1st...";
  WC_2022_TERMS_OF_USE_HEADER = "Terms of use";
  WC_2022_RULES_TIE_BREAK_CONTENT = "1) Number of Exact Scores\r\n2) Number of Correct Results\r\n3) Number of goals scored by the Top scorer selection\r\n4) Tournament winner guess\r\n5) Registration time";
  WC_2022_RULES_TIE_BREAK_HEADER = "TieBreak (In case of a points tie)";
  WC_2022_RULES_HEADER = "Rules";
  WC_2022_MY_GROUPS = "My Groups";
  WC_2022_VS = "VS";
  WC_20222_SELECT = "Select";
  WC_2022_SKIP_BUTTON = "Skip";
  WC_2022_NEXT_BUTTON = "Next";
  WC_2022_SELECT_WINNING_TEAM = "Select Winning Team";
  WC_2022_SELECT_TOP_SCORER = "Select Top Scorer";
  WC_2022_DONE_BUTTON = "Done";
  WC_2022_AUTO_FILL_BUTTON = "Auto fill";
  WC_2022_WINNING_TEAM = "Winning Team";
  WC_2022_COUNTRY_NAME = "Country Name";
  WC_2022_TOP_SCORER = "Top Scorer";
  WC_2022_PLAYER_NAME = "Player Name";
  WC_2022_PROFILE = "Profile";
  WC_2022_SCORES_BUTTON = "Scores";
  WC_2022_LEADERBOARD_BUTTON = "Leaderboard";
  WC_2022_LEAGUES_BUTTON = "Leagues";
  WC_2022_RULES_BUTTON = "Rules";
  WC_2022_OVERALL_RANKING = "Ranking";
  WC_2022_POINTS = "Points";
  WC_2022_EXACT_SCORE = "Exact Score";
  WC_2022_CREATE_NEW_GROUP = "Create new group";
  WC_2022_GROUP_CREATED_MESSAGE = "Group created! It's time to play";
  WC_2022_PICK_CLOSE_IN = "Pick close in";
  WC_2022_GROUP_NAME = "Group Name";
  WC_2022_CREATE_GROUP_BUTTON = "Create Group";
  WC_2022_CREATE_A_NEW_GROUP_HEADER = "UPLOAD GROUP'S PHOTO AND NAME IT";
  WC_2022_INVITE_FRIENDS = "Invite friends and play";
  WC_2022_INVITE_FRIENDS_BUTTON = "Invite Friends";
  WC_2022_COPY_BUTTON_DESKTOP = "Copy";
  WC_2022_COPIED_BUTTON_DESKTOP = "Copied";
  WC_2022_GROUP_MEMBERS = "Group members";
  WC_2022_WINNER = "Winner";
  WC_2022_GAME_1 = "Game 1";
  WC_2022_GAME_2 = "Game 2";
  WC_2022_PREDICTION = "Prediction";
  WC_2022_YOUR_SCORE = "Your score";
  WC_2022_ACCEPT_AND_PLAY = "Accept and Play"
  WC_GAME_RULES = `<h4>Game scoring:</h4> <div></div><div>World Cup winner - 10 points</div> <div>Tournament top scorer - 15 points</div> <h4>Correct Result:</h4> <div>Group Stages - 1 Point</div><div>Quarterfinals - 5 Points</div> <div>Semifinals - 6 Points</div> <div>3rd Place Game 6 Points</div> <div>Final - 8 Points</div> <h4>Exact Score:</h4> <div>Group Stages - 3 Points</div><div>Quarterfinals - 10 Points</div> <div>Semifinals - 12 Points</div> <div>3rd Place Game - 12 Points</div> <div>Final - 15 Points</div>`;
  WC_2022_SELECT_WINNIG_TEAM_SECOND_LINE = "Who do you think going to win the title?";
  WC_2022_SELECT_TOP_SCORER_SECOND_LINE = "Who’s gonna outscore all?";
  WC_2022_SELECT_SCORELIST_SECOND_LINE = "Predict the exact score for each game";
  WC_2022_TOURNAMENT_TOP_SCORER = "Qatar 2022 top scorer";
  WC_2022_QUALIFIED_TEAMS = "Qatar 2022 qualified team";
  WC_2022_ACCAEPT_AND_PLAY = "Accept & Play";
  WC_2022_ENVIROMENT_ERROR_MESSAGE_WEB = "Sorry, we couldn't add you to this group";
  WC_2022_ENVIROMENT_ERROR_MESSAGE_WEB_CONFIRM_BUTTON = "OK";
  WC_2022_INVINTATION_TERM = "You're invited to join the group!";
  WC_2022_COUNTDOWN_DAYS = "Days";
  WC_2022_CONNECT_WITH_FB = "Connect With Facebook";
  WC_2022_CONNECT_WITH_GOOGLE = "Connect With Google";
  WC_2022_CONNECT_WITH_APPLE = "Connect With Apple";
  WC_2022_LOGIN_HEADER = "Sign in with your preferred social network and start playing";
  WC_2022_SIGN_UP = "Sign Up";
  WC_2022_SIGN_UP_SECOND_LINE = "and open your friend's league";
  WC_2022_GUESS_THE_GAME_RESULT = "Guess the game result";
  WC_2022_GUESS_THE_GAME_RESULT_SECOND_LINE = "and beat them all";
  WC_2022_GET_REAL_TIME_UPDATE = "Get real time update";
  WC_2022_GET_REAL_TIME_UPDATE_SECOND_LINE = "and live game";
  WC_2022_LOGIN_FAILED = "Login failed - Please try again";
  WC_2022_NO_DATA = "We didn't find anything related to your search";
  WC_2022_BACK = "Back";
  WC_2022_TOURNAMENT_NAME = "WORLD CUP";
  WC_2022_BOLAO_NAME = "OFFICE POOL";
  WC_2022_LOGIN_ACCEPT_TERMS_AND_CONDITIONS = 'By clicking on the login button you accept our <a href="https://www.365scores.com/pages/terms"> terms and conditions </a> and our <a href="https://www.365scores.com/pages/privacy"> privacy policy </a>. We do not post on your behalf.'
  WC_2022_INGAME_NAME_TOP_BANNER = "PREDICTION CUP 2022";
  WC_2022_LEAVE_GROUP_APPROVE = "Are You Sure You Want to leave the group?";
  WC_2022_OOPS_MESSAGE = "Oops!! Something went wrong.";
  WC_2022_INFLUENCERS_GROUP_LIST = "Group list";
  WC_2022_INFLUENCERS_NUM_OF_MEMBERS_INGROUP = "Members";
  WC_2022_AVG_POINTS_INFLUENCERS_GROUP = "Avg.";
  WC_2022_COMPETITION_PAGE_LINK_CTA = "World Cup Standings & Stats";
  WC_2022_EXPLORE_TEXT = "Explore The World Cup";
  WC_GROUP_TABLES = "Group Standings";
  WC_WEB_BRACKETS = "World Cup Brackets";
  WC_STATISTICS_NEWWEB = "Stats";
  MD_TITLE_BOLAO_HOMEPAGE = "Placeholder";
  MD_DESCRIPTION_BOLAO_HOMEPAGE = "Placeholder";
  CONFIRMATION_NEWSLETTER = "I agree to get updates, interesting news, and marketing emails/ messages from Domino's";
  CHECKBOX_CONFIRMED = "Please check the box to proceed";
  BOLAO_DAILY_ACCA_HEADER = "Set Your Scores, Build Your Bet";
  BOLAO_DAILY_ACCA_GET_MY_ACCA = "Create My Bet Builder";
  BOLAO_ACCA_IS_READY = "Your Bet Builder is Set!";
  BOLAO_ACCA_ODDS_SUMMARY = "Parley Summary";
  BOLAO_ACCA_PROMOTION_TEXT_MAIN = `Get your personal accumulation bets`;
  BOLAO_ACCA_PROMOTION_TEXT_SECONDARY = `Based on your predictions`;
  BOLAO_ACCA_SUMMARY_CTA_TEXT = "Bet {{#CURRENCY#AMOUNT}} to win {{#CURRENCY#TOTAL_AMOUNT}}";
  BOLAO_ACCA_SUMMARY_CTA_BUTTON = "Bet now";

  BOLAO_CTA_EXACT_SCORE_SURE_ABOUT = "Convinced it’s #GAME_RESULT?"
  BOLAO_CTA_EXACT_SCORE = "Bet on {{#CURRENCY#AMOUNT}} and earn {{#CURRENCY#TOTAL_AMOUNT}}"

  BOLAO_FULL_TIME_RESULT_TO_WIN = "Sure #COMPETITOR_NAME Will Win?"
  BOLAO_FULL_TIME_RESULT_DRAW = "Convinced It'll Be a Draw?"
  BOLAO_FULL_TIME_RESULT_CTA = "Bet on {{#CURRENCY#AMOUNT}} and earn {{#CURRENCY#TOTAL_AMOUNT}}"
  BOLAO_ACC_NO_ODDS = "No ODDS";
  BOLAO_POPUP_DOMINOS_HEADER = "Header";
  BOLAO_POPUP_DOMINOS_TEXT = "Lorem ipsum dolor sit amet, consectetur adipiscing elit";
  BOLAO_POPUP_DOMINOS_APPROVE = "Approve";
  BOLAO_CTA_EXACT_SCORE_BEFORE_ENTER_PREDICTION_HEADER = "Add Your Score";
  BOLAO_CTA_EXACT_SCORE_BEFORE_ENTER_PREDICTION_BUTTON = "Find Out What You Could Win!";

}

