import styles from "../../styles/app.module.scss";
import classNames from "classnames";
import {Image} from "../../../components/image/image";
import {NativeLogoImageRequestParams} from "../../../icons/native-logo";
import {APP_CONST, ROUTE_NAME} from "../../../consts";
import {Link} from "../../../components/link/link";
import {shareState} from "../../share-state";
import {useCurrentPath} from "../../../hooks/use-current-path";

export const AppHeaderTitle = () => {
  const {pathName} = useCurrentPath()
  const isWizardRoute: Boolean = pathName === ROUTE_NAME[APP_CONST.ROUTE.wizard]


  const logoProps = {
    src: new NativeLogoImageRequestParams(),
    className: classNames(styles.game_logo)
  }

  const linkProps = {
    className: classNames(styles.header_title),
    to: !isWizardRoute ? `${shareState.currentDisplayName ? `/${shareState.currentDisplayName}` : ''}${APP_CONST.ROUTE.home}` : '#'
  }

  return <Link {...linkProps}>
    <Image {...logoProps}/>
  </Link>
};

