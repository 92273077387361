import {BaseModel} from "../base";
import {Rate} from "./rate";
import type {Line} from "./line";
import {shareState} from "../../app/share-state";
import {computed} from "mobx";

export class Selection extends BaseModel {
  recommendation: String;
  gameText: String;
  line: Line;
  selectedOptionNum: Number;
  constructor(model, line: Line) {
    super(model);

    this.id = model.LineId;
    this.sportifierGameId = model.Game.ID;
    this.recommendation = model.Recommendation;
    this.gameText = model.GameText;
    this.line = line;
    this.selectedOptionNum = model.SelectedOptionNum;
  }

  get rate(): Rate {
    return this.line.options.find((rate: Rate) => rate.num === this.selectedOptionNum);
  }

  get url(): String {
    return this.line.link || this.line.bookmaker.link;
  }

  get bookmakerId(): Number {
    return this.line.bookmaker.id;
  }

  @computed get totalAmount(): Number {
    return (shareState.settings.betValue * this.rate?.rate).toFixed(0);
  }
}