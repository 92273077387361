import {SelectionListWidgetController} from "../selection-list.widget.controller";
import {SelectionListWidgetState} from "../selection-list.widget.state";
import classNames from "classnames";
import styles from '../selection-list-widget.module.scss';
import {shareState} from "../../../app/share-state";
import {WidgetComposer} from "../../widget.composer";
import {SelectionListWidgetList} from "./selection-list.widget.list";
import {Loader} from "../../../components/loader/loader";
import {SelectionListHeader} from "../../../components/selection-list-header/selection-list-header";
import {SelectionListWidgetTitles} from "./selection-list.widget.titles";
import {observer} from "mobx-react";

export const settings = {
  controller: SelectionListWidgetController,
  state: SelectionListWidgetState
}

export const SelectionListWidget = WidgetComposer(settings)(observer(({controller, currentPageModel, onButtonClick}) => {
  const {state} = controller;
  const {isLoading, entityModel} = state;

  const titlesProps = {
    title: entityModel.title,
    subtitle: entityModel.subtitle,
  }

  const headerProps = {
    controller,
    shouldRenderAutoFillButton: !shareState.isMobileView,
    buttonProps: {
      label: currentPageModel?.textButton,
      isFilled: currentPageModel?.hasSelection,
      onClick: () => onButtonClick && onButtonClick()
    },
  }

  return (
    <div className={classNames(styles.container, {[styles.mobile_view]: shareState.isMobileView})}>
      <SelectionListWidgetTitles {...titlesProps}/>
      <SelectionListHeader {...headerProps}/>
      <Loader showLoader={isLoading}>
        <SelectionListWidgetList controller={controller}/>
      </Loader>
    </div>
  )
}));
