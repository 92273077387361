import classNames from "classnames";
import styles from "../navigation-bar.module.scss";
import {Image} from "../../image/image";
import {shareState} from "../../../app/share-state";
import {kinesisAnalyticsUtils} from "../../../analytics/kinesis/kinesis.analytics-utils";
import {MenuClickKinesisEvent} from "../../../analytics/kinesis/events/navigation-bar/menu.click.kinesis-event";

export const NavigationBarLinkButton = ({iconType, label, page}) => {
  const imageProps = {
    className: classNames(styles.button_logo),
    src: iconType
  }

  const linkProps = {
    href: `https://www.365scores.com${shareState.language.displayName ? `/${shareState.language.displayName}` : ''}/${shareState.tournamentInfo.sportTypeUrlName}/league/${shareState.tournamentInfo.competitionUrlName}-${shareState.tournamentInfo.competitionId}/${page}`,
    className: classNames(styles.button),
    target: "_blank",
    rel: "noreferrer",
    onClick: () => kinesisAnalyticsUtils.send(new MenuClickKinesisEvent({source: page}))
  }

  return <a {...linkProps}>
    <Image {...imageProps}/>
    {label}
  </a>
};
