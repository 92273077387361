import {GamesWidgetController} from "../games.widget.controller";
import {GamesWidgetState} from "../games.widget.state";
import classNames from "classnames";
import styles from '../styles/games-widget.module.scss';
import {observer} from "mobx-react";
import {shareState} from "../../../app/share-state";
import {WidgetComposer} from "../../widget.composer";
import {GamesWidgetHeader} from "./games.widget.header";
import {GamesWidgetGroups} from "./games.widget.groups";

export const settings = {
  controller: GamesWidgetController,
  state: GamesWidgetState
}

export const GamesWidget = WidgetComposer(settings)(observer(({controller, currentPageModel, onButtonClick, shouldConsiderAcca}) => {
  const {state} = controller;
  const {isOtherUser, isLastGameKeyboardOpen} = state;

  const headerProps = {
    controller,
    currentPageModel,
    onButtonClick,
  }

  return (
    <div className={
      classNames(
        styles.container,
        {[styles.mobile_view]: shareState.isMobileView},
        {[styles.popup_view]: isOtherUser},
        {[styles.last_game_keyboard_open]: isLastGameKeyboardOpen}
      )
    }>
      <GamesWidgetHeader {...headerProps}/>
      <GamesWidgetGroups controller={controller} shouldConsiderAcca={shouldConsiderAcca}/>
    </div>
  )
}));
