import '../styles/app.module.scss';
import {Route, Switch, useHistory} from "react-router-dom";
import HomePageElement from "../../pages/home-page/home.page.element";
import {APP_CONST} from "../../consts";
import classNames from "classnames";
import styles from "../styles/app.module.scss";
import WinningTeamPageElement from "../../pages/winning-team-page/winning-team.page.element";
import TopScorerPageElement from "../../pages/top-scorer-page/top-scorer.page.element";
import PredictionPageElement from "../../pages/prediction-page/prediction-page.element";
import LeaderboardPageElement from "../../pages/leaderboard-page/leaderboard-page.element";
import GameRulesPageElement from "../../pages/game-rules-page/game-rules-page.element";
import WizardPageElement from "../../pages/wizard-page/wizard.page.element";
import InfluencersPageElement from "../../pages/influencers-page/influencers-page.element";
import {observer} from "mobx-react";
import {shareState} from "../share-state";
import {useEffect} from "react";

export const AppRoutes = observer(({controller}) => {
  const {state: {contentPageOffset}} = controller;
  const history = useHistory();

  useEffect(() => {
    if(shareState.isWebView) {
      const screenNameMap = {
        "profile": `${prefix}${APP_CONST.ROUTE.home}`,
        "my_games": `${prefix}${APP_CONST.ROUTE.prediction}`,
        "group": `${prefix}${APP_CONST.ROUTE.leaderboard}`,
        "select_winner_team": `${prefix}${APP_CONST.ROUTE.winningTeam}`,
        "show_winner_team": `${prefix}${APP_CONST.ROUTE.winningTeam}`,
        "select_top_scorer": `${prefix}${APP_CONST.ROUTE.topScorer}`,
        "show_top_scorer":`${prefix}${APP_CONST.ROUTE.topScorer}`
      }

      window.openScreen = (name, groupId) => {
        history.push(`${screenNameMap[name]}${groupId > 0 ? `/${groupId}` : ''}`)
      }

      if(shareState.nativeGameConfig.screen?.name) {
        const {name, groupId} = shareState.nativeGameConfig.screen;
        history.push(`${screenNameMap[name]}${groupId > 0 ? `/${groupId}` : ''}`)
      }
    }
  }, []);

  const containerProps = {
    className: classNames(styles.route_page),
    style: {
      minHeight: window.innerHeight - contentPageOffset
    },
  }

  const prefix: String = `${shareState.currentDisplayName ? `/${shareState.currentDisplayName}` : ''}`;


  return (
    <div {...containerProps}>
      <div className={styles.route_page_container}>
        <Switch>
          <Route
            path={`${prefix}${APP_CONST.ROUTE.home}`}
            exact component={() => <HomePageElement/>}/>
          <Route
            path={`${prefix}${APP_CONST.ROUTE.wizard}`}
            component={() => <WizardPageElement/>}/>
          <Route
            path={`${prefix}${APP_CONST.ROUTE.topScorer}`}
            component={() => <TopScorerPageElement/>}/>
          <Route
            path={`${prefix}${APP_CONST.ROUTE.winningTeam}`}
            component={() => <WinningTeamPageElement/>}/>
          <Route
            path={`${prefix}${APP_CONST.ROUTE.prediction}`}
            component={() => <PredictionPageElement/>}/>
          <Route
            path={`${prefix}${APP_CONST.ROUTE.rules}`}
            component={() => <GameRulesPageElement/>}/>
          <Route
            path={`${prefix}${APP_CONST.ROUTE.leaderboard}/:groupId?`}
            component={() => <LeaderboardPageElement/>}/>
          <Route
            path={`${prefix}${APP_CONST.ROUTE.leagues}/:groupId?`}
            component={() => <InfluencersPageElement/>}/>
        </Switch>
      </div>
    </div>
  );
});
