import classNames from "classnames";
import {Image} from "../image/image";

export const DropdownHeaderInfo = ({selectedItem, logoIncluded, styles, hideHeaderLogo}) => {
  const imageProps = {
    className: classNames(styles.item_logo),
    src: selectedItem?.imageURL
  }

  return (
   <div className={classNames(styles.item_info)}>
     {
       logoIncluded && !hideHeaderLogo ? <Image {...imageProps}/> : null
     }
     <bdi className={classNames(styles.item_text)}>{selectedItem?.name}</bdi>
   </div>
  );
};
