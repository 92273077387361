import {ImageRequestParams} from "../../models/image/image-request-params";

export class CompetitorFlagImageRequestParams extends ImageRequestParams {
  constructor(id: Number, width: Number = 40, height: Number = 24) {
    super({width, height});

    this.id = id;
    this._resourceName = `bolao24/Flags/${id}`;
    this.ratio = 3;
  }

  get fallbackResourceName(): null {
    return 'Competitors:default1.png';
  }
}
