import classNames from "classnames";
import styles from "../selection-list-widget.module.scss";
import {observer} from "mobx-react";
import {debounce} from "../../../infrastructure/functions/debounce";
import {SELECTION_LIST_WIDGET} from "../selection-list.widget.consts";
import {Image} from "../../../components/image/image";
import {SearchImageRequestParams} from "../../../icons/search";
import {shareState} from "../../../app/share-state";
import {COLORS} from "../../../styles/colors";
import {useState} from "react";

export const SelectionListWidgetInput = observer(({controller}) => {
  const {state} = controller;
  const {entityModel} = state;
  const {searchPlaceholder, hasSearch} = entityModel;

  const [iconDisplay, setIconDisplay] = useState(true)
  const onSearchPlayers = (e) => {
    if(e.target.value.length >= SELECTION_LIST_WIDGET.minimumCharacters) {
      controller.searchItemsByQuery(e.target.value)
    } else if(e.target.value === '') {
      controller.getAllItems()
    }
  }

  const searchLogoProps = {
    className: classNames(styles.input_logo),
    src: new SearchImageRequestParams(20,20, COLORS[shareState.theme].disable)
  }

  const inputProps = {
    placeholder: searchPlaceholder,
    className: classNames(styles.input),
    onChange: debounce((e) => onSearchPlayers(e), SELECTION_LIST_WIDGET.debounceDelay),
    onFocus: () => setIconDisplay(false),
    onBlur: (e) => e.target.value.length === 0 && setIconDisplay(true),
  }

  return (
    hasSearch ? <div className={classNames(styles.input_container)}>
      <input {...inputProps}/>
      {
        iconDisplay ? <Image {...searchLogoProps}/> : null
      }
    </div> : null
  );
});


