import {RequestModel} from "../../../request";
import {REQUEST} from "../../../requests.consts";
import {shareState} from "../../../../../app/share-state";

export class PostRequestChangeGroupPictureModel extends RequestModel {
  groupId: Number;
  groupImage: String;

  constructor({groupImage, groupId}) {
    super(REQUEST.CATEGORY.groups, REQUEST.ACTION.GROUPS.changeGroupPicture, {}, true, false, true, true);

    this.apiEnvironment = shareState.environment.baseUrl;
    this.method = REQUEST.METHOD_TYPE.post;
    this.groupId = groupId;
    this.groupImage = groupImage;
  }

  get body() {
    const lang = {"lang": shareState.language.id}
    const groupId = this.groupId ? {"groupID": this.groupId} : {}
    const groupImage = this.groupImage ? {base64Image: this.groupImage} : {}

    return {...groupId, ...groupImage, ...lang};
  }
}
