import classNames from "classnames";
import styles from "./app-popups.module.scss";
import {shareState} from "../../share-state";
import {observer} from 'mobx-react';
import {Popup} from "../../../components/popup/popup";
import {Image} from "../../../components/image/image";
import {useHistory} from "react-router-dom";
import {SadEmojiImageRequestParams} from "../../../icons/sad-emoji";
import {InvitationFailedBackgroundImageRequestParams} from "../../../icons/assets/invitation-failed-background";

export const AppInvitationFailedPopup = observer(({controller}) => {
  const history = useHistory();

  const popupProps = {
    className: classNames(styles.popup_container, {[styles.mobile_view]: shareState.isMobileView}),
    style: {
      backgroundImage: `url(${new InvitationFailedBackgroundImageRequestParams(shareState.isMobileView)})`
    },
    onClose: () => {
      controller.closeInvitationFailedPopup();
      history((`${shareState.currentDisplayName ? `/${shareState.currentDisplayName}` : ''}`))
    },
  }

  const imageProps = {
    className: classNames(styles.group_logo),
    src: new SadEmojiImageRequestParams(88, 88)
  }

  const buttonProps = {
    className: classNames(styles.popup_button),
    onClick: () => {
      controller.closeInvitationFailedPopup();
      history(`${shareState.currentDisplayName ? `/${shareState.currentDisplayName}` : ''}`)
    },
  }

  return (
    shareState.shouldRenderInvitationFailedPopup ?
      <Popup {...popupProps}>
        <div>{shareState.termsUtils.terms.WC_2022_ENVIROMENT_ERROR_MESSAGE_WEB}</div>
        <Image {...imageProps}/>
        <div {...buttonProps}>{shareState.termsUtils.terms.WC_2022_ENVIROMENT_ERROR_MESSAGE_WEB_CONFIRM_BUTTON}</div>
      </Popup> : null
  );
});


