import React, {Suspense} from "react";
import {LoadingIndicator} from "../../components/loading-indicator/loading-indicator";
import {useParams} from "react-router-dom";

const InfluencersPage = React.lazy(() => import("./influencers-page" /* webpackChunkName: "influencers.page" */ ));

const InfluencersPageElement = ({location}) => {
  const {groupId} = useParams()

  return (
    <Suspense fallback={<LoadingIndicator/>}>
      <InfluencersPage location={location} groupId={groupId && Number(groupId)}/>
    </Suspense>
  )
};

export default InfluencersPageElement;