import {BaseModel} from "../base";
import {CompetitorModel} from "../competitor";
import {GameScoreModel} from "./game.score";
import {GameBetModel} from "./game.bet";
import {GameBetOutcomesModel} from "./game.bet.outcome";
import {APP_CONST} from "../../consts";
import {shareState} from "../../app/share-state";
import {datetimeUtils} from "../../infrastructure/utils/datetime-utils";
import {AddonModel} from "../addon";
import {makeObservable, observable, computed} from "mobx";
import {REQUEST} from "../request/requests.consts";

export class GameModel extends BaseModel {
  id: Number;
  @observable gtd: Number;
  @observable stid: Number;
  sportifierGameId: Number;
  stageNum: Number;
  status: Number;
  compGroupName: String;
  compGroupNumber: Number;
  _startTime: String;
  isBetable: Boolean
  competitors: Array<CompetitorModel>;
  @observable scores: GameScoreModel;
  gameBet: GameBetModel;
  gameBetOutcomes: GameBetOutcomesModel;
  @observable addon: AddonModel;

  constructor(model, addon: AddonModel) {
    super(model);

    this.id = model.gameID;
    this.gtd = model.gtd;
    this.stid = model.stid;
    this.sportifierGameId = model.sportifierGameID;
    this.stageNum = model.stageNum;
    this.status = model.status;
    this.compGroupName = model.compGroupName;
    this.compGroupNumber = model.compGroupNumber;
    this._startTime = model.startTime
    this.isBetable = model.isBetable;
    this.competitors = model.competitors.map((competitor: CompetitorModel) => new CompetitorModel(competitor));
    this.scores = new GameScoreModel(model.scores);
    this.gameBet = model.gameBet ? new GameBetModel(model.gameBet) : null;
    this.gameBetOutcomes = model.gameBetOutcomes ? new GameBetOutcomesModel(model.gameBetOutcomes) : null;
    this.addon = addon;

    makeObservable(this);
  }

  get startTime(): Date {
    const date = new Date(this._startTime);

    return new Date(date.setMinutes(date.getMinutes() - date.getTimezoneOffset()));
  }

  @computed get topStatus(): String {
    return `${this.compGroupName}`;
  }

  @computed get bottomStatus() {
    if (this.isLive || this.isEnded) {
      return shareState.termsUtils.terms.WC_2022_POINTS;
    }

    return `${this.formatTime(this.startTime)}`;
  }

  @computed get isSchedule(): Boolean {
    return this.status === APP_CONST.GAME_STATUS.schedule;
  }

  @computed get isLive(): Boolean {
    return this.status === APP_CONST.GAME_STATUS.live;
  }

  @computed get isEnded(): Boolean {
    return this.status === APP_CONST.GAME_STATUS.ended;
  }

  get gameDate(): Date {
    return new Date(this.startTime);
  }

  get fullDate(): String {
    const fullDate: String = `${this.gameDate.getMonth() + 1}/${this.gameDate.getDate()}/${this.gameDate.getFullYear()}`;

    return fullDate
  }

  formatDate(startTime: Date) {
    return datetimeUtils.toShortNamedDate(startTime, false);
  }

  formatTime(startTime: Date) {
    return datetimeUtils.toShortTime(startTime, false);
  }

  get link(): String {
    let linkPrefix: String = `https://${REQUEST.domain}/`

    if(shareState.language.id !== 1) {
      linkPrefix += `${shareState.language.displayName}/`
    }

    const link: String = `${linkPrefix}${shareState.tournamentInfo.sportTypeUrlName}/match/${shareState.tournamentInfo.competitionUrlName}-${shareState.tournamentInfo.competitionId}/${this.teamsNameForUrl}`;


    return link;
  }
  get teamsNameForUrl(): String{
    let names = this.competitors.map(competitor => competitor.nameForUrl).sort((a, b) => this.compareFn(a, b));
    let ids = this.competitors.map(competitor => competitor.id).sort((a, b) => a - b);
    const teamsNameUrl: String = `${names[0]}-${names[1]}-${ids[0]}-${ids[1]}-${shareState.tournamentInfo.competitionId}#id=${this.sportifierGameId}`;

    return teamsNameUrl;
  }

  compareFn = (a: String, b: String) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  }
}
