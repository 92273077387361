import classNames from "classnames";
import styles from "../styles/login.module.scss";
import {observer} from "mobx-react";
import {LoginWidgetWelcomeContent} from "./login.widget.welcome-content";
import {shareState} from "../../../app/share-state";

export const LoginWidgetWelcomePage = observer(({controller}) => {
  const {state} = controller;
  const {shouldRenderWelcomePage} = state;

  return (
    shouldRenderWelcomePage ? <div className={classNames(styles.page)}>
      <div
        className={classNames(styles.welcome_description)}>{shareState.termsUtils.terms.WC_2022_WELCOME_TAGLINE}</div>
      <LoginWidgetWelcomeContent controller={controller}/>
    </div> : null
  )
});
