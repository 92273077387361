import {WidgetComposer} from "../widget.composer";
import {AccaWidgetController} from "./acca-widget.controller";
import {AccaWidgetState} from "./acca-widget.state";
import classNames from "classnames";
import styles from './acca-widget.module.scss';
import {Popup} from "../../components/popup/popup";
import {MobileHeaderImageRequestParams} from "../../icons/background/mobile-header";
import {AccaWidgetBets} from "./bets/acca-widget.bets";
import {AccaWidgetBetSlip} from "./bet-slip/acca-widget.bet-slip";
import {ACCA_WIDGET} from "./acca-widget.consts";
import {observer} from "mobx-react";

export const settings = {
  controller: AccaWidgetController,
  state: AccaWidgetState
}

export const AccaWidget = WidgetComposer(settings)(observer(({handleClose, controller}) => {
  const {state: {page}} = controller;
  const popupProps = {
    className: classNames(styles.popup_container),
    onClose: handleClose,
    style: {
      backgroundImage: `url(${new MobileHeaderImageRequestParams()})`
    }
  }
  return (
    <Popup {...popupProps}>
      {
        page === ACCA_WIDGET.pages.bets ? <AccaWidgetBets controller={controller}/> : null
      }
      {
        page === ACCA_WIDGET.pages.betSlip ? <AccaWidgetBetSlip controller={controller}/> : null
      }
    </Popup>
  )
}));
