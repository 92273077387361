import {WidgetController} from "../../controllers/widget.controller";
import {LogoutWidgetState} from "./logout.widget.state";
import {FirebaseAuth} from "../../models/firebase/firebase-auth";
import {shareState} from "../../app/share-state";
import {APP_CONST} from "../../consts";
import {LOGIN} from "../login-widget/login.widget.consts";
import {localStorageUtils} from "../../infrastructure/utils/locale-storage-utils";

export class LogoutWidgetController extends WidgetController {
  state: LogoutWidgetState;

  async init() {
    super.init()

    this.state.isLoading = false
  }

  async signOut() {
    const firebaseAuth = new FirebaseAuth();

    await firebaseAuth.signOut().then( () => {
      shareState.user = null
      shareState.welcomePage = LOGIN.PAGE.registration;
      localStorageUtils.removeItem(APP_CONST.LOCAL_STORAGE_KEYS.deviceId);
    })
  }
}
