import {observable, makeObservable} from 'mobx';
import {BaseModel} from "./base";
import {RankingModel} from "./ranking";
import {GameBetModel} from "./game/game.bet";

export class UserModel extends BaseModel {
  userId: Number;
  name: String;
  @observable masterGroupId: Number;
  imageURL: String;
  @observable rank: Number;
  @observable score: Number;
  @observable topScorerId: Number;
  @observable topScorerName: String;
  @observable winnerTeamId: Number;
  @observable winnerTeamName: String;
  availableGameBets: Number;
  showWizard: Boolean;
  @observable isTournamentStarted: String;
  @observable allowTournamentBets: String;
  @observable isRemoving: Boolean;
  @observable totalScore: Number;
  @observable userAgreement: Number;
  @observable userAgreementPopupDisplayed: Number;
  preventKick: Boolean;
  isAdmin: Boolean;

  constructor(model) {
    super(model);

    makeObservable(this);

    this.userId = model.userId || model.userID;
    this.name = model.name;
    this.imageURL = model.imageURL?.replace('http://', 'https://').replace('imagescache', 'imagecache') || 'https://imagecache.365scores.com/image/upload/f_png,c_limit,q_auto:eco/default.png';
    this.masterGroupId = model.masterGroupID;
    this.rank = model.rank
    this.score = model.score || model.totalScore;
    this.topScorerId = model.topScorerID;
    this.topScorerName = model.topScorerName;
    this.winnerTeamId = model.winnerTeamID;
    this.winnerTeamName = model.winnerTeamName;
    this.availableGameBets = model.availableGameBets;
    this.showWizard = model.showWizard;
    this.isTournamentStarted = model.isTournamentStarted;
    this.allowTournamentBets = model.allowTournamentBets;
    this.ranking = model.ranking ? new RankingModel(model.ranking) : null;
    this.gameBets = model.gameBets?.map((bet: GameBetModel) => new GameBetModel(bet));
    this.preventKick = model.preventKick;
    this.isAdmin = model.isAdmin;
    this.isRemoving = false;
    this.totalScore = model.totalScore;
    this.userAgreementPopupDisplayed = model.userAgreementPopupDisplayed;
    this.userAgreement = model.userAgreement;
  }

  get isUserRemovable(): Boolean {
    return !this.preventKick && !this.isAdmin;
  }

  get firstGameBets(): String {
    let label: String = '?-?';
    if (this.gameBets?.length > 0) {
      const homeBet: Number = this.gameBets[0]?.selection?.home !== undefined ? this.gameBets[0]?.selection?.home : '?';
      const awayBet: Number = this.gameBets[0]?.selection?.away !== undefined ? this.gameBets[0]?.selection?.away : '?';
      label = `${homeBet}-${awayBet}`;
    }

    return label;
  }

  get secondGameBets(): String {
    let label: String = '?-?';
    if (this.gameBets?.length > 1) {
      const homeBet: Number = this.gameBets[1]?.selection?.home !== undefined ? this.gameBets[1]?.selection?.home : '?';
      const awayBet: Number = this.gameBets[1]?.selection?.away !== undefined ? this.gameBets[1]?.selection?.away : '?';
      label = `${homeBet}-${awayBet}`;
    }

    return label;
  }
}
