import classNames from "classnames";
import styles from "../../styles/app.module.scss";
import {observer} from "mobx-react";
import {useHistory} from 'react-router-dom';
import {shareState} from "../../share-state";
import {Image} from "../../../components/image/image";
import {BackImageRequestParams} from "../../../icons/back";
import {useEffect} from "react";

export const AppHeaderBack = observer(({controller, shouldRenderBack}) => {
  const {state} = controller;
  const {currentPageModel} = state;
  const {id} = currentPageModel;
  const history = useHistory();

  const handleBack = () => {
    if (shareState.wizard.isVisible) {
      controller.changeWizardPage(id - 1);
    } else if (shareState.isWebView && history.location.pathname === "/") {
      shareState.nativeFunctionsUtils.callFunction("exitClicked")
    } else if (shareState.isWebView && history.location.pathname !== "/") {
      history.push("/");
    } else if (history.length > 1) {
      history.goBack();
    }

  }
  useEffect(() => {
    window.handleBack = handleBack;
  }, []);

  const backProps = {
    src: new BackImageRequestParams(11, 20),
    className: classNames(styles.back),
    onClick: handleBack
  };


  return shouldRenderBack && shareState.user ? <Image {...backProps}/> : <div/>;
});

