import {BaseModel} from "./base";

export class AddonModel extends BaseModel {
  sportifierGameId: Number;
  bookmakerId: Number;
  predictionTextHtml: String;
  referralLinkTextHtml: String;
  referralLink: String;

  constructor(model) {
    super(model);

    this.sportifierGameId = model.SportifierGameId
    this.bookmakerId = model.BookmakerId
    this.predictionTextHtml = model.PredictionTextHtml
    this.referralLinkTextHtml = model.ReferralLinkTextHtml?.boldSubString()
    this.referralLink = model.ReferralLink
  }
}
