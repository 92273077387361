import React from "react";
import classNames from "classnames";
import styles from "./entity-info.module.scss";
import {EntityInfoImage} from "./entity-info.image";
import {EntityInfoInput} from "./entity-info.input";
import {shareState} from "../../app/share-state";

export const EntityInfo = ({item, onChangeName, onImageUploaded}) => {
  const imageProps = {
    item,
    onImageUploaded: (fileUploaded) => onImageUploaded(fileUploaded)
  }

  const inputProps = {
    item,
    onChangeName,
  }

  return (
    <div className={classNames(styles.group_info, {[styles.mobile_view]: shareState.isMobileView})}>
      <EntityInfoImage {...imageProps}/>
      <EntityInfoInput {...inputProps}/>
    </div>
  )
};


