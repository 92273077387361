import classNames from "classnames";
import styles from "../profile-header.module.scss";
import {Image} from "../../image/image";
import {observer} from "mobx-react";
import {PlusImageRequestParams} from "../../../icons/plus";
import {ProfileHeaderButtonIcon} from "./profile-header.button-icon";
import {EditImageRequestParams} from "../../../icons/edit";
import {Link} from "../../link/link";
import {shareState} from "../../../app/share-state";
import {kinesisAnalyticsUtils} from "../../../analytics/kinesis/kinesis.analytics-utils";
import {ArrowImageRequestParams} from "../../../icons/arrow";

export const ProfileHeaderButtonImage = observer(({model}) => {
  const {user, isOtherUser, hasNoSelection, iconClassName, imageSource, source, wizardPage, isRouteActive} = model
  const {allowTournamentBets} = user;
  const isSelectionOpen: Boolean = allowTournamentBets && !isOtherUser;

  const imgProps = {
    src: imageSource,
    referrerPolicy: "no-referrer",
    className: classNames(
      styles.header_button_logo,
      {[iconClassName]: hasNoSelection}
    )
  }

  const linkProps = {
    to: (!shareState.wizard.isVisible && !isOtherUser) ? `${shareState.currentDisplayName ? `/${shareState.currentDisplayName}` : ''}${source}` : null,
    onClick: () => {
      kinesisAnalyticsUtils.send(model.clickEventModel)

      if(shareState.wizard.isVisible) {
        shareState.wizard.currentPage = wizardPage;
      }
    },
    className: classNames(
      styles.header_button_image,
      {[styles.no_selection]: hasNoSelection},
      {[styles.active_icon]: isRouteActive},
      {[styles.close_selection]: !isSelectionOpen},
    )
  }

  return (
    <Link {...linkProps}>
      {
        isSelectionOpen ? (hasNoSelection ? <ProfileHeaderButtonIcon iconType={PlusImageRequestParams}/> :
          <ProfileHeaderButtonIcon iconType={EditImageRequestParams}/>) : isOtherUser ? null : <ProfileHeaderButtonIcon iconType={ArrowImageRequestParams}/>
      }
      <Image {...imgProps}/>
    </Link>
  );
});

