import {BaseModel} from "../base";
import {GameSetSelection} from "./game.set-selection";

export class GameSetBet extends BaseModel {
  gameId: Number;
  selection: GameSetSelection;

  constructor(model) {
    super(model);

    this.gameID = model.gameId;
    this.selection = new GameSetSelection(model.selection);
  }
}