import {KinesisEvent} from "../kinesis.event";
import {KINESIS} from "../../kinesis.consts";

export class SettingsImageChangeKinesisEvent extends KinesisEvent {
  constructor(properties) {
    super(
      KINESIS.CATEGORY.bolao,
      KINESIS.ACTION.settings,
      KINESIS.LABEL.image,
      KINESIS.VALUE.change,
      properties
    );
  }

  getProperties(params) {
    const props = super.getProperties()

    return {
      ...props,
      group_id: params?.groupId,
      is_group: Number(Boolean(params?.groupId))
    }
  }
}
