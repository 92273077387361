import React, {Suspense} from "react";
import "./game-rules-page.module.scss";
import {LoadingIndicator} from "../../components/loading-indicator/loading-indicator";

const GameRulesPage = React.lazy(() => import("./game-rules-page" /* webpackChunkName: "game-rules.page" */ ));

const GameRulesPageElement = () => {
  return (
    <Suspense fallback={<LoadingIndicator/>}>
      <GameRulesPage/>
    </Suspense>
  )
};

export default GameRulesPageElement;