import {RequestModel} from "../../../request";
import {REQUEST} from "../../../requests.consts";
import {shareState} from "../../../../../app/share-state";

export class PostRequestSetWinnerTeamModel extends RequestModel {
  teamId: Number;

  constructor(teamId) {
    super(REQUEST.CATEGORY.bets, REQUEST.ACTION.BETS.setWinnerTeam, {}, true, false, true, true);

    this.apiEnvironment = shareState.environment.baseUrl;
    this.method = REQUEST.METHOD_TYPE.post;
    this.teamId = teamId
  }

  get body() {
    const params = this.teamId ? {'winnerTeamID': this.teamId} : {}

    return {...params};
  }
}
