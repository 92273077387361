import classNames from "classnames";
import styles from "../selection-list-widget.module.scss";
import {observer} from "mobx-react";
import {Image} from "../../../components/image/image";

export const SelectionListWidgetItemCheckbox = observer(({isActive, entityModel, selectedClassName}) => {
  const {logoClassName, selectedLogo, deselectedLogo} = entityModel

  const selectLogoProps = {
    className: classNames(logoClassName),
    src: isActive ? selectedLogo : deselectedLogo
  }

  return (
    <div className={classNames(selectedClassName || styles.selection_logo_container, {[styles.selected]: isActive})}>
      <Image {...selectLogoProps}/>
    </div>
  );
});


