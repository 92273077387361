import {WidgetComposer} from "../widget.composer";
import {LogoutWidgetController} from "./logout.widget.controller";
import {LogoutWidgetState} from "./logout.widget.state";
import classNames from "classnames";
import styles from './logout.module.scss';
import {shareState} from "../../app/share-state";
import {kinesisAnalyticsUtils} from "../../analytics/kinesis/kinesis.analytics-utils";
import {SettingsLogoutClickKinesisEvent} from "../../analytics/kinesis/events/settings/settings.logout.click.kinesis-event";

export const settings = {
  controller: LogoutWidgetController,
  state: LogoutWidgetState
}

export const LogoutWidget = WidgetComposer(settings)(({controller, onClose}) => {
  const googleSignOutButtonProps = {
    className: classNames(styles.container),
    onClick: () => {
      kinesisAnalyticsUtils.send(new SettingsLogoutClickKinesisEvent())
      controller.signOut()
      onClose && onClose()
    }
  }

  return <div {...googleSignOutButtonProps}>{shareState.termsUtils.terms.WC_2022_SETTING_LOGOUT}</div>
});
