import classNames from "classnames";
import styles from '../../styles/settings-widget.module.scss';
import {observer} from "mobx-react";
import {SettingsWidgetIcons} from "./settings.widget.icons";
import {SettingsWidgetItemInfo} from "../settings.widget.item.info";
import {MainGroupImageRequestParams} from "../../../../icons/main-group";
import {useState} from "react";
import {SettingsWidgetExitMode} from "./settings.widget.exit-mode";
import {shareState} from "../../../../app/share-state";

export const SettingsWidgetGroupItem = observer(({controller, group}) => {
  const [onExitMode, setOnExitMode] = useState(false)

  const {state} = controller;
  const {mainGroupId} = state;

  const exitProps = {
    controller,
    group,
    onClose: () => setOnExitMode(false)
  }

  const iconProps = {
    controller,
    group,
    onLeaveClick: () => setOnExitMode(true)
  }

  const infoProps = {
    controller,
    shouldRenderBadge: group.id === mainGroupId,
    icon: new MainGroupImageRequestParams(16,16),
    item: group,
    activeClassName: onExitMode ? styles.item_exit_mode : '',
    label: onExitMode ? shareState.termsUtils.terms.WC_2022_LEAVE_GROUP_APPROVE : group.name
  }

  return (
    <div className={classNames(styles.item, {[styles.item_exit_mode]: onExitMode})}>
      <SettingsWidgetItemInfo {...infoProps}/>
      {
        onExitMode ? <SettingsWidgetExitMode {...exitProps}/> : <SettingsWidgetIcons {...iconProps}/>
      }
    </div>
 )
});
