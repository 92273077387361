import {BaseModel} from "../base";
import {GameSelectionModel} from "./game.selection";
import {COLORS} from "../../styles/colors";
import {shareState} from "../../app/share-state";

export class GameBetModel extends BaseModel {
  id: Number;
  gainedPoints: Number;
  betOutcome: Number;
  selection: GameSelectionModel;

  constructor(model) {
    super(model);

    this.id = model.gameID;
    this.gainedPoints = model.gainedPoints;
    this.betOutcome = model.betOutcome;
    this.selection = model.selection ? new GameSelectionModel(model.selection) : null;
  }

  get betOutcomeColor() {
    let color = null;

    if(this.betOutcome === 0) {
      color = COLORS[shareState.theme].live
    } else if(this.betOutcome === 2) {
      color = COLORS[shareState.theme].partial_success
    } else if(this.betOutcome === 3) {
      color = COLORS[shareState.theme].success
    }

    return color
  }
}
