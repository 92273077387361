import {BaseModel} from "../base";
import {RequestModel} from "./request";
import {REQUEST} from "./requests.consts";
import {makeObservable, observable} from 'mobx';

export class DeltaRequestModel extends RequestModel {
  startDelta: Boolean;
  intervalId: Number;
  @observable interval: Number;

  constructor(
    category: String,
    action: String,
    requestParams: BaseModel,
    isPostRequest,
    includeLanguage: Boolean,
    includeAuthorized: Boolean,
    includeContentType: Boolean
  ) {
    super(category, action, requestParams, isPostRequest, includeLanguage, includeAuthorized, includeContentType);

    makeObservable(this)
    this.startDelta = false;
    this.interval = REQUEST.UPDATE_TIME_INTERVALS.default;
    this.method = REQUEST.METHOD_TYPE.get;
  }
}
