import {computed, makeObservable, observable} from "mobx";
import {UserModel} from "../models/user";
import {LanguageModel} from "../models/language";
import {WizardDisplayModel} from "../models/wizard-display";
import {TermsUtils} from "../infrastructure/utils/terms-utils";
import {JoinGroupDialog} from "../models/join-group-dialog";
import {Environment} from "../models/environment";
import {LOGIN} from "../widgets/login-widget/login.widget.consts";
import {NativeFunctionsUtils} from "../infrastructure/utils/native-functions-utils";
import type {NativeGameConfig} from "../models/native-game-config";
import {APP_CONST} from "../consts";
import type {GameSettings} from "../models/game-settings";
import {localStorageUtils} from "../infrastructure/utils/locale-storage-utils";
import type {TournamentInfo} from "../models/tournament-info";
import {LANGUAGES} from "../languages";

class ShareState {
  @observable user: UserModel;
  @observable userCountryId: Number;
  @observable language: LanguageModel;
  @observable wizard: WizardDisplayModel;
  @observable joinGroupDialog: JoinGroupDialog;
  @observable userAgreementPopup: Boolean;
  @observable isMobileView: Boolean;
  @observable termsUtils: TermsUtils;
  @observable environment: Environment;
  @observable _currentDisplayName: String;
  @observable rankingRoute: String;
  @observable isAppVisible: String;
  @observable shouldRenderInvitationFailedPopup: Boolean;
  @observable onLoginProcess: Boolean;
  @observable welcomePage: Number = LOGIN.PAGE.welcome;
  @observable nativeGameConfig: NativeGameConfig;
  @observable settings: GameSettings;
  @observable tournamentInfo: TournamentInfo;
  @observable isACCAVisible: Boolean;
  accaVisibilityCallback: Map;
  _appType: Number;
  nativeFunctionsUtils: NativeFunctionsUtils;
  websiteVisitorId: String;

  constructor() {
    this.termsUtils = new TermsUtils();
    this.wizard = new WizardDisplayModel();
    this.joinGroupDialog = new JoinGroupDialog();
    this.environment = new Environment();
    this.userCountryId = -1;
    this.shouldRenderInvitationFailedPopup = false;
    this._currentDisplayName = '';
    this._appType = APP_CONST.PLATFORMS.web;
    this.userAgreementPopup = false;
    this.accaVisibilityCallback = new Map();

    makeObservable(this);

    this.nativeFunctionsUtils = new NativeFunctionsUtils();
  }

  get currentDisplayName() {
    return this._currentDisplayName;// || this.language?.displayName;
  }

  get appType(): APP_CONST.PLATFORMS {
    return this.nativeGameConfig?.appType || this._appType;
  }

  getBase64Image = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();

    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data)
      }
    });
  }

  get isWebView(): Boolean {
    return this.appType === APP_CONST.PLATFORMS.android || this.appType === APP_CONST.PLATFORMS.ios;
  }

  get deviceId() {
    let deviceId = this.nativeGameConfig?.deviceId || localStorageUtils.getItem(APP_CONST.LOCAL_STORAGE_KEYS.deviceId);

    if (!deviceId) {
      deviceId = Math.random().toString(36);
    }

    localStorageUtils.setItem(APP_CONST.LOCAL_STORAGE_KEYS.deviceId, deviceId);

    return deviceId
  }

  get theme(): String {
    return this.nativeGameConfig?.theme || this.settings?.theme || "default";
  }

  get termsCategory() {
    return (`BOLAO_${this.environment.name}`).toUpperCase();
  }

  @computed get languages(): Array<LanguageModel> {
    const langs = Object.values(LANGUAGES);
    const languages: Array<LanguageModel> = langs.map((lang: LanguageModel) => new LanguageModel(lang))
      .filter((lang: LanguageModel) => !lang.displayInCountries || lang.displayInCountries.includes(parseInt(this.userCountryId)));

    return languages;
  }

  get isACCAActive(): Boolean {
    return this.settings.featuresMap.get(APP_CONST.FEATURES.acca) && (shareState.settings.topBookmakerId > 0);
  }

  get isExactScoreAvailable(): Boolean {
    return this.settings.featuresMap.get(APP_CONST.FEATURES.exactScore) && (shareState.settings.topBookmakerId > 0);
  }

  changeACCAVisibility(isVisible) {
    this.isACCAVisible = isVisible;

    Array.from(this.accaVisibilityCallback, ([name, value]) => value).forEach(callback => callback?.(isVisible));
  }

  registerToAccaVisibilityCallback(name, callback) {
    this.accaVisibilityCallback.set(name, callback);
  }
}

export const shareState = new ShareState();
window.BOLAO = shareState;
