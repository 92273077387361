import classNames from "classnames";
import styles from "../../styles/app.module.scss";
import {observer} from "mobx-react";
import {shareState} from "../../share-state";
import {AppHeaderBack} from "./app.header.back";
import {AppHeaderTitle} from "./app.header.title";
import {AppHeaderSideButton} from "./app.header.side-button";
import {APP_CONST, ROUTE_NAME} from "../../../consts";
import {useCurrentPath} from "../../../hooks/use-current-path";
import {CoverDesktopImageRequestParams} from "../../../icons/background/cover-desktop";
import {AppHeaderAccaButton} from "./app.header.acca-button";

export const AppHeader = observer(({controller, isLoginPage}) => {
  const {state: {shouldRenderACCAButton}} = controller;
  const {pathName} = useCurrentPath()
  const isHomeRoute: Boolean = pathName === ROUTE_NAME[APP_CONST.ROUTE.home]
  const isWizardRoute: Boolean = pathName === ROUTE_NAME[APP_CONST.ROUTE.wizard]
  const shouldRenderBack: Boolean = isWizardRoute ? shareState.wizard.currentPage !== 0 : (shareState.isWebView || !isHomeRoute)

  const backProps = {
    controller,
    shouldRenderBack
  }

  const sideButtonProps = {
    controller,
    isLoginPage
  }

  const accaButtonProps = {
    controller,
    isLoginPage
  }

  const containerProps = {
    className: classNames(styles.header, {[styles.acca]: shouldRenderACCAButton}),
    style: !shareState.isMobileView ? {
      backgroundImage: `url(${new CoverDesktopImageRequestParams()})`
    } : null
  }

  return (
    shareState.isMobileView ? <div {...containerProps}>
        <div className={classNames(styles.buttons_container)}>
          <div className={styles.button_container}>
            <AppHeaderBack {...backProps}/>
          </div>
          <div className={styles.button_container}/>
        </div>
        <AppHeaderTitle controller={controller}/>
        <div className={classNames(styles.buttons_container, styles.secondary)}>
          {
            shouldRenderACCAButton ? <AppHeaderAccaButton {...accaButtonProps}/> : null
          }
          <AppHeaderSideButton {...sideButtonProps}/>
        </div>
      </div>
      :
      <div {...containerProps}>
        <AppHeaderTitle controller={controller}/>
      </div>
  );
});

