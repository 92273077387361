import {Link as RouterLink} from "react-router-dom";

export const Link = ({to, className, children, onClick}) => {

  const divProps = {
    className,
    to,
    onClick
  };

  const linkProps = {
    ...divProps,
    to
  };

  return to ? <RouterLink {...linkProps}>
      {children}
    </RouterLink> :
    <div {...divProps}>
      {children}
    </div>
}
