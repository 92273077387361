import {WidgetState} from "../widget.state";
import {computed, makeObservable, observable} from "mobx";
import {PlayerModel} from "../../models/player";
import {CompetitorModel} from "../../models/competitor";
import {SelectionListWidgetEntityModel} from "./models/selection-list.widget.entity.model";
import {SelectionListWidgetPlayerModel} from "./models/selection-list.widget.player.model";
import {SelectionListWidgetCompetitorModel} from "./models/selection-list.widget.competitor.model";
import {APP_CONST} from "../../consts";
import {shareState} from "../../app/share-state";

export class SelectionListWidgetState extends WidgetState {
  context: String;
  @observable teams: Array<CompetitorModel>;
  @observable selectedItemId: Number;

  constructor() {
    super()

    makeObservable(this)
  }

  @computed get entityModel(): SelectionListWidgetEntityModel {
    let entityModel: SelectionListWidgetEntityModel = null;

    if(this.context === APP_CONST.ROUTE.topScorer) {
      entityModel = new SelectionListWidgetPlayerModel(this.context, this.players);
    } if(this.context === APP_CONST.ROUTE.winningTeam) {
      entityModel = new SelectionListWidgetCompetitorModel(this.context, this.competitors);
    }

    return entityModel;
  }

  @computed get players(): Array<PlayerModel> {
    let players: Array<PlayerModel> = this.dataSource?.players?.map(player => new PlayerModel(player));

    if(shareState.user.isTournamentStarted) {
      players?.sort((a,b) => b.score - a.score)
    }

    return players;
  }

  @computed get competitors(): Array<CompetitorModel> {
    const teams: Array<CompetitorModel> = this.teams?.map((team: CompetitorModel) => new CompetitorModel(team));

    return teams;
  }

  @computed get hasNoItems(): Boolean {
    const hasNoItems: Boolean = this.entityModel.items?.length === 0

    return hasNoItems;
  }

  @computed get isTopScorer(): Boolean {
    const isTopScorer: Boolean = this.entityModel.context === APP_CONST.ROUTE.topScorer

    return isTopScorer;
  }

  @computed get shouldRenderWizardButton(): Boolean {
    return shareState.wizard.isVisible && !shareState.isMobileView
  }

  @computed get isWizardVisible(): Boolean {
    return shareState.wizard.isVisible;
  }

  get shouldRenderInput() {
    return shareState.user.allowTournamentBets;
  }
}
