import classNames from "classnames";
import styles from "../app-popups.module.scss";
import {shareState} from "../../../share-state";
import {observer} from 'mobx-react';

export const AppUserAgreementPopupInput = observer(({controller}) => {
  const {state: {userAgreementsValue}} = controller;

  const checkboxProps = {
    type: "checkbox",
    checked: userAgreementsValue,
    className: classNames(styles.checkbox),
    onChange: (e) => controller.setUserAgreements(e.target.checked)
  }

  return (
    <div className={classNames(styles.user_agreement_input)}>
      <input {...checkboxProps}/>
      <div>
        {
          shareState.termsUtils.terms.CONFIRMATION_NEWSLETTER
        }
      </div>
    </div>
  )
});


