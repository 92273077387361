import classNames from "classnames";
import styles from "../styles/login.module.scss";
import {observer} from "mobx-react";
import {LoginWidgetButtons} from "./login.widget.buttons";
import {LoginWidgetErrorMessageIndicator} from "./login.widget.error-message-indicator";
import {shareState} from "../../../app/share-state";
import React, {useEffect, useRef} from "react";
import {LoginWidgetUserAgreements} from "./login.widget.user-agreements";
import {APP_CONST} from "../../../consts";
// import {LoginWidgetUserAgreements} from "./login.widget.user-agreements";

export const LoginWidgetRegistrationPage = observer(({controller}) => {
  const {state} = controller;
  const {shouldRenderRegistrationPage} = state;
  const ref = useRef();

  const termsAndPolicyProps = {
    className: classNames(styles.terms_and_policy),
    dangerouslySetInnerHTML: {
      __html: shareState.termsUtils.terms.WC_2022_LOGIN_ACCEPT_TERMS_AND_CONDITIONS
        .replaceAll('<a', '<div')
        .replaceAll('</a>', '</div>')
        .replaceAll('href', 'data-url')
    },
    ref
  }

  const onClick = (url) => {
    shareState.isWebView ? shareState.nativeFunctionsUtils.callFunction("openBrowser", {url}) : window.open(url);
  }

  useEffect(() => {

    const children = ref.current?.children;
    if(children) {
      const arr = [].slice.call(children);

      arr?.forEach((child) => child.onclick = () => onClick(child.dataset.url))
    }
  }, [shouldRenderRegistrationPage]);

  return (
    shouldRenderRegistrationPage ? <div className={classNames(styles.page)}>
      <div className={classNames(styles.registration_description)}>{shareState.termsUtils.terms.WC_2022_LOGIN_HEADER}</div>
      <LoginWidgetButtons controller={controller}/>
      <LoginWidgetErrorMessageIndicator controller={controller}/>
      <div {...termsAndPolicyProps}/>
      {
        shareState.environment.name === APP_CONST.GAME_ENVIRONMENT.israel && shareState.theme === 'dominos' ? <LoginWidgetUserAgreements controller={controller}/> : null
      }
    </div> : null
  )
});
