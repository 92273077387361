import {REQUEST} from "../../../requests.consts";
import {DeltaRequestModel} from "../../../delta-request";
import {shareState} from "../../../../../app/share-state";

export class GetRequestGroupTableModel extends DeltaRequestModel {
  constructor(requestParams, startDelta) {
    super(REQUEST.CATEGORY.groups, REQUEST.ACTION.GROUPS.getGroupTable, requestParams, false, true, true, false);

    this.startDelta = startDelta;
    this.apiEnvironment = shareState.environment.baseUrl;
    this.interval = REQUEST.UPDATE_TIME_INTERVALS.liveRanking;
  }
}
