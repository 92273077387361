import classNames from "classnames";
import styles from '../acca-widget.module.scss';
import type {GameBetModel} from "../../../models/game/game.bet";
import {Game} from "../../../components/game/game";
import {Button} from "../../../components/button/button";
import {shareState} from "../../../app/share-state";
import {observer} from "mobx-react";
import {ACCA_WIDGET} from "../acca-widget.consts";
import {kinesisAnalyticsUtils} from "../../../analytics/kinesis/kinesis.analytics-utils";
import {
  AccaPredictionFormClickKinesisEvent
} from "../../../analytics/kinesis/events/acca/prediction-form/acca.prediction-form.click.kinesis-event";

export const AccaWidgetBetsGames = observer(({controller}) => {
  const {state} = controller;
  const {games, allGamesFilled} = state;

  const containerProps = {
    className: classNames(styles.bets_games_container),
  }


  const buttonProps = {
    className: classNames(styles.button, {[styles.active]: allGamesFilled}),
    label: shareState.termsUtils.terms.BOLAO_DAILY_ACCA_GET_MY_ACCA,
    isFilled: allGamesFilled,
    onClick: () => {
      if(allGamesFilled) {
        state.page = ACCA_WIDGET.pages.betSlip;

        kinesisAnalyticsUtils.send(new AccaPredictionFormClickKinesisEvent(({
          app_device_id: shareState.nativeGameConfig?.deviceId,
          web_visitor_id: shareState.websiteVisitorId,
        })))
      }
    }
  }

  return (
    games?.length > 0 ? <>
      <div {...containerProps}>
        {
          games.map((game: GameBetModel) => <Game key={game.id} item={game} controller={controller}
                                                  shouldRenderGameBets isSmallView/>)
        }
      </div>
      <Button {...buttonProps} />
    </> : <div>{shareState.termsUtils.terms.BOLAO_ACC_NO_ODDS}</div>

  )
});
