import {observer} from "mobx-react";
import {shareState} from "../../../app/share-state";
import {Image} from "../../../components/image/image";
import styles from "../styles/login.module.scss"
import {NativeLogoImageRequestParams} from "../../../icons/native-logo";
import classnames from "classnames";

export const LoginWidgetMain = observer(({controller}) => {

  const logoProps = {
    src: new NativeLogoImageRequestParams(),
    className: classnames(styles.desktop_logo)
  }

  return !shareState.isMobileView ? <Image {...logoProps}/> : null
});
