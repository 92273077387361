import classNames from "classnames";
import {WidgetComposer} from "../../widgets/widget.composer";
import {AppController} from "../app.controller";
import {AppState} from "../app.state";
import {AppComposer} from "../app.composer";
import styles from "../styles/app.module.scss";
import {shareState} from "../share-state";
import {observer} from 'mobx-react';
import {AppJoinGroupPopup} from "./popups/app.join-group-popup";
import {AppLogin} from "./app.login";
import {AppMain} from "./app.main";
import {AppInvitationFailedPopup} from "./popups/app.invitation-failed.popup";
import {MobileHeaderImageRequestParams} from "../../icons/background/mobile-header";
import {AppMetadata} from "./app.metadata";
import {AccaWidget} from "../../widgets/acca-widget/acca-widget";
import {AppUserAgreementPopup} from "./popups/user_agreement/app.user-agreement.popup";

export const settings = {
  controller: AppController,
  state: AppState
}

const App = AppComposer(WidgetComposer(settings)(observer(({controller}) => {
  const containerProps = {
    className: classNames(styles.app, {[styles.mobile_view]: shareState.isMobileView}, {[styles.wizard]: shareState.wizard.isVisible}, {[styles.login_page]: !shareState.user}),
    style: shareState.isMobileView && !controller.state.isLoadingApp ? {
      backgroundImage: `url(${new MobileHeaderImageRequestParams()})`
    } : null
  }
  const handleClose = () => {
    shareState.changeACCAVisibility(false);
  }

  return (
    <>
      <AppUserAgreementPopup controller={controller}/>
      {
        !controller.state.isLoadingApp ? <div {...containerProps}>
          <AppMetadata/>
          <AppJoinGroupPopup controller={controller}/>
          <AppInvitationFailedPopup controller={controller}/>
          {
            shareState.isACCAVisible ? <AccaWidget handleClose={handleClose}/> : null
          }
          {
            !shareState.user ? <AppLogin controller={controller}/> : <AppMain controller={controller}/>
          }
        </div> : null
      }
    </>
  );
})));

export default App;
