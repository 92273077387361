import React, {useEffect, useState} from 'react';
import classNames from "classnames";
import {useInView} from "react-intersection-observer";

export const Image = ({className, src: dataSrc, onClick, referrerPolicy}) => {
  const config = {triggerOnce: false};
  const [src, setSrc] = useState(null);
  const [ref, inView] = useInView(config);

  useEffect(() => {
    setSrc(dataSrc);
  }, [inView, dataSrc]);

  const imageProps = {
    className: classNames(className),
    onClick: () => onClick && onClick(),
    referrerPolicy,
    ref,
    src,
  }

  return (
    <img alt={''} {...imageProps}/>
  );
};