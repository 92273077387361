import {WidgetState} from "../widget.state";
import {GameModel} from "../../models/game/game";
import {makeObservable, observable, computed, values} from "mobx";
import {GamesWidgetGroupModel} from "./models/games-widget.group.model";
import {shareState} from "../../app/share-state";
import {GameWidgetInputModel} from "./models/games.widget.game-input-model";
import {GAMES_WIDGET} from "./games-widget.consts";
import {datetimeUtils} from "../../infrastructure/utils/datetime-utils";

export class GamesWidgetState extends WidgetState {
  @observable userId: Number;
  @observable selectedSortId: Number;
  @observable _games: Array<GameWidgetInputModel>;
  @observable hasLiveGames: Boolean;

  constructor() {
    super();

    makeObservable(this)
  }

  set games(games) {
    this._games = games;
  }

  @computed get games(): Array<GameWidgetInputModel> {
    return values(this._games).sort((a: GameWidgetInputModel, b: GameWidgetInputModel) => this.isGroupByDate ? a.startTime - b.startTime : compareFn(a.compGroupNumber, b.compGroupNumber));
  }

  @computed get nextGame(): GameModel {
    return this.games.find((game: GameWidgetInputModel) => game.isSchedule || game.isLive);
  }

  @computed get liveGamesAmount(): Number {
    const liveGamesAmount: Number = this.games.filter((game: GameModel) => game.isLive).length;

    return liveGamesAmount;
  }

  get gamesByDate(): Array<GameModel> {
    const gamesByDate: Map<String, Array<GameModel>> = this.groupGamesByKey('fullDate')

    return gamesByDate;
  }

  get gamesByGroupName(): Array<GameModel> {
    const gamesByGroupName: Map<String, Array<GameModel>> = this.groupGamesByKey('compGroupNumber')

    return gamesByGroupName;
  }

  get gamesByDateKeys(): Array<GameModel> {
    return Array.from(this.gamesByDate.keys());
  }

  get gamesByGroupNameKeys(): Array<GameModel> {
    return Array.from(this.gamesByGroupName.keys());
  }

  @computed get dropdownItems(): Array<GamesWidgetGroupModel> {
    return [{id: 0, name: 'Sort By Date'}, {id: 1, name: 'Sort By Group Name'}]
  }

  @computed get isGroupByDate(): Boolean {
    return this.selectedSortId === GAMES_WIDGET.GROUPS_ORDER.byDate
  }

  @computed get groups(): Array<GamesWidgetGroupModel> {
    const currentGroupGames = this.isGroupByDate ? this.gamesByDate : this.gamesByGroupName;
    const currentGroupKeys = this.isGroupByDate ? this.gamesByDateKeys : this.gamesByGroupNameKeys;

    const groups: Array<GamesWidgetGroupModel> = currentGroupKeys?.map((key: String, index: Number) =>
      new GamesWidgetGroupModel({
        id: index,
        open: !this.isWizardVisible || index === 0,
        key: key,
        games: currentGroupGames.get(key),
        title: this.isGroupByDate ? this.formatDate(key) : currentGroupGames.get(key)[0].compGroupName,
      })
    ).sort((a: GamesWidgetGroupModel, b: GamesWidgetGroupModel) => this.isGroupByDate ? a.id - b.id : compareFn(a.key, b.key));

    const currentGamesGroupIndex = groups.findIndex((group: GamesWidgetGroupModel) => group.key === this.nextGame?.fullDate);

    if (this.nextGame) {
      if (currentGamesGroupIndex < groups?.length - 1) {
        groups[currentGamesGroupIndex + 1].isNextGroupGames = true;
      } else if (currentGamesGroupIndex > 0) {
        groups[currentGamesGroupIndex].isNextGroupGames = true;
      }
    }

    return groups;
  }

  groupGamesByKey(key): Array<GameModel> {
    const gamesByGroup: Map<String, Array<GameModel>> = new Map();

    this._games?.forEach((game: GameModel) => {
      const value = gamesByGroup.get(game[key])
      if (value) {
        value.push(game)
        gamesByGroup.set(game[key], value);
      } else {
        gamesByGroup.set(game[key], [game]);
      }
    });

    return gamesByGroup;
  }

  get isOtherUser(): Boolean {
    const isOtherUser: Boolean = !!this.userId && this.userId !== shareState.user.userId;

    return isOtherUser
  }

  @computed get shouldRenderWizardButton(): Boolean {
    return shareState.wizard.isVisible && !shareState.isMobileView
  }

  @computed get shouldRenderAutoFillButton(): Boolean {
    const hasAnyEmptyBets: Boolean = Boolean(this._games.find((game: GameWidgetInputModel) => game.isSchedule && !game.isBetsFulfilled));

    return hasAnyEmptyBets && !this.isOtherUser;
  }

  @computed get numOfUnGuessesGames(): Number {
    const numOfUnGuessesGames: Number = this._games.filter((game: GameWidgetInputModel) => game.isSchedule && !game.isBetsFulfilled)?.length;

    return numOfUnGuessesGames;
  }

  get autoFillLabel(): String {
    return shareState.termsUtils.terms.WC_2022_AUTO_FILL_BUTTON;
  }

  @computed get disableCollapsibleGroups(): Boolean {
    return this.isGroupByDate;
  }

  @computed get isLastGameKeyboardOpen(): Boolean {
    return this.games[this._games.length - 1]?.isActive;
  }

  formatDate(startTime: Date) {
    const date: Date = new Date(startTime);

    return `${datetimeUtils.toLongNamedDate(date)}`;
  }


  @computed get isWizardVisible(): Boolean {
    return shareState.wizard.isVisible;
  }
  
  @computed get currentGameId(): Boolean {
    return this.games?.find((game: GameModel) => game.isLive || game.isSchedule)?.sportifierGameId;
  }

  get title(): String {
    const title: String = shareState.termsUtils.terms.WC_2022_GUESS_THE_GAME_RESULT;

    return title;
  }

  get subtitle(): String {
    const subtitle: String = shareState.termsUtils.terms.WC_2022_SELECT_SCORELIST_SECOND_LINE;

    return subtitle;
  }
}


const compareFn = (a: String, b: String) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}

