import classNames from "classnames";
import styles from '../acca-widget.module.scss';
import {shareState} from "../../../app/share-state";
import {AccaWidgetBetsGames} from "./acca-widget.bets.games";
import {LoadingIndicator} from "../../../components/loading-indicator/loading-indicator";
import {observer} from "mobx-react";
import {useEffect} from "react";
import {kinesisAnalyticsUtils} from "../../../analytics/kinesis/kinesis.analytics-utils";
import {
  AccaPredictionFormDisplayKinesisEvent
} from "../../../analytics/kinesis/events/acca/prediction-form/acca.prediction-form.display.kinesis-event";
export const AccaWidgetBets = observer(({controller}) => {
  const {state: {isLoadingGames}} = controller;

  useEffect(() => {
    kinesisAnalyticsUtils.send(new AccaPredictionFormDisplayKinesisEvent(({
      app_device_id: shareState.nativeGameConfig?.deviceId,
      web_visitor_id: shareState.websiteVisitorId,
    })))
  }, []);

  const containerProps = {
    className: classNames(styles.bets_page_container),
  }

  return (
      <div {...containerProps}>
        <div className={classNames(styles.title)}>
          {
            shareState.termsUtils.terms.BOLAO_DAILY_ACCA_HEADER
          }
        </div>
        {
          isLoadingGames ? <LoadingIndicator /> : <AccaWidgetBetsGames controller={controller} />
        }
      </div>
  )
});
