import {REQUEST} from "../../../requests.consts";
import {DeltaRequestModel} from "../../../delta-request";
import {shareState} from "../../../../../app/share-state";

export class GetRequestUserSettingsModel extends DeltaRequestModel {
  constructor(startDelta: Boolean) {
    super(REQUEST.CATEGORY.users, REQUEST.ACTION.USERS.getUserSettings, {}, false, true, true, false);

    this.apiEnvironment = shareState.environment.baseUrl;
    this.method = REQUEST.METHOD_TYPE.get;
    this.startDelta = startDelta;
    this.interval = REQUEST.UPDATE_TIME_INTERVALS.default;
  }
}
