import React, {Suspense} from "react";
import "./prediction-page.module.scss";
import {LoadingIndicator} from "../../components/loading-indicator/loading-indicator";

const PredictionPage = React.lazy(() => import("./prediction-page" /* webpackChunkName: "prediction.page" */ ));

const PredictionPageElement = () => {

  return (
    <Suspense fallback={<LoadingIndicator/>}>
      <PredictionPage/>
    </Suspense>
  )
};

export default PredictionPageElement;