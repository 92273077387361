import classNames from "classnames";
import {DropdownArrow} from "./dropdown.arrow";
import {DropdownHeaderInfo} from "./dropdown.header-info";

export const DropdownHeader = ({isOpen, isOneItemOnly, onArrowClick, selectedItem, logoIncluded, hideHeaderLogo, styles}) => {
  const infoProps = {
    hideHeaderLogo,
    logoIncluded,
    selectedItem,
    styles
  }

  return (
     <div className={classNames(styles.header, {[styles.open]: isOpen, [styles.one_item]: isOneItemOnly})} onClick={() => onArrowClick()}>
       <DropdownHeaderInfo {...infoProps}/>
       {
         !isOneItemOnly ? <DropdownArrow isOpen={isOpen} styles={styles}/> : null
       }
     </div>
  );
};