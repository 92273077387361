import {KinesisEvent} from "../kinesis.event";
import {KINESIS, NETWORK_ID} from "../../kinesis.consts";

export class SignInNetworkClickKinesisEvent extends KinesisEvent {
  constructor(properties) {
    super(
      KINESIS.CATEGORY.bolao,
      KINESIS.ACTION.signIn,
      KINESIS.LABEL.network,
      KINESIS.VALUE.click,
      properties
    );
  }

  getProperties(params) {
    const props = super.getProperties()

    return {
      ...props,
      network: NETWORK_ID[params.authenticatorId]
    }
  }
}
