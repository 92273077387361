import classNames from "classnames";
import styles from './button.module.scss';
import {observer} from "mobx-react";
import {shareState} from "../../app/share-state";

export const Button = observer(({children, label, onClick, className, style, isFilled = false}) => {

  const containerProps = {
    className: classNames({[styles.mobile_view]: shareState.isMobileView}, styles.button, className, {[styles.filled]: isFilled}),
    onClick: () => onClick?.(),
    style,
  }

  return (
    <div {...containerProps}>
      {children || label}
    </div>
  )
});
