import React from "react";
import classNames from "classnames";
import styles from './collapsed-item.module.scss';

export const CollapsedItemHeader = ({isOpen, onHeaderClick, item, disable= false}) => {
  const {title} = item;

  const headerProps = {
    className: classNames(styles.header, {[styles.open]: isOpen}, {[styles.disable]:disable}),
    onClick: () => onHeaderClick()
  }

  return (
    <div {...headerProps}>
      <div className={classNames(styles.title)}>{title}</div>
      {
        !disable ? isOpen ? <div className={classNames(styles.arrow, styles.arrow_up)}/> :
          <div className={classNames(styles.arrow, styles.arrow_down)}/> : null
      }
    </div>
  )
};
