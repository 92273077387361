import {KinesisEvent} from "../kinesis.event";
import {KINESIS} from "../../kinesis.consts";

export class SettingsLogoutClickKinesisEvent extends KinesisEvent {
  constructor() {
    super(
      KINESIS.CATEGORY.bolao,
      KINESIS.ACTION.settings,
      KINESIS.LABEL.logout,
      KINESIS.VALUE.click,
    );
  }
}
