import classNames from "classnames";
import styles from "../styles/login.module.scss";
import {observer} from "mobx-react";
import {Loader} from "../../../components/loader/loader";
import {shareState} from "../../../app/share-state";

export const LoginWidgetErrorMessageIndicator = observer(({controller}) => {
  const {state} = controller;
  const {showErrorMessage, isLoggingIn} = state;

  return (
    <Loader showLoader={isLoggingIn && !showErrorMessage} className={styles.login_loader} size={20}>
      <div className={classNames(styles.error_message)}>
        {
          showErrorMessage ? shareState.termsUtils.terms.WC_2022_LOGIN_FAILED : ''
        }
      </div>
    </Loader>
  )
});
