import React from "react";
import classNames from "classnames";
import styles from "../../styles/login.module.scss";
import {Image} from "../../../../components/image/image";
import {shareState} from "../../../../app/share-state";

export const LoginWidgetStepIcon = ({step}) => {
  const {icon} = step;

  const iconProps = {
    className: classNames(styles.step_logo),
    src: new icon(shareState.isMobileView ? 24 : 32)
  }

  return (
    <div className={classNames(styles.step_logo_container)}>
      <Image {...iconProps}/>
    </div>
  );
};
