import {KinesisEvent} from "../kinesis.event";
import {KINESIS, ROUTE_INDEX} from "../../kinesis.consts";
import {APP_CONST, ROUTE_NAME} from "../../../../consts";

export class GameClickKinesisEvent extends KinesisEvent {
  constructor(properties) {
    super(
      KINESIS.CATEGORY.bolao,
      KINESIS.ACTION.game,
      KINESIS.LABEL.click,
      null,
      properties
    );
  }

  getProperties(params) {
    const props = super.getProperties()

    return {
      ...props,
      gameId: params.id,
      status: this.resolveStatus(params.status),
      source: this.resolveSource(params.source)
    }
  }

  resolveSource(source): Number {
    const homePathName: String = ROUTE_NAME[APP_CONST.ROUTE.home];
    let sourceIndex: Number = ROUTE_INDEX[`/${source.toLowerCase()}`];

    if(source === homePathName) {
      sourceIndex = ROUTE_INDEX['/'];
    }

    return sourceIndex
  }
}
