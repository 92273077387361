import classNames from "classnames";
import styles from '../../styles/settings-widget.module.scss';
import dropdownStyles from '../../styles/settings-widget-dropdown.module.scss';
import {Dropdown} from "../../../../components/dropdown/dropdown";
import {observer} from "mobx-react";
import {SettingsWidgetGroups} from "./settings.widget.groups";
import {LogoutWidget} from "../../../logout-widget/logout.widget";
import {shareState} from "../../../../app/share-state";
import {kinesisAnalyticsUtils} from "../../../../analytics/kinesis/kinesis.analytics-utils";
import {
  FilterChoiceClickKinesisEvent
} from "../../../../analytics/kinesis/events/dropdown/filter.choice.click.kinesis-event";
import {APP_CONST} from "../../../../consts";
import {KINESIS} from "../../../../analytics/kinesis/kinesis.consts";
import {LanguagesDropdown} from "../../../../components/languages/languages-dropdown";

export const SettingsWidgetMainSettings = observer(({controller, onClose}) => {
  const {state} = controller;
  const {groups, mainGroupId} = state;

  const dropdownProps = {
    items: groups,
    customStyles: dropdownStyles,
    selectedItemId: mainGroupId,
    source: KINESIS.SOURCES.settings,
    onChange: (id) => {
      controller.changeMainGroupId(id)
      kinesisAnalyticsUtils.send(new FilterChoiceClickKinesisEvent({
        entityId: id,
        entityType: APP_CONST.ENTITY_TYPE.group,
        source: KINESIS.SOURCES.settings
      }))
    }
  }

  return (
    groups ? <div className={classNames(styles.groups_sections, dropdownStyles.main_group)}>
      <div className={classNames(styles.subtitle)}>{shareState.termsUtils.terms.WC_2022_SELECT_MAIN_GROUP}</div>
      <Dropdown {...dropdownProps}/>
      <div className={classNames(styles.subtitle)}>{shareState.termsUtils.terms.WC_2022_MY_GROUPS}</div>
      <SettingsWidgetGroups controller={controller}/>
      {
        !shareState.isWebView ?
          <>
            <div className={classNames(styles.subtitle)}>{shareState.termsUtils.terms.WC_2022_SELECT_LANGUAGE}</div>
            <LanguagesDropdown styles={dropdownStyles}/>
            <LogoutWidget onClose={onClose}/>
          </> : null
      }
    </div> : null
  )
});
