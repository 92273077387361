export const REQUEST = {
  appType: 5,
  publisher: 'BOLAO_GAME',
  domain: '365scores.com',
  userAuthorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJ3Y2cyMDE4IiwiaXNzIjoiMzY1U2NvcmVzIiwibmJmIjoiMTY1OTI3NDUwOCIsImV4cCI6IjE2NzUxNzIxMDgiLCJ1aWQiOiIxMTEifQ.GBqmdv3ECLU4Q4WAHFxtR8kb6wpVHvyc6Rj8WQ29kuk',

  AUTHENTICATOR_ID: {
    facebook: 1,
    google: 2,
    apple: 5,
    firebase: 4
  },

  METHOD_TYPE: {
    get: 'GET',
    post: 'POST'
  },

  ENVIRONMENT: {
    dev: 'https://wcg-qa.365scores.com',
    prod: 'https://wcg-qa.365scores.com',
    web: 'webws',
    mobile: 'mobileapi',
    qa13: 'qa13',
  },

  CATEGORY: {
    web: 'web',
    bolao: 'bolao',
    data: 'data',
    bets: 'Bets',
    games: 'Games',
    groups: 'Groups',
    tournament: 'Tournament',
    users: 'Users',
  },

  ACTION: {
    BETS: {
      setTopScorer: 'SetTopScorer',
      setWinnerTeam: 'SetWinnerTeam',
      setGameBets: 'SetGameBets',
      randomizeEmptyBets: 'RandomizeEmptyBets',
    },

    GAMES: {
      getAllGames: 'GetAllGames',
      getLiveGames: 'GetLiveGames',
      getOtherUserGames: 'GetAllGamesForOtherUser',
      getACCAGames: 'GetACCAGames',
    },

    GROUPS: {
      kick: 'Kick',
      join: 'Join',
      leave: 'Leave',
      create: 'Create',
      rename: 'Rename',
      delete: 'DeleteGroup',
      getGroupInfo: 'getGroupInfo',
      getUserGroups: 'GetUserGroups',
      getGroupTable: 'GetGroupTable',
      changeGroupPicture: 'ChangeGroupPicture',
      groupsLeagueTable: 'GetGroupsLeagueTable',
    },

    TOURNAMENT: {
      info: 'GetTournamentInfo',
      getTopScorers: 'getTopScorers',
      getAllPlayers: 'GetAllPlayers',
      getAllTeams: 'GetAllTeams',
      searchPlayers: 'SearchPlayers',
    },

    USERS: {
      getUserDashboard: 'GetUserDashboard',
      setUserSettings: 'SetUserSettings',
      getUserSettings: 'GetUserSettings',
      login: 'Login',
    },
    WEB: {
      games: 'games/current',
      terms: 'init/terms',
      locale: 'locale',
    },
    BOLAO: {
      settings: 'settings',
    },
    DATA: {
      addons: 'bets/addons',
      betsSlip: 'Bets/BetSlip',
      localdata: 'init/localdata'
    }
  },

  UPDATE_TIME_INTERVALS: {
    default: 60000,
    live: 10000,
    liveRanking: 10000,
  }
}
