import {BaseAnalyticsEvent} from "../../base.analytics-event";
import {userAgentUtils} from "../../../infrastructure/utils/user-agent-utils";
import {datetimeUtils} from "../../../infrastructure/utils/datetime-utils";
import {APP_CONST} from "../../../consts";
import {shareState} from "../../../app/share-state";
import {DEVICE_TYPES, GAME_STATUS, KINESIS} from "../kinesis.consts";
import {localStorageUtils} from "../../../infrastructure/utils/locale-storage-utils";

export class KinesisEvent extends BaseAnalyticsEvent {
  category: String;
  action: String;
  label: String;
  value: String;
  event_seq: Number;

  constructor(category: String, action: String, label: String, value: String, params) {
    super();

    this.level_1 = category;
    this.level_2 = action;
    this.level_3 = label;
    this.level_4 = value;
    this.properties = this.getProperties(params)
    this.event_name = this.eventName;
    this.visitor_id = this.visitorId;
    this.orientation = this.deviceOrientation;
    this.os_type = userAgentUtils.operationSystem.name;
    this.os_version = userAgentUtils.operationSystem.version;
    this.browser_type = userAgentUtils.browser.name;
    this.browser_version = userAgentUtils.browser.version;
    this.device_platform_type = this.devicePlatformType;
    this.device_brand = userAgentUtils.device.vendor;
    this.device_model = userAgentUtils.device.model;
    this.event_datetime = datetimeUtils.toShortDatetime();
    this.datekey = datetimeUtils.toDateObject().valueOf();
    this.country_id = shareState.userCountryId;
    this.lang_id = shareState.language.id;
    this.table = KINESIS.table;
    this.wifi = false;
    this.web_type = KINESIS.webType;
    this.visit_id = this.visitId;
    this.url = window.location.href
    this.domain = window.location.host;
    this.source = null;
  }

  getProperties(params) {
    return {
      envName: this.environmentName,
      visitor_game_id: this.visitorGameId,
      game_version: this.gameVersion,
    }
  }

  get environmentName(): String {
    const environmentName: String = localStorageUtils.getItem(APP_CONST.LOCAL_STORAGE_KEYS.envName)?.replaceAll('"','');

    return environmentName;
  }

  get eventName(): String {
    const eventName: String = [this.level_1, this.level_2, this.level_3, this.level_4].filter((property: String) => property).join('_');

    return eventName;
  }

  get visitorId(): String {
    return shareState.deviceId;
  }

  get visitId(): String {
    const visitId: String = localStorageUtils.getItem(APP_CONST.LOCAL_STORAGE_KEYS.sessionVisitId)?.replaceAll('"','') || null;

    return visitId;
  }

  get visitorGameId(): String {
    const visitorId: String = localStorageUtils.getItem(APP_CONST.LOCAL_STORAGE_KEYS.deviceId)?.replaceAll('"','') || null;

    return visitorId;
  }

  get devicePlatformType(): String {
    let devicePlatformType: String = '';

    if(shareState.isMobileView) {
      devicePlatformType = DEVICE_TYPES[userAgentUtils.device.type] || KINESIS.DEVICES.other
    } else {
      devicePlatformType = DEVICE_TYPES[KINESIS.DEVICES.console]
    }

    return devicePlatformType
  }

  get deviceOrientation(): String {
    let deviceOrientation: String = '';

    if (window.matchMedia("(orientation: portrait)").matches) {
      deviceOrientation = APP_CONST.DEVICE_ORIENTATIONS.portrait;
    }

    if (window.matchMedia("(orientation: landscape)").matches) {
      deviceOrientation = APP_CONST.DEVICE_ORIENTATIONS.landscape;
    }

    return deviceOrientation
  }

  resolveStatus(status) {
    return GAME_STATUS[status]
  }

  get gameVersion() {
    const version = require('../../../../package.json').version;

    return version
  }
}
