import classNames from "classnames";
import styles from './game.module.scss';
import {Image} from "../image/image";
import {CompetitorFlagImageRequestParams} from "../../icons/competitor/competitor-flag";

export const GameCompetitor = ({competitor, gridArea}) => {
  const containerProps = {
    className: classNames(styles.competitor_container),
    style: {gridArea}
  }

  const logoProps = {
    className: classNames(styles.competitor_logo),
    src: new CompetitorFlagImageRequestParams(competitor.id)
  }

  return(
    <div {...containerProps}>
      <Image {...logoProps}/>
      <div className={classNames(styles.competitor_name)}>{competitor.name}</div>
    </div>
  );
}
