import {KinesisEvent} from "../kinesis.event";
import {KINESIS} from "../../kinesis.consts";

export class LandingPageDisplayKinesisEvent extends KinesisEvent {
  constructor() {
    super(
      KINESIS.CATEGORY.bolao,
      KINESIS.ACTION.landingPage,
      KINESIS.LABEL.display,
      null
    );
  }
}
