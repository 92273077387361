import {ImageRequestParams} from "../models/image/image-request-params";

export class CompetitorImageRequestParams extends ImageRequestParams {
  constructor(id, width: Number = 48, height: Number = 48) {
    super({width, height});

    this._resourceName = `Competitors/${id}`;
  }

  get fallbackResourceName(): null {
    return `Competitors:default1.png` ;
  }
}