import {WidgetState} from "../widget.state";
import {makeObservable, observable, computed} from 'mobx';
import {LoginWidgetStepModel} from "./login-widget.step.model";
import {AvatarImageRequestParams} from "../../icons/avatar";
import {PredictionImageRequestParams} from "../../icons/prediction";
import {MegaphoneImageRequestParams} from "../../icons/megaphone";
import {shareState} from "../../app/share-state";
import {APP_CONST} from "../../consts";
import {LOGIN} from "./login.widget.consts";
import {localStorageUtils} from "../../infrastructure/utils/locale-storage-utils";

export class LoginWidgetState extends WidgetState {
  @observable isLoggingIn: Boolean;
  @observable showErrorMessage: Boolean;
  @observable showErrorUnCheckedBoxMessage: Boolean;
  @observable userAgreementsValue: Boolean;
  @observable redirectLoginLoading: Boolean;

  constructor() {
    super()

    this.redirectLoginLoading = localStorageUtils.getItem(APP_CONST.LOCAL_STORAGE_KEYS.signedInWithRedirect) === "true";
    this.userAgreementsValue = (shareState.environment.name === APP_CONST.GAME_ENVIRONMENT.israel) && (shareState.theme === 'dominos');

    makeObservable(this)
  }

  get steps(): Array<LoginWidgetStepModel> {
    const stepsModels = {
      signUp: this.signUpStep,
      guess: this.guessStep,
      updates: this.updatesStep
    }

   const steps: Array<LoginWidgetStepModel> = Object.values(stepsModels)

   return steps
  }

  @computed get signUpStep(): LoginWidgetStepModel {
    const signUpStep: Boolean = new LoginWidgetStepModel({
      key: 1,
      title: shareState.termsUtils.terms.WC_2022_SIGN_UP,
      text: shareState.termsUtils.terms.WC_2022_SIGN_UP_SECOND_LINE,
      icon: AvatarImageRequestParams
    })

    return signUpStep
  }

  @computed get guessStep(): LoginWidgetStepModel {
    const guessStep: Boolean = new LoginWidgetStepModel({
      key: 2,
      title: shareState.termsUtils.terms.WC_2022_GUESS_THE_GAME_RESULT,
      text: shareState.termsUtils.terms.WC_2022_GUESS_THE_GAME_RESULT_SECOND_LINE,
      icon: PredictionImageRequestParams
    })

    return guessStep
  }

  @computed get updatesStep(): LoginWidgetStepModel {
    const updatesStep: Boolean = new LoginWidgetStepModel({
      key: 3,
      title: shareState.termsUtils.terms.WC_2022_GET_REAL_TIME_UPDATE,
      text: shareState.termsUtils.terms.WC_2022_GET_REAL_TIME_UPDATE_SECOND_LINE,
      icon: MegaphoneImageRequestParams
    })

    return updatesStep
  }

  @computed get shouldRenderWelcomePage(): Boolean {
    const shouldRenderWelcomePage: Boolean = shareState.welcomePage === LOGIN.PAGE.welcome;

    return shouldRenderWelcomePage
  }

  @computed get shouldRenderRegistrationPage(): Boolean {
    const shouldRenderRegistrationPage: Boolean = shareState.welcomePage === LOGIN.PAGE.registration;

    return shouldRenderRegistrationPage
  }

  @computed get isBrEnvironment() {
    return shareState.environment.name === APP_CONST.GAME_ENVIRONMENT.brazil
  }
}
