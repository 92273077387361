import {RequestModel} from "../../../request";
import {REQUEST} from "../../../requests.consts";

export class GetRequestGameSettingsModel extends RequestModel {
  userCountryId: Number;
  languageId: Number;

  constructor(userCountryId: Number, languageId: Number) {
    super(REQUEST.CATEGORY.bolao, REQUEST.ACTION.BOLAO.settings, {}, false, true, false, false);

    this.method = REQUEST.METHOD_TYPE.get;
    this.apiEnvironment = `https://${this.getEnv()}.${this.domain}`;
    this.languageId = languageId;
    this.userCountryId = userCountryId;
  }

  getEnv() {
    let apiType: String = process.env.REACT_APP_API_ENV;

    return apiType;
  }

  get userCountryParams(): String {
    const userCountryParams: String = this.userCountryId ? `&countryId=${this.userCountryId}` : '';

    return userCountryParams;
  }

  get languageIdParam(): String {
    const userCountryParams: String = this.languageId ? `&langId=${this.languageId}` : '';

    return userCountryParams;
  }

  get fullUrl(): String {
    const fullUrl: String = `${this.url}?${this.languageIdParam}${this.userCountryParams}`;

    return fullUrl
  }
}
