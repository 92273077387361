import {REQUEST} from "../../../requests.consts";
import {DeltaRequestModel} from "../../../delta-request";
import {shareState} from "../../../../../app/share-state";

export class GetRequestLiveGamesModel extends DeltaRequestModel {
  constructor(isLive: Boolean) {
    super(REQUEST.CATEGORY.games, REQUEST.ACTION.GAMES.getLiveGames, {}, false, true, true, false);

    this.apiEnvironment = shareState.environment.baseUrl;
    this.method = REQUEST.METHOD_TYPE.get;
    this.startDelta = true
    this.interval = isLive ? REQUEST.UPDATE_TIME_INTERVALS.live : REQUEST.UPDATE_TIME_INTERVALS.default
  }
}
