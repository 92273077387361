import {BaseModel} from "./base";
import {observable, makeObservable} from 'mobx';
import {GroupModel} from "./group";

export class JoinGroupDialog extends BaseModel {
  @observable group: GroupModel;
  @observable isVisible: Boolean;

  constructor() {
    super();

    this.group = null;
    this.isVisible = false;

    makeObservable(this)
  }
}