import {override} from "mobx";
import {WizardWidgetPageModel} from "./wizard-widget.page.model";
import {Component} from "react";
import {GamesWidget} from "../../games-widget/components/games.widget";
import {shareState} from "../../../app/share-state";
import {kinesisAnalyticsUtils} from "../../../analytics/kinesis/kinesis.analytics-utils";
import {WizardDoneClickKinesisEvent} from "../../../analytics/kinesis/events/wizard/wizard.done.click.kinesis-event";

export class WizardWidgetPageGamesModel extends WizardWidgetPageModel {
  @override get WidgetComponent(): Component {
    return GamesWidget;
  }

  @override get title(): String {
    return shareState.termsUtils.terms.WC_2022_GUESS_THE_GAME_RESULT;
  }

  @override get nextPage(): Number {
    return null;
  }

  @override get textButton(): String {
    return shareState.termsUtils.terms.WC_2022_DONE_BUTTON;
  }

  @override get context(): String {
    return null;
  }

  @override get shouldRenderArrow(): Boolean {
    return false;
  }

  buttonEventModel(): WizardDoneClickKinesisEvent {
    return kinesisAnalyticsUtils.send(new WizardDoneClickKinesisEvent());
  }
}
