import {BaseModel} from "../base";
import {REQUEST} from "./requests.consts";
import {APP_CONST} from "../../consts";
import {localStorageUtils} from "../../infrastructure/utils/locale-storage-utils";

export class RequestModel extends BaseModel {
  apiEnvironment: String;
  domain: String;
  category: String;
  action: String;
  requestParams: BaseModel;
  method: String;
  includeLanguage: Boolean;
  includeAuthorized: Boolean;
  includeContentType: Boolean;

  constructor(
    category: String,
    action: String,
    requestParams: BaseModel,
    isPostRequest,
    includeLanguage: Boolean,
    includeAuthorized: Boolean,
    includeContentType: Boolean,
  ) {
    super();

    this.apiEnvironment = REQUEST.ENVIRONMENT.dev;
    this.domain = REQUEST.domain;
    this.category = category;
    this.action = action;
    this.requestParams = requestParams;
    this.isPostRequest = isPostRequest;
    this.includeLanguage = includeLanguage
    this.includeAuthorized = includeAuthorized;
    this.includeContentType = includeContentType;
  }

  get url(): String {
    return `${this.apiEnvironment}/${this.category}/${this.action}`
  }

  get languageParams(): String {
    const langId = localStorageUtils.getItem(APP_CONST.LOCAL_STORAGE_KEYS.langId) || 1
    const languageParams: String = this.includeLanguage ? `?lang=${langId}` : '';

    return languageParams
  }

  get fullUrl(): String {
    const fullUrl: String = `${this.url}${this.languageParams}${this.queryParams}`;

    return fullUrl
  }

  get queryParams(): String {
    if (this.requestParams) {
      const keys = Object.keys(this.requestParams);
      let queryParams: String = keys.length > 0 ? this.includeLanguage ? '&' : '?' : '';
      queryParams += keys.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.requestParams[key])).join('&');

      return queryParams;
    }
    return '';
  }

  get headers() {
    const uat = localStorageUtils.getItem(APP_CONST.LOCAL_STORAGE_KEYS.userAuthorizationToken)
    const auth = this.includeAuthorized ? {authorization: `Bearer ${uat}`} : {} //user token
    const contentType = this.includeContentType ? {'Content-Type': 'application/json'} : {}
    const headers = {...auth, ...contentType};

    return this.isPostRequest ? headers : {headers}
  }

  get objectType() {
    return this.constructor.name;
  }

  get body() {
    return {}
  }
}
