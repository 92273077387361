import styles from './loading-indicator.module.scss';
import classNames from "classnames";

export const LoadingIndicator = ({className, size, style = {}}) => {
  const containerProps = {
    className: classNames(styles.loading_indicator, className),
    style: {
      width: size || 40,
      height: size || 40,
      ...style
    }
  }

  return (
    <div {...containerProps} >
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
    </div>
  )
};

