import classNames from "classnames";
import styles from '../../styles/settings-widget.module.scss';
import {observer} from "mobx-react";
import {Image} from "../../../../components/image/image";
import {LeaveGroupImageRequestParams} from "../../../../icons/leave-group";
import {CloseImageRequestParams} from "../../../../icons/close";
import {Blanket} from "../../../../components/blanket/blanket";

export const SettingsWidgetExitMode = observer(({controller, group, onClose}) => {
  const leaveProps = {
    className: classNames(styles.icon),
    src: new LeaveGroupImageRequestParams(20,20),
    onClick: () => controller.leaveGroup(group.id)
  }

  const cancelProps = {
    className: classNames(styles.close_icon),
    src: new CloseImageRequestParams(16, 16),
    onClick: () => onClose && onClose()
  }

  const blanketProps = {
    className: classNames(styles.blanket),
    shouldRenderBlanket: true,
    onClick: () => onClose && onClose()
  }

  return (
    <>
      <Blanket {...blanketProps}/>
      <div className={classNames(styles.exit_mode_icons)}>
        <Image {...cancelProps}/>
        <div className={classNames(styles.icon_exit_mode)}>
          <Image {...leaveProps}/>
        </div>
      </div>
    </>
  )
});